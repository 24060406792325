import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { vendorApi } from "./apis/VendorApi";
import { transferApi } from "./apis/TransferApi";
import { vendorItemApi } from "./apis/VendorItemApi";
import { deliveryApi } from "./apis/DeliveryApi";
import { requestApi } from "./apis/RequestApi";
import { approvalApi } from "./apis/ApprovalApi";
import { accountApi } from "./apis/AccountApi";
import { productApi } from "./apis/ProductionApi";
import { notificationApi } from "./apis/NotificationApi";
import { inventoryApi } from "./apis/InventoryApi";
import { dashboardApi } from "./apis/DashboardApi";

export const store = configureStore({
  reducer: {
    [vendorApi.reducerPath]: vendorApi.reducer,
    [transferApi.reducerPath]: transferApi.reducer,
    [vendorItemApi.reducerPath]: vendorItemApi.reducer,
    [deliveryApi.reducerPath]: deliveryApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [approvalApi.reducerPath]: approvalApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(vendorApi.middleware)
      .concat(transferApi.middleware)
      .concat(vendorItemApi.middleware)
      .concat(deliveryApi.middleware)
      .concat(requestApi.middleware)
      .concat(approvalApi.middleware)
      .concat(accountApi.middleware)
      .concat(productApi.middleware)
      .concat(notificationApi.middleware)
      .concat(inventoryApi.middleware)
      .concat(dashboardApi.middleware);
  },
});

setupListeners(store.dispatch);
export {
  useFetchVendorQuery,
  useFetchVendorListQuery,
  useFilterVendorItemListQuery,
  useUpdateReceivingReportMutation,
  useDetVendorItemListQuery,
  useUpdateVendorItemMutation,
} from "./apis/VendorApi";
export {
  useFetchTransferQuery,
  useFetchTransferListPageQuery,
  useFetchTransferDetailsQuery,
  useUpdateTransferMutation,
  useUpdateDeliveryReturnMutation,
  useTransferReceivePodSigMutation,
  useTransferReceivedMutation,
  useTransferGoodRejectMutation,
  useCreateManualRequestMutation,
  useTransferRequestApprovalQuery,
  useTransferRequestListPageQuery,
  useTransferRequestItemListPageQuery,
  useTransferRequestItemApprovalViewQuery,
  useUpdateTransferRequestStatusMutation,
  useFetchTransferRequestDetailsQuery,
  useDetailedTranferScheduleQuery,
  useTransferItemGoodRejectMutation,
  useFetchUserManualRequestListQuery,
  useUpdateManualTransferMutation,
  useTransferMaterialGoodRejectMutation,
  useTransferCompletedRequestListPageQuery,
  useCreateTransferRequestReturnMutation,
  useFetchWithdrawalRtsListPageQuery,
  useUpdateReceivedReturnManualTransferMutation,
  useUpdateReceivedWithdrawalTransferMutation,
  useFetchVendorItemRTSListPageQuery,
  useFetchVendorRtsListPageQuery,
  useCreateRTSTransferMutation,
  useUpdateRTSTransferMutation,
  useUpdateRTSApprovalMutation,
  useFetchTransferDelReturnListPageQuery,
  useUpdateReceivedReturnDeliveryTransferMutation,
} from "./apis/TransferApi";
export {
  useFetchDeliveryListPageQuery,
  useFetchDeliveryOutletQuery,
  useFetchDeliveryProductsQuery,
  useFetchDeliveryBeveragesQuery,
  useFetchDeliveryBunsQuery,
  useFetchDeliveryCigarettesQuery,
  useFetchDeliveryOSQuery,
  useFetchDeliverySundriesQuery,
  useFetchDeliveryTankQuery,
  useFetchDeliveryWrappersQuery,
  useAddDeliveryOrderItemMutation,
  useFetchOrderNumberListPageQuery,
  useFetchForDelOrderNumberListPageQuery,
  useFetchOutletListPageQuery,
  useUpdateOrderToTransferMutation,
  useFetchDeliveryItemsDetailsQuery,
  useFetchDeliveryOrdersQuery,
  useFetchDeliveryItemsAdjustQuery,
  useUpdateSaveDeliveryDetailsMutation,
  useFetchDelIssuedItemsQuery,
  useUpdateOrderToDeliverMutation,
  useUpdateOrderToReturnMutation,
  useFetchDeliveryDetailsQuery,
  useFetchDeliveryOrdersSummaryQuery,
  useFetchOrderSummaryPDFDetailsQuery,
  useFetchDelScheduleQuery,
  useFetchRouteListQuery,
  useUpdateRouteScheduleMutation,
  useFetchFCListQuery,
  useAddDailySalesReportMutation,
  useUpdateStockLevelMutation,
  useFetchCurrentStockLvlQuery,
  useFetchGasListQuery,
  useUpdateSerialNumberMutation,
  useAddWithdrawalRequestMutation,
  useFetchWithdrawalOrderRequestQuery,
  useFetchUserListPageQuery,
  useAddNewOutletMutation,
  useUpdateOutletDetailsMutation,
  useFetchDSRListPageQuery,
  useFetchSRItemsDetailsQuery,
  useUpdateOutletPasswordMutation,
  useFetchManualRequestOrderProductListQuery,
  useAddDeliveryManualRequestMutation,
  useAddNewRoutesMutation,
  useFetchDeliveryMonthlyScheduleQuery,
  useUpdateDelDailyScheduleMutation,
  useFetchDSRFormatProductListQuery,
  useFetchDSRAuditListQuery,
  useFetchOutletFilteredFCListQuery,
  useFetchProdSLListPdfQuery,
  useFetchAverageWeeklySchedQuery,
  useFetchAverageMultiplierQuery,
  useFetchDeliveryReturnFormListPageQuery,
  useFetchDeliveryItemsReturnFormListPageQuery,
  useCreateReturnDeliveryRequestMutation,
  useFetchDeliveryDetailsExcelQuery,
} from "./apis/DeliveryApi";
export {
  useFetchRequestQuery,
  useAddRequestItemMutation,
  useFetchRequestItemQuery,
  useFetchRequestVendorItemQuery,
  useAddSuggestedVendorMutation,
  useAddSuggestedVendorDetailMutation,
  useUpdateItemRequestMutation,
  useFetchRawMatVendorItemQuery,
  useRequestItemVendorApproveMutation,
  useDeleteRequestItemMutation,
  useApprovalAdjustedRequiredQtyMutation,
  useRawMatApproveMutation,
  usePmApprovalAdjustedRequiredQtyMutation,
  useSuggestedVendorListQuery,
  useRawMatPrintQuery,
  useCreateNewPoMutation,
  useUpdateSuggestedVendorMutation,
} from "./apis/RequestApi";
export {
  useFetchApprovalQuery,
  useFetchApprovalDataQuery,
  useFetchTransferApprovalDetailsQuery,
  useFetchTransferItemApprovalQuery,
  useUpdateApproveTransferMutation,
  useFetchDeliveryApprovalQuery,
  useUpdateDeliveryItemsApprovalMutation,
  useUpdateApproveDeliveryMutation,
  useUpdateApprovalDelItemZeroMutation,
  useFetchApprovalRtsListPageQuery,
  useFetchRTSItemApprovalViewQuery,
  useFetchAdditionalDeliveryApprovalQuery,
  useUpdateApproveAddDelApprovalMutation,
  useFetchDeliveryApprovalItemsAdjustQuery,
  useFetchDeliveryApprovalItemsDetailsQuery,
} from "./apis/ApprovalApi";
export {
  useAccountDetailedQuery,
  useAccountListSelectQuery,
  useFetchClientLogoQuery,
  useFetchActionLogsQuery,
  useEmployeeListQuery,
  useSelectedEmployeeSchedQuery,
  useSaveEmployeeSchedMutation,
  useAccountListReceivingReportQuery,
  useEmpInvolSchedOutputQuery,
  useSaveEmployeeInvolMutation,
  useFetchUserManagementListPageQuery,
  useCreateNewUserMutation,
  useUpdateUserDetailsMutation,
} from "./apis/AccountApi";
export {
  useFetchProductBakeryListQuery,
  useFetchProductPattyListQuery,
  useFetchProductCakePastriesListQuery,
  useFetchProductKetsupListQuery,
  useFetchProductSandwichLineListQuery,
  useFetchScheduleQuery,
  useFetchTransferProductQuery,
  useFetchDetailTransferProductQuery,
  useFetchMixesListQuery,
  useFetchScheduleProductQuery,
  useTransferRawMatsMutation,
  useTransferOutOfStockMutation,
  useCountRecipeOfProductMutation,
  useSaveSchedProductMutation,
  useSaveSchedInputMutation,
  useSaveSchedSignatureMutation,
  useSaveSchedProductDetailMutation,
  useNewTransferRawMatsMutation,
  useFetchProductLineCompletedQuery,
  useFetchProdItemLineCompletedQuery,
  useSaveSchedItemMutation,
  useSaveSchedItemDetailMutation,
  usePremixesRecipeComputationQuery,
  useReceivedProductsMutation,
  useSaveTransItemPlasInputMutation,
  useFetchProductBreadCrumbsBunsListQuery,
  useFetchProductBreadCrumbsListQuery,
  useUpdateClientItemMutation,
} from "./apis/ProductionApi";
export {
  useFetchNotificationQuery,
  useFetchNotificationCountQuery,
} from "./apis/NotificationApi";
export {
  useFetchItemQuery,
  useFetchVendorListPageQuery,
  useAddVendorItemMutation,
  useFetchVendorListSearchPageQuery,
  useRawMatListPageQuery,
  useRawMatDetailedPageQuery,
} from "./apis/VendorItemApi";
export {
  useFetchItemInventoryListQuery,
  useFetchProductInventoryListQuery,
  useFetchProductHistoryQuery,
  useFetchItemHistoryQuery,
  useFetctDelRejectProductListQuery,
  useFetchDeliveryRejHistoryQuery,
  useFetchAuditReportListPageQueryQuery,
  useAddNewAuditMutation,
  useFetchAuditFormProductsQuery,
  useAddUpdateAuditedItemsQtyMutation,
  useFetchAuditedDataQtyQuery,
  useFetchAuditFormItemsQuery,
  useUpdatedAuditReportCompleteMutation,
  useUpdatedAuditReportStatusMutation,
  useFetchItemRejectPageQuery,
  useFetchProductRejectPageQuery,
  useFetchItemProdRejectHistoryQuery,
  useSaveMaterialInputMutation,
  useFetchMaterialHistoryQuery,
} from "./apis/InventoryApi";
export {
  useFetchDeliveryTotalSalesQuery,
  useFetchInventoryDashBoardGraphQuery,
  useDashBoardPOQuery,
  useDashPendingPoQuery,
  useDashBoardProdQuery,
  useDashProdReportQuery,
  usePoListStatusQuery,
  usePoDetailQuery,
} from "./apis/DashboardApi";
