import React, { useState } from "react";
import Papa from "papaparse"; // Import PapaParse
import { saveAs } from "file-saver";
import { useFetchDeliveryDetailsQuery } from "../../../store";
import { Button } from "@material-tailwind/react";
import { FaFileCsv } from "react-icons/fa";

const DelSummaryCsv = ({ getDelId }) => {
  const { data, isError, isLoading, isSuccess } =
    useFetchDeliveryDetailsQuery(getDelId);
  const [isCsvLoading, setIsCsvLoading] = useState(false);

  const generateCsv = async () => {
    setIsCsvLoading(true);
    if (isSuccess && data) {
      const result = data.data;
      let csvData = [];

      // Title section
      csvData.push(["PREMIUM BURGER & FOOD PRODUCT INC."]);
      csvData.push([
        "1185 TOPAZ ST. RAMAR VILLAGE SAN AGUSTIN CITY OF SAN FERNANDO PAMPANGA",
      ]);
      csvData.push(["DELIVERY SUMMARY RECEIPT"]);

      // Add delivery information
      const deliveryDate = new Date(
        result.delivery.delivery_date
      ).toLocaleString("en-US", { timeZone: "Asia/Tokyo" });
      csvData.push([]);
      csvData.push([
        "Delivery Date:",
        deliveryDate,
        "Cage Number:",
        result.delivery.cage_number,
      ]);
      csvData.push([
        "Delivery Number:",
        result.delivery.delivery_number,
        "Route:",
        result.delivery.route.name,
      ]);
      csvData.push([
        "Transfer Number:",
        result.delivery.transfer.transfer_number,
      ]);
      csvData.push([]);

      // Table headers
      csvData.push(["Code", "Name", "Total Issued Qty"]);

      let totalOrderedQty = 0; // Initialize total ordered quantity

      // Add delivery items
      result.delivery_items
        .filter((item) => item.ordered_qty !== 0)
        .forEach((item) => {
          totalOrderedQty += item.ordered_qty; // Sum up ordered_qty
          csvData.push([
            item.product__product_code,
            item.product__nickname,
            `${item.ordered_qty} ${item.product__unit_measurement}`,
          ]);
        });

      // Add total packs row
      csvData.push([]);
      csvData.push(["", "Total Packs:", totalOrderedQty]);

      // Generate CSV from the collected data
      const csv = Papa.unparse(csvData);

      // Save as CSV file
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `BM_${result.delivery.delivery_number}_Cage_Summary.csv`);
      setIsCsvLoading(false);
    }
  };

  return (
    <div>
      <Button
        className="bg-green-700 mt-1 py-3 px-3 flex items-center space-x-2"
        onClick={generateCsv}
        disabled={isLoading || isError || isCsvLoading}
      >
        {isLoading || isError || isCsvLoading ? (
          "Loading..."
        ) : (
          <>
            <FaFileCsv className="mr-2" />
          </>
        )}
      </Button>
    </div>
  );
};

export default DelSummaryCsv;
