import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
  Checkbox,
  CardFooter,
  Card,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";
import { useTransferRequestItemListPageQuery } from "../../../../store";
import { GrPowerReset } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";
import ReactSignatureCanvas from "react-signature-canvas";
import { ConsumablesTbl } from "../../../table_components/Delivery/ManualReqTbl";
import { useCreateManualRequestMutation } from "../../../../store";
import { Confirmation } from "../../Error_trap_components/ErrorTrapComponent";
import { useCreateTransferRequestReturnMutation } from "../../../../store";
import { toast } from "react-toastify";

const ManualRequestModal = ({ refetechMaintable }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const trapMessage = "Are you sure to request these items?";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const classes = "p-4 border-b border-blue-gray-50";
  const tableHeadItems = ["", "ITEM", "UM", ""];
  const TABLE_HEAD = ["Name", "Conversion/Unit Measure", "Requested Qty"];
  let [consumablesData, setConsumablesData] = useState([]);
  let [productActualQty, setProductActualQty] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState("");
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isItemDalogOPen, setIsItemDalogOPen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [driver, SetDriver] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [itemCurrentPage, setItemCurrentPage] = useState(1);
  const [itemPageSize, setItemPageSize] = useState(100);
  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
  };
  const handleChangeTypes = (value) => {
    setSelectedTypes(value);
  };
  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };
  const { data, isError, isLoading, isSuccess, refetch } =
    useTransferRequestItemListPageQuery({
      page: itemCurrentPage,
      page_size: itemPageSize,
      location: [4, 7, 1],
      search: searchTerm,
    });
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setItemCurrentPage(1);
  };

  const handleChangeItem = (item) => {
    setSelectedItem((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((selected) => selected !== item)
        : [...prevSelected, item]
    );
    console.log(selectedItem);
  };

  const handleChangeItemName = (item) => {
    setSelectedItemName((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((selected) => selected !== item)
        : [...prevSelected, item]
    );
    console.log(selectedItemName);
  };

  let handleQtyChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999999 ? 9999999 : value;

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      itemId: selectedItem[index].id,
      itemQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.itemQty !== undefined && item.itemQty !== 0
    );
    console.log(product_data);
  };

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    const anyArrayHasData =
      selectedItem.length > 0 && signature !== "" && selectedTypes !== "";

    // Update isReadyToSave based on the condition
    setIsReadyToSave(anyArrayHasData);

    if (isSuccess) {
      setItemData(data.results);
    }
  }, [selectedItem, signature, selectedTypes, isSuccess, data]);

  // const logo = JSON.parse(localStorage.getItem("account_detail"));
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");

  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  const handleIopentemsDialogue = () => {
    // Your logic to handle opening the dialog
    refetch();
    setIsItemDalogOPen(true);
  };
  const handleClearSelectedItem = () => {
    setSelectedItem([]);
    setSelectedItemName([]);
  };

  const handleDialogClose = () => {
    // Your logic to handle closing the dialog
    setIsItemDalogOPen(false);
  };
  const [addManualReturn] = useCreateTransferRequestReturnMutation();
  const [addManualRequest] = useCreateManualRequestMutation();
  const handleSave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components
    let item_data = productActualQty.map((qty, index) => ({
      itemId: selectedItem[index].id,
      itemQty: qty,
    }));
    // Combine all data as needed
    let allData = {
      signature: signature,
      types: selectedTypes,
      requester: account_detailed.user.id,
      requestItems: [],
    };
    allData.requestItems = allData.requestItems.concat(item_data);

    let sea;
    if (selectedTypes == 1) {
      sea = await addManualRequest(allData).unwrap();
    } else {
      sea = await addManualReturn(allData).unwrap();
    }

    console.log(allData);
    try {
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetechMaintable();
    setProductActualQty([]);
    handleClose();
    setSelectedItem([]);
    setSelectedItemName([]);
  };

  console.log(selectedItem);

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Request & Return Form
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Request & Return Item Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Select
                    label="Transaction Types"
                    name="types"
                    value={selectedTypes}
                    onChange={handleChangeTypes}
                  >
                    <Option value="">Select Types</Option>
                    <Option value="1">Request</Option>
                    <Option value="2">Return</Option>
                  </Select>
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Requester"
                    name="requester"
                    value={`${account_detailed.user.first_name} ${account_detailed.user.last_name}`}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {selectedTypes !== "" && (
              <>
                <Typography className="text-green-900 mt-5" variant="h3">
                  Select Items:
                </Typography>

                <div className={inputClasses}>
                  <Input
                    label="Select Items.."
                    value={selectedItemName.join(", ")}
                    onClick={handleIopentemsDialogue}
                    readOnly
                  />
                  {selectedItem.length > 0 && (
                    <Button
                      onClick={handleClearSelectedItem}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="xl"
                    open={isItemDalogOPen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Request Items
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[80vh]">
                      <Input
                        label="Search"
                        icon={<FiSearch className="h-5 w-5" />}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHeadItems.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {itemData.length > 0 ? (
                            <>
                              {itemData.map((item) => {
                                const classes =
                                  "p-4 border-b border-blue-gray-50";
                                return (
                                  <tr key={item.id}>
                                    <td className={classes}>
                                      <Checkbox
                                        type="checkbox"
                                        color="teal"
                                        checked={selectedItem.includes(item)}
                                        onChange={() => {
                                          handleChangeItem(item);
                                          handleChangeItemName(item.item.name);
                                        }}
                                      />
                                    </td>
                                    <td className={classes}>
                                      {item.item.name}
                                    </td>
                                    <td className={classes}>
                                      {item.item.weight_box}/
                                      {item.item.unit_measurement}
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          ) : (
                            <tr>
                              <td
                                colSpan={7}
                                className="p-4 border-b border-blue-gray-50 items-center justify-center"
                              >
                                <Typography
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  No Data Available
                                </Typography>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </DialogBody>
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        Page{" "}
                        {`${currentPage} of ${
                          Math.ceil(data?.count / pageSize) || 1
                        }`}
                      </Typography>
                      <div className="flex gap-2">
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handlePreviousPage}
                          disabled={!data?.previous}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handleNextPage}
                          disabled={!data?.next}
                        >
                          {data?.next ? "Next" : "No More Pages"}
                        </Button>
                      </div>
                    </CardFooter>
                    {/* <DialogFooter>
                           <Button
                             variant="text"
                             color="red"
                             onClick={handleDialogClose}
                             className="mr-1"
                           >
                             <span>Cancel</span>
                           </Button>
                           <Button
                             variant="gradient"
                             color="green"
                             onClick={handleDialogClose}
                           >
                             <span>Confirm</span>
                           </Button>
                         </DialogFooter> */}
                  </Dialog>
                </div>
              </>
            )}

            <div className="m-5">
              <Typography className="text-green-900 mt-2" variant="h3">
                {selectedItem.length > 0 && (
                  <Card className="h-full w-full overflow-scroll">
                    <table className="w-full min-w-max table-auto text-left ">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {selectedItem.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.item.name}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.item.weight_box}/
                                      {item.item.unit_measurement}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="flex items-center">
                                  <div className="flex flex-col flex-grow">
                                    <input
                                      type="number"
                                      value={productActualQty[index] || ""}
                                      onInput={(e) =>
                                        handleQtyChange(index, e.target.value)
                                      }
                                      className="w-20 border border-1 border-blue-gray-300 p-1"
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                )}
              </Typography>
            </div>
          </form>
          {selectedItem.length > 0 && (
            <>
              <div className="col-span-1 flex items-center justify-center space-y-5">
                <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                      width: 200,
                      height: 100,
                      className: "border-black border-2 rounded-s-xl",
                    }}
                    onEnd={handleSignatureChange}
                  />
                  <div className={boxResetClasses}>
                    <GrPowerReset
                      size={30}
                      className="cursor-pointer"
                      onClick={handleSignatureReset}
                      color="black"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center space-y-5">
                <Typography className="text-justify text-black" variant="h6">
                  {account_detailed.user.first_name
                    ? account_detailed.user.last_name
                      ? `${account_detailed.user.first_name} ${account_detailed.user.last_name}`
                      : "User"
                    : ""}
                </Typography>
              </div>
            </>
          )}

          {signature == "" && selectedTypes !== "" && (
            <Typography variant="lead" color="red">
              Please provide a signature.
            </Typography>
          )}
          {selectedTypes == "" && (
            <Typography variant="lead" color="red">
              Please provide a transaction.
            </Typography>
          )}
        </DialogBody>

        <DialogFooter>
          <Confirmation
            onConfirm={handleSave}
            trapMessage={trapMessage}
            isReadyToSave={isReadyToSave}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ManualRequestModal;
