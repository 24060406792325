import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function Confirmation({
  onConfirm,
  isReadyToSave,
  trapMessage,
  isRefreshing,
  onSend,
}) {
  const [open, setOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const clickRef = useRef(false); // Ref to prevent double clicks

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    if (clickRef.current) return; // Prevent further clicks immediately
    clickRef.current = true; // Block further clicks

    // Disable the button visually
    setIsButtonDisabled(true);

    // Execute the confirmation actions
    onConfirm?.(event); // Safeguard against undefined function
    onSend?.(event); // Safeguard against undefined function

    // Close the dialog
    handleOpen();

    // Re-enable the button and reset ref after 3 seconds
    setTimeout(() => {
      clickRef.current = false; // Allow future clicks
      setIsButtonDisabled(false);
    }, 3000);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="bg-green-900 ml-10"
        disabled={!isReadyToSave || isRefreshing || isButtonDisabled}
      >
        Confirm
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confirmation</DialogHeader>
        <DialogBody>{trapMessage}</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleConfirm}
            disabled={isButtonDisabled} // Prevent multiple clicks
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
