import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import SignatureCanvas from "react-signature-canvas";
import { GrPowerReset } from "react-icons/gr";
import RawMatReceiptTbl from "../../../table_components/Warehouse/DeliveryRawMaterials/RawMatRecieptTbl";
import { CiReceipt } from "react-icons/ci";
import {
  useAccountListReceivingReportQuery,
  useRawMatApproveMutation,
  useRawMatDetailedPageQuery,
} from "../../../../store";
import { HashLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";

function RawMaterialsReceipt({ vrr_id, refetch_receiving }) {
  const account_detailed = JSON.parse(
    localStorage.getItem("account_detail")
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [countSigFlag, setCountSigFlag] = useState(false);
  const [file, setFile] = useState(null);
  const [clickDisable, setClickDisable] = useState(false)
  const user_info = JSON.parse(localStorage.getItem("user_info"));

  const [inputs, setInputs] = useState({
    receiver2: "",
    driverName: "",
    drrir: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
      setInputs({
        ...inputs,
        [name]: value,
      });
  };

  const areAllFieldsComplete = () => {
    console.log(Object.values(inputs).every((value) => value.trim() !== ""))
    return (
      Object.values(inputs).every(
        (value) => value.trim() !== ""
      ) && countSigFlag && file != null
    );
  };

  const signatureCanvas = [
    {
      id: "1",
      label: "canvas1",
      ref: React.createRef(),
      assignatories: "Name of Receiver 1",
    },
    {
      id: "2",
      label: "canvas2",
      ref: React.createRef(),

      assignatories: "Name of  Receiver 2",
    },
    {
      id: "3",
      label: "canvas3",
      ref: React.createRef(),
      assignatories: "Name of Vendor's Driver",
    },
  ];

  const boxResetClasses =
    "flex justify-center items-center h-[105px]  w-[60px] bg-[#0B6E4F] shadow-md rounded-r-xl";

  const count_signature = () => {
    let count = 0;
    signatureCanvas
      .map((sig) => {
        return !sig.ref.current.isEmpty() ? (count += 1) : null;
      })
      .filter((url) => url !== null);

    if (count === 3) {
      setCountSigFlag(true);
    }
  };

  const accouint_list_select = useAccountListReceivingReportQuery();
  const fetch_raw_mats_item = useRawMatDetailedPageQuery(vrr_id);

  let als = [];
  if (accouint_list_select.isLoading) {
    //pass
  } else if (accouint_list_select.error) {
    //pass
  } else {
    als = accouint_list_select.data.data;
  }
  let content;

  //fetching of data from api
  if (fetch_raw_mats_item.isLoading) {
    content = <HashLoader />;
  } else if (fetch_raw_mats_item.error) {
    content = <div>Error Loading...</div>;
  } else {
    let result = fetch_raw_mats_item.data.data;
    content = (
      <>
        <form>
          <div className="flex flex-col gap-4  mt-7 w-full">
            <div className="flex flex-col md:flex-row lg:mx-10 lg:space-x-16">
              <div className="flex flex-col space-y-3 md:w-[50%]">
                <Typography>PO Number: {result.po_number}</Typography>
                <Typography>Vendor Name: {result.vendor.name}</Typography>
                <Input
                  label="Vendor's Driver"
                  name="driverName"
                  onChange={handleInputChange}
                />
                <Input
                  label="Delivery Date"
                  name="delivery_date"
                  value={result.delivery_date}
                  readOnly
                />
              </div>
              <div className="md:ml-5 md:w-[55%] mt-3 md:mt-0 space-y-3">
                <Input
                  label="Receiver 1"
                  name="receiver1"
                  viewOnly
                  defaultValue={
                    account_detailed.user.first_name + ' ' +account_detailed.user.last_name
                  }
                />
                <select
                  className="w-[101%] inline-flex justify-center rounded-md px-2 py-3 text-sm text-black-500 border-[1px] ring-blue-gray-200 border-blue-gray-200"
                  required
                  name="receiver2"
                  onChange={handleInputChange}
                >
                  <option disabled selected className="text-center">
                    - - - Select Receiver - - -
                  </option>
                  {als.map((rev, i) => {
                    return (
                      <>
                        <option value={rev.user_id}>{rev.full_name}</option>
                      </>
                    );
                  })}
                </select>
                <Input
                  label="Delivery Receipt/Sales Invoice Receipt"
                  name="drrir"
                  onChange={handleInputChange}
                />
                <Input
                  label="Receiving Receipt Number"
                  name="rrn"
                  defaultValue={result.receiving_receipt_number}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mt-8">
              <RawMatReceiptTbl
                data={result.vendor_items}
                receiving_remarks={result.receiving_remarks}
                refetch_data={fetch_raw_mats_item.refetch}
              />
            </div>
            <div className="flex items-center mt-8 ml-2">
              <input
                type="file"
                className="
                  file:bg-gradient-to-b file:from-blue-500 file:to-blue-600
                  file:px-1 file:py-1 file:m-2
                  file:border-none
                  file:rounded-full
                  file:rounde-full
                  file:text-white
                  file:cursor-pointer
                  file:shadow-sm file:shadow-blue-600/50
                  bg-gradient-to-br from-green-600 to-green-700
                  text-white/80
                  rounded-full
                  cursor-pointer
                  shadow-xl shadow-gray-300/60
                  "
                name="file"
                onChange={(evt) => {
                  setFile(
                    evt.target.files[0],
                  );
                }}
              />
            </div>
            <div className="block gap-5 px-2">
              <div className="flex flex-col md:flex-row justify-center items-center gap-4">
                {signatureCanvas.map((sig) => (
                  <div key={sig.id} className="flex flex-col">
                    <div className="flex flex-row mt-8">
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: 200,
                          height: 100,
                          className: "border-black border-2 rounded-l-xl",
                        }}
                        onEnd={() => count_signature()}
                        ref={sig.ref}
                      />
                      <div className={boxResetClasses}>
                        <GrPowerReset
                          size={"30px"}
                          onClick={() => clearSignature(sig.ref)}
                          className="cursor-pointer"
                          color="white"
                        />
                      </div>
                    </div>
                    <Typography className="text-center mt-3">
                      {sig.assignatories}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
  const [raw_mat_approve] = useRawMatApproveMutation();
  const handleSave = async () => {
    setClickDisable(true)
    const dataURLs = signatureCanvas
      .map((sig) => {
        return !sig.ref.current.isEmpty()
          ? sig.ref.current.getTrimmedCanvas().toDataURL()
          : null;
      })
      .filter((url) => url !== null);

    let signature1 = dataURLs[0];
    let signature2 = dataURLs[1];
    let signature3 = dataURLs[2];
    let signature1_split = signature1.split(",");
    let signature2_split = signature2.split(",");
    let signature3_split = signature3.split(",");
    let formData = new FormData();

    formData.append("user_id", user_info.id);
    formData.append("vrr_id", vrr_id);
    formData.append(
      "receiver1_name",
      account_detailed.user.first_name + ' ' +account_detailed.user.last_name
    );
    formData.append("receiver2_user", inputs.receiver2);
    formData.append("vendor_driver_name", inputs.driverName);
    formData.append("receiver1", signature1_split[1]);
    formData.append("receiver2", signature2_split[1]);
    formData.append("vendor_driver_sig", signature3_split[1]);
    formData.append("drsi_receipt", inputs.drrir);
    formData.append("file_receipt", file);
    console.log(file)
    const rmtApproved = await raw_mat_approve(formData).unwrap();
    if (rmtApproved.success === true) {
      setClickDisable(false)
      toast.success(rmtApproved.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(rmtApproved.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    fetch_raw_mats_item.refetch();
    refetch_receiving()
    handleOpen();
  };

  const clearSignature = (canvasRef) => {
    canvasRef.current.isEmpty() ? console.log("") : canvasRef.current.clear();
  };

  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className=" w-[110%] md:w-[75%] lg:w-[50%] flex gap-0 md:gap-0 lg:gap-2 bg-[#1AA55D]"
          size="sm"
        >
          <CiReceipt strokeWidth={2} className="h-4 w-4" />
          View Receipt
        </Button>
        <Dialog
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
          size="xxl"
        >
          <DialogHeader className="w-full bg-[#0B6E4F] rounded-sm flex justify-center">
            <Typography className="text-center text-white" variant="h5">
              Receiving Report
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto">{content}</DialogBody>
          <ToastContainer />
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
            <Button
              className="bg-green-900"
              onClick={handleSave}
              disabled={!areAllFieldsComplete() || clickDisable === true}
            >
              <span>Submit</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
}

export default RawMaterialsReceipt;
