import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useFetchDelScheduleQuery } from "../../../store";
import EditCalendarModal from "./EditDelCalendar";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import EditRouteModal from "./EditScheduledRoute";
import { useFetchDeliveryMonthlyScheduleQuery } from "../../../store";
import { FaCalendarAlt } from "react-icons/fa";
let sched_result = [];
const DelSchedulePicker = ({ assignedRoute, delDate }) => {
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const intMonth = currentDate.month() + 1;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const goToToday = () => setCurrentDate(moment());
  const goToNextMonth = () => {
    const nextMonth = moment(currentDate).add(1, "month");
    const maxAllowedMonth = moment().add(1, "month"); // Set the maximum allowed month

    if (nextMonth.isSameOrBefore(maxAllowedMonth, "month")) {
      setCurrentDate(nextMonth);
    }
  };
  const goToPreviousMonth = () =>
    setCurrentDate(moment(currentDate).subtract(1, "month"));
  const {
    data: schedData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useFetchDeliveryMonthlyScheduleQuery({
    month: intMonth,
  });

  const monthYearString = currentDate.format("MMMM YYYY");

  const CustomMonthView = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    } else if (error) {
      return <div>Error loading data</div>;
    } else if (schedData.length === 0) {
      return <div>No data found.</div>;
    } else if (isSuccess) {
      const firstDayOfMonth = moment(currentDate).startOf("month");
      const startingDayOfWeek = firstDayOfMonth.day(); // Starting day index (0 - Sunday, 1 - Monday, ..., 6 - Saturday)

      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      const start = moment(currentDate).startOf("month");
      const end = moment(currentDate).endOf("month");
      const days = [];
      let currentDay = moment(start);
      sched_result = schedData.data;

      const monthValue = currentDate.month() + 1;

      // Push empty slots for days before the starting day of the month
      for (let i = 0; i < startingDayOfWeek; i++) {
        days.push(null);
      }

      while (currentDay <= end) {
        days.push(moment(currentDay));
        currentDay = moment(currentDay).add(1, "day");
      }

      const weeks = [];
      let currentWeek = [];
      days.forEach((day, index) => {
        if (index % 7 === 0 && currentWeek.length > 0) {
          weeks.push(currentWeek);
          currentWeek = [];
        }
        currentWeek.push(day);
      });
      if (currentWeek.length > 0) {
        weeks.push(currentWeek);
      }
      const formattedWeeks = weeks.map((week, weekIndex) => {
        return (
          <div key={weekIndex} className="grid grid-cols-7 gap-2">
            {week.map((day, dayIndex) => {
              if (!day) {
                return (
                  <div
                    key={dayIndex}
                    className="text-center p-2 border-b border-gray-200"
                  />
                );
              }
              const dayOfWeek = day.format("dddd").toLowerCase();
              const isTodayOrPast = day.isSameOrBefore(
                moment().startOf("day"),
                "day"
              );
              const isSameSelectedDate = day.isSame(selectedDate, "day");
              const isToday = day.isSame(moment(), "day");
              const isDisabled = isTodayOrPast;

              return (
                <EditRouteModal
                  day={day}
                  isSameSelectedDate={isSameSelectedDate}
                  isDisabled={isDisabled}
                  selectedDate={selectedDate}
                  dayIndex={dayIndex}
                  isToday={isToday}
                  monthData={schedData.data}
                  refetchSched={refetch}
                />
                // <div
                //   key={dayIndex}
                //   className={`text-center p-2 border-b border-gray-200 ${
                //     isDisabled ? "bg-gray-300 cursor-not-allowed" : ""
                //   } ${isSameSelectedDate ? "bg-green-500 text-white" : ""} ${
                //     isToday ? "bg-yellow-300" : ""
                //   }`}
                //   onClick={isDisabled ? null : () => handleDayClick(day)}
                //   style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                // >
                //   <div
                //     className={`text-sm ${
                //       isSameSelectedDate ? "text-white" : ""
                //     }`}
                //   >
                //     {isSameSelectedDate
                //       ? selectedDate.format("D")
                //       : day.format("D")}
                //   </div>
                //   <div className="text-xs text-white-500">
                //     {/* {weeklyData &&
                //       weeklyData[dayOfWeek] &&
                //       weeklyData[dayOfWeek].map((route, idx) => (
                //         <div key={idx}>{route.name}</div>
                //       ))} */}
                //   </div>
                // </div>
              );
            })}
          </div>
        );
      });

      return (
        <div className="bg-gray-100 p-5 rounded-sm">
          <div className="grid grid-cols-7 gap-2">
            {dayNames.map((day, index) => (
              <div
                key={index}
                className="bg-white text-center p-2 border-b border-gray-200"
              >
                <div className="text-sm">{day}</div>
              </div>
            ))}
          </div>
          {formattedWeeks}
        </div>
      );
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="bg-[#1AA55D] mt-1 flex items-center space-x-2 py-3 px-3"
      >
        <FaCalendarAlt />
        <span>Set Scheduile</span>
      </Button>
      <Dialog
        size="xl"
        open={open}
        onClose={handleClose}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full flex flex-col items-center rounded-sm space-y-2">
          <Typography className="text-green-900" variant="h3" component="span">
            Delivery Schedule
          </Typography>
          <Typography className="text-green-900" variant="h6" component="span">
            {monthYearString}
          </Typography>
        </DialogHeader>

        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <CustomMonthView />
        </DialogBody>
        <DialogFooter>
          <Button variant="text" className="ml-10" onClick={goToToday}>
            <span>Today</span>
          </Button>
          <Button variant="text" className="mr-3" onClick={goToPreviousMonth}>
            <span>Back</span>
          </Button>
          <Button
            variant="text"
            className="mr-3"
            onClick={goToNextMonth}
            disabled={moment(currentDate)
              .add(1, "month")
              .isAfter(moment().add(1, "month"), "month")} // Disable if next month is after the maximum allowed month
          >
            <span>Next</span>
          </Button>

          <Button
            variant="text"
            color="red"
            className="mr-3 ml-5"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DelSchedulePicker;
