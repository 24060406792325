import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Select,
  Option,
  Input,
  IconButton,
  Checkbox,
} from "@material-tailwind/react";
import jsPDF from "jspdf";
import InvenFlowFlourPDF from "./PBF/InventoryFlowOfFlourPdf";
import InvenFlowMeatPDF from "./PBF/InventoryFlowOfMeatPdf";
import InvenFlowFGPDF from "./PBF/InventoryFlowOfFinishedGoodsPdf";
import InvenFlowPattiesPDF from "./PBF/InventoryFlowOfPattiesPdf";
import BreadProductionPDF from "./PBF/BreadProduction";
import PattyProdctionPDF from "./PBF/PattyProduction";
import BreadCrumbsReportPDF from "./PBF/BreadCrumbsReport";
import BevReportPDF from "./PBF/BeveragesCompReport";
import CigarettesReportPDF from "./PBF/CigaretteCompReport";
import LPGReportPDF from "./PBF/LPGCompReport";
const GeneratePBFPdf = () => {
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedReports, setSelectedReports] = useState([]);
  const [isReadyToGenerate, setIsReadyToGenerate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const complianceOptions = [
    { id: 1, name: "INVENTORY FLOW OF FLOUR" },
    { id: 2, name: "INVENTORY FLOW OF MEAT" },
    { id: 3, name: "INVENTORY FLOW OF PATTIES" },
    { id: 4, name: "INVENTORY FLOW OF FINISHED GOODS" },
    { id: 5, name: "PATTY PRODUCTION" },
    { id: 6, name: "BREAD PRODUCTION" },
    { id: 7, name: "THICKER PLASTIC RECONCILIATION REPORT" },
    { id: 8, name: "BREAD CRUMBS COMPLIANCE REPORT" },
    { id: 9, name: "BEVERAGES COMPLIANCE" },
    { id: 10, name: "CIGARETTES COMPLIANCE" },
    { id: 11, name: "LPG COMPLIANCE" },
  ];
  useEffect(() => {
    // Set default date range if no dates are provided
    if (startDate === "" && endDate === "") {
      const currentDate = new Date();
      const defaultStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const defaultEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      setStartDate(defaultStartDate.toISOString().split("T")[0]);
      setEndDate(defaultEndDate.toISOString().split("T")[0]);
    }
    let readyToClick =
      startDate !== "" && endDate !== "" && selectedReports.length > 0;
    setIsReadyToGenerate(readyToClick);
  }, [startDate, endDate, selectedReports]);

  const handleRemoveItem = (id) => {
    setSelectedReports(selectedReports.filter((item) => item !== id));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const intValue = parseInt(value); // Convert string value to an integer
    if (checked) {
      // Add the checked value (integer) to the array
      setSelectedReports([...selectedReports, intValue]);
    } else {
      // Remove the unchecked value (integer) from the array
      setSelectedReports(selectedReports.filter((item) => item !== intValue));
    }
  };

  const selectedReportTxts = selectedReports.map((item) => {
    const option = complianceOptions.find((option) => option.id === item);
    return option ? option : null;
  });
  const handleOpen = () => {
    setOpen(!open); // Toggle dialog state
  };

  const handleMainClose = () => {
    setOpen(false);
    setSelectedReports([]);
  };

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);
    setEndDate("");

    // Ensure end date is not before start date
    if (endDate && selectedStartDate > endDate) {
      setEndDate(selectedStartDate);
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    setEndDate(selectedEndDate);
  };

  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)
  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // Create a new jsPDF instance with custom page size
  const pdfDoc = new jsPDF({
    orientation: "portrait", // or 'landscape'
    unit: "mm", // units of measurement
    format: [pdfWidth, pdfHeight], // specify the page size
  });

  const generatePDF = (reports) => {
    if (reports.includes(1)) {
      InvenFlowFlourPDF(startDate, endDate);
    }
    if (reports.includes(2)) {
      InvenFlowMeatPDF(startDate, endDate);
    }
    if (reports.includes(3)) {
      InvenFlowPattiesPDF(startDate, endDate);
    }
    if (reports.includes(4)) {
      InvenFlowFGPDF(startDate, endDate);
    }
    if (reports.includes(5)) {
      PattyProdctionPDF(startDate, endDate);
    }
    if (reports.includes(6)) {
      BreadProductionPDF(startDate, endDate);
    }
    if (reports.includes(8)) {
      BreadCrumbsReportPDF(startDate, endDate);
    }
    if (reports.includes(9)) {
      BevReportPDF(startDate, endDate);
    }
    if (reports.includes(10)) {
      CigarettesReportPDF(startDate, endDate);
    }
    if (reports.includes(11)) {
      LPGReportPDF(startDate, endDate);
    }
  };

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Generate PBF Report
      </Button>

      <Dialog
        open={open} // Bind the dialog's open state to 'open'
        size="xl"
        handler={handleOpen} // Toggle dialog handler
        className="relative max-h-min"
      >
        {/* Dialog Header */}
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            PBF Report
          </Typography>
        </DialogHeader>

        {/* Dialog Body */}
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className="grid grid-cols-2 gap-4">
                  {complianceOptions.map((option, index) => (
                    <div key={index}>
                      <Checkbox
                        label={option.name} // Display the text of the option
                        value={option.id} // Store the integer ID as the value
                        onChange={handleCheckboxChange}
                        color="green"
                        checked={selectedReports.includes(option.id)} // Handle checked state
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-span-2 space-y-5">
                <Typography className="text-green-900" variant="h6">
                  Selected Report
                </Typography>
                <div className="space-y-2">
                  <div className="flex flex-wrap items-center gap-2 border border-gray-300 p-2 rounded-md">
                    {selectedReportTxts.length > 0 ? (
                      selectedReportTxts.map(
                        (option) =>
                          option && (
                            <div
                              key={option.id}
                              className="bg-gray-200 text-gray-800 px-3 py-1 rounded-full flex items-center space-x-2"
                            >
                              <span>{option.name}</span>
                              <button
                                className="text-red-500 hover:text-red-700 focus:outline-none"
                                onClick={() => handleRemoveItem(option.id)}
                              >
                                ✕
                              </button>
                            </div>
                          )
                      )
                    ) : (
                      <span className="text-gray-400">No reports selected</span>
                    )}
                  </div>
                  <Typography className="text-green-900" variant="h6">
                    Date Range
                  </Typography>
                  <div className="flex space-x-4">
                    <Input
                      label="Start Date"
                      type="date"
                      id="start-date"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                    <Input
                      label="End Date"
                      type="date"
                      id="end-date"
                      value={endDate}
                      onChange={handleEndDateChange}
                      min={startDate} // Set minimum date for end date to the selected start date
                      disabled={!startDate} // Disable if start date isn't selected
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="green"
            onClick={() => generatePDF(selectedReports)}
            disabled={!isReadyToGenerate}
          >
            {isPDFLoading ? "Generating PDF..." : "Generate PDF"}
          </Button>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default GeneratePBFPdf;
