import React, { useState } from "react";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { useFetchDeliveryDetailsExcelQuery } from "../../../store";
import { Button } from "@material-tailwind/react";
import { FaFileCsv } from "react-icons/fa";

const DelSummaryCsv = ({ getDelId }) => {
  const { data, isError, isLoading, isSuccess } =
    useFetchDeliveryDetailsExcelQuery(getDelId);
  const [isCsvLoading, setIsCsvLoading] = useState(false);

  const generateCsv = async () => {
    setIsCsvLoading(true);
    if (isSuccess && data) {
      const deliveries = data.data;
      console.log(data);
      let csvRows = deliveries.map((delivery) => {
        const deliveryDate = new Date(
          Date.parse(delivery.delivery_tracking_number.delivery_date)
        ).toLocaleString("en-PH", { timeZone: "Asia/Manila" });

        const outletName = delivery.outlet.outlet;
        const deliveryNumber =
          delivery.delivery_tracking_number.delivery_number;
        const transferNumber = delivery.transfer;

        // Map product codes to ordered quantities
        let productData = {};
        delivery.d_o_i.forEach((item) => {
          if (item.ordered_qty !== 0) {
            productData[
              item.product.product_code
            ] = `${item.ordered_qty} ${item.product.unit_measurement}`;
          }
        });

        // Create CSV row in "Header;Value" format
        return [
          `"Delivery Date;${deliveryDate}"`,
          `"Outlet;${outletName}"`,
          `"Delivery Number;${deliveryNumber}"`,
          `"Transfer Number;${transferNumber}"`,
          ...Object.entries(productData).map(
            ([code, qty]) => `"${code};${qty}"`
          ),
        ];
      });

      // Convert to CSV
      const csv = Papa.unparse(csvRows, { quotes: false, delimiter: "," });

      // Save as CSV file
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `BM_Cage_Summary.csv`);
    }
    setIsCsvLoading(false);
  };

  return (
    <div>
      <Button
        className="bg-green-700 mt-1 py-3 px-3 flex items-center space-x-2"
        onClick={generateCsv}
        disabled={isLoading || isError || isCsvLoading}
      >
        {isLoading || isError || isCsvLoading ? (
          "Loading..."
        ) : (
          <>
            <FaFileCsv className="mr-2" />
          </>
        )}
      </Button>
    </div>
  );
};

export default DelSummaryCsv;
