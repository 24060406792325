import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useAccountDetailedQuery } from "../../store";
import { Input, Button, Typography, Checkbox } from "@material-tailwind/react";

function Login() {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const fetchAccountDetailed = async (id, token) => {
    await axios
      .get(`${apiDomain}/api/account/${id}/account_detailed/`, {
        headers: {
          Authorization: "token " + token,
        },
      })
      .then((result) => {
        localStorage.setItem(
          "account_detail",
          JSON.stringify(result.data.data)
        );
        navigate("/base/home");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let navigate = useNavigate();

  let email = username;
  const login = async (event) => {
    event.preventDefault();

    await axios
      .post(`${apiDomain}/api/user/login/`, {
        username: email,
        password: password,
      })
      .then(function (response) {
        // const encrypt = (text) => {
        //   const cipher = crypto.createCipher('aes-256-cbc', 'secretKey');
        //   let encrypted = cipher.update(text, 'utf8', 'hex');
        //   encrypted += cipher.final('hex');
        //   return encrypted;
        // }

        // const token = encrypt('mySecretToken');
        // localStorage.setItem('token', token);
        localStorage.setItem("mytoken", response.data.token);
        localStorage.setItem("user_info", JSON.stringify(response.data.user));
        navigate("/base");
        fetchAccountDetailed(response.data.user.id, response.data.token);
      })
      .catch(function (error) {
        console.log(error)
        setError("Invalid username or password");
      });
  };

  const togglePassword = (e) => {
    e.target.checked ? setPasswordType("text") : setPasswordType("password");
  };
  const handlePassword = (evt) => {
    setPassword(evt.target.value);
  };

  return (
    <div className="w-screen bg-green-900 h-screen flex justify-center items-center">
      <div className=" w-[22rem] md:w-96 p-6 shadow-lg bg-white rounded-md">
        <div className="flex justify-center items-center">
          <img
            src="../images/BurgerMachineLogo.jpg"
            alt="system_logo"
            className=" w-60 h-20"
          />
        </div>
        <hr />
        {error ? (
          <div className="alert alert-warning alert-dismissible" role="alert">
            <p className="text-red-700 text-center">{error}</p>
          </div>
        ) : null}
        <h3 className="m-4 block text-center font-semibold">
          Login to your account.
        </h3>
        <form onSubmit={login}>
          <div className="m-4">
            <label htmlFor="username" className="block text-base mb-2">
              Username
            </label>
            <Input
              type="text"
              id="username"
              label="Please Enter Username"
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </div>
          <div className="m-4">
            <label htmlFor="password" className="block text-base mb-2">
              Password
            </label>
            <Input
              type={passwordType}
              id="password"
              label="Please Enter Password"
              value={password}
              onChange={handlePassword}
            />
            <div className="flex justify-end items-center">
              <Typography className=" text-blue-gray-500 text-xs">
                Show password
              </Typography>
              <Checkbox onClick={togglePassword} />
            </div>
          </div>
          <div></div>
          <div className="m-4 mt-4 justify-between items-center">
            <a href="/" className="text-indigo-800 font-semibold">
              Forgot Password
            </a>
          </div>

          <div className="pb-10 m-4 mb-3 flex justify-center">
            <Button
              color="blue"
              type="submit"
              className="w-full rounded-md hover:bg-transparent hover:text-indigo-700"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;
