import React, { useRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function ApproveDeliveryConfirmation({
  onConfirm,
  isSufficient,
  isSignatureEmpty,
}) {
  const [open, setOpen] = React.useState(false);
  const isProcessing = useRef(false); // Tracks if the confirm button is already processing

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    if (isProcessing.current) return; // Prevent double submission
    isProcessing.current = true;

    // Execute the confirmation action
    onConfirm(event);

    // Close the dialog
    handleOpen();

    // Reset the ref after a delay to allow another click
    setTimeout(() => {
      isProcessing.current = false;
    }, 3000);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="bg-green-900"
        disabled={!isSufficient || isSignatureEmpty === ""}
      >
        Submit
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confirm Approval</DialogHeader>
        <DialogBody>
          Are you sure to approve this delivery to transfer?
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
