import React, { useState, useEffect } from "react";
import { Card, CardHeader, Typography } from "@material-tailwind/react";
import { useFetchApprovalDataQuery } from "../../store/apis/ApprovalApi";
import { useFetchDeliveryApprovalQuery } from "../../store/apis/ApprovalApi";
import { useFetchTransferListPagev2Query } from "../../store/apis/TransferApi";
import { FaCheckToSlot } from "react-icons/fa6";
import HashLoader from "react-spinners/HashLoader";
import PageTitle from "../page_components/PageTitle";
import PurchaseOrderTable from "../table_components/Approval/PurchaseOrderTable";
import DeliveryTransferTable from "../table_components/Approval/DelSlipTable";
import ApprovalTabComponents from "../page_components/ApprovalTabComponents";
import RequiredQtyApprovalTbl from "../table_components/Approval/RequiredQtyApprovalTbl";
import RequestTransferTable from "../table_components/Approval/RequestSlip";
import RTSTransferTable from "../table_components/Approval/RTSSlip";
import { useTransferRequestApprovalQuery } from "../../store";
import { useFetchApprovalRtsListPageQuery } from "../../store";
import { useApprovalRequestItemQuery } from "../../store/apis/RequestApi";
import { useNavigate } from "react-router-dom";

const PoTableHead = [
  "PO Number",
  "Request for Approval Date",
  "Category",
  "Requestor",
  "Department",
  "View",
];
let fetchDelResult = [];
let fetechReqResult = [];
let fetchRTSResult = [];

const DelSlipHead = [
  "Deliver Tracking #",
  "Route",
  "Requestor",
  "Delivery Date",
  "Cage Number",
  "View",
];

const RequiredAdjustedHead = [
  "PO number",
  "Item",
  "Required Quantity",
  "Adjusted Quantity",
  "Remark",
  "View",
];

const RequestTableHead = [
  "Transfer #",
  "Issuance To",
  "Requestor",
  "Date",
  "View",
];

const RTSTableHead = [
  "Transfer #",
  "Issuance To",
  "Vendor",
  "Requestor",
  "Date",
  "View",
];

function Approval() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [currentPage, setCurrentPage] = useState(1);
  const [poPageSize, setPoPageSize] = useState(10);
  const [transferPageSize, setTransferPageSize] = useState(10);
  const [raPageSize, setRAPageSize] = useState(10);
  const [delCurrentPage, setdelCurrentPage] = useState(1);
  const [raCurrentPage, setRaCurrentPage] = useState(1);
  const [delPageSize, setdelPageSize] = useState(10);
  const [delTotalPage, setDelTotalPage] = useState(null);
  const [reqTotalPage, setReqTotalPage] = useState(null);
  const [rtsTotalPage, setRtsTotalPage] = useState(null);
  const [orderBy, setOrderBy] = useState("desc");
  const [rtsSearchTerm, setRtsSearchTerm] = useState("");
  const [delOrderBy, setdelOrderBy] = useState("dec");
  const [reqCurrentPage, setReqCurrentPage] = useState(1);
  const [reqPageSize, setReqPageSize] = useState(10);
  const [rtsCurrentPage, setRtsCurrentPage] = useState(1);
  const [rtsPageSize, setRtsPageSize] = useState(10);

  const {
    data: delData,
    error: delError,
    isLoading: delLoading,
    isSuccess: delsuccess,
    refetch: reFetchDelData,
  } = useFetchDeliveryApprovalQuery({
    page: reqCurrentPage,
    pageSize: reqPageSize,
    search: "",
    orderDir: orderBy,
    orderBy: "id",
  });

  const {
    data: reqData,
    error: reqError,
    isLoading: reqLoading,
    isSuccess: reqSuccess,
    refetch: refetchReqData,
  } = useTransferRequestApprovalQuery({
    page: reqCurrentPage,
    page_size: reqPageSize,
    search: "",
    order_dir: orderBy,
    order_by: "id",
  });

  const {
    data: rtsData,
    error: rtsError,
    isLoading: rtsLoading,
    isSuccess: rtsSuccess,
    refetch: refetchRTSData,
  } = useFetchApprovalRtsListPageQuery({
    page: rtsCurrentPage,
    page_size: rtsPageSize,
    search: rtsSearchTerm,
    order_by: "id",
    order_dir: orderBy,
    types: 4,
  });

  const fetchPurchaseOrder = useFetchApprovalDataQuery({
    page: currentPage,
    pageSize: poPageSize,
    orderDir: orderBy,
    account_jp: account_detailed.job_position,
  });
  const fetchSTS = useFetchTransferListPagev2Query({
    page: currentPage,
    page_size: transferPageSize,
    orderDir: orderBy,
  });

  const fetchRequiredApproval = useApprovalRequestItemQuery({
    page: raCurrentPage,
    page_size: raPageSize,
    orderDir: orderBy,
  });
  // for access controller purposes and implement refetching of data
  useEffect(() => {
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 14
    ) {
      ///Pass
    } else {
      navigate("/base/dashboard");
      return;
    }
    fetchPurchaseOrder.refetch();
    fetchRequiredApproval.refetch();
    reFetchDelData();
    refetchReqData();
  }, [token]);

  useEffect(() => {
    if (delsuccess) {
      const DelSlipTotalPage = Math.ceil(delData.count / delPageSize) || 1;
      setDelTotalPage(DelSlipTotalPage);
    }

    if (reqSuccess) {
      const ReqSlipTotalPage = Math.ceil(reqData.count / reqPageSize) || 1;
      setReqTotalPage(ReqSlipTotalPage);
    }

    if (rtsSuccess) {
      const RTSSlipTotalPage = Math.ceil(rtsData.count / rtsPageSize) || 1;
      setRtsTotalPage(RTSSlipTotalPage);
    }
  }, [delsuccess, reqSuccess, rtsSuccess]);

  // total page for purchase order
  const POTotalPage = Math.ceil(fetchPurchaseOrder.data?.count / poPageSize);
  // total page for transfer slip
  const TransSlipTotalPage = Math.ceil(fetchSTS.data?.count / transferPageSize);

  const RATotalPage = Math.ceil(fetchRequiredApproval.data?.count / raPageSize);
  // function for previous page  Math.ceil(fetchRequiredApproval.data.count / delPageSize);
  // const intervalDuration = 5000; // 5 seconds
  // useEffect(() => {
  //   reFetchDelData();
  // }, intervalDuration);

  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleDelPreviousPage() {
    if (delCurrentPage === 1) return;
    setdelCurrentPage(delCurrentPage - 1);
  }
  function handleRAPreviousPage() {
    if (raCurrentPage === 1) return;
    setRaCurrentPage(raCurrentPage - 1);
  }
  function handleReqPreviousPage() {
    if (reqCurrentPage === 1) return;
    setReqCurrentPage(reqCurrentPage - 1);
  }
  function handleRtsPreviousPage() {
    if (rtsCurrentPage === 1) return;
    setRtsCurrentPage(rtsCurrentPage - 1);
  }
  function handleDelNextPage() {
    if (raCurrentPage === 1) return;
    setRaCurrentPage(raCurrentPage + 1);
  }
  function handleRaNextPage() {
    if (delCurrentPage === 1) return;
    setdelCurrentPage(delCurrentPage + 1);
  }
  function handleReqNextPage() {
    if (reqCurrentPage === 1) return;
    setReqCurrentPage(reqCurrentPage + 1);
  }
  function handleRtsNextPage() {
    if (rtsCurrentPage === 1) return;
    setRtsCurrentPage(rtsCurrentPage + 1);
  }
  // function for next page
  function handlePoNextPage() {
    if (currentPage === POTotalPage) return;
    setCurrentPage(currentPage + 1);
  }
  function handleTransferNextPages() {
    if (currentPage === TransSlipTotalPage) return;
    setCurrentPage(currentPage + 1);
  }
  // function changing the page size of table
  function handleReqPageSizeChange(e) {
    setReqPageSize(e.target.value);
    setReqCurrentPage(1);
  }
  function handleRtsPageSizeChange(e) {
    setRtsPageSize(e.target.value);
    setRtsCurrentPage(1);
  }
  function handlePoPageSizeChange(e) {
    setPoPageSize(e.target.value);
    setCurrentPage(1);
  }
  function handleDeliveryPageSize(e) {
    setdelPageSize(e.target.value);
    setdelCurrentPage(1);
  }
  // function handleTransferPageSizeChange(e) {
  //   setTransferPageSize(e.target.value);
  //   setCurrentPage(1);
  // }
  function handleRASizeChange(e) {
    setRAPageSize(e.target.value);
    setRaCurrentPage(1);
  }
  // function for ascending sorting of data for table
  function handleAscending() {
    setOrderBy("asc");
  }
  // function for descending sorting of data for table
  function handleDescending() {
    setOrderBy("desc");
  }
  // data fetching for delivery slip

  if (reqLoading) {
    return <HashLoader />;
  } else if (reqSuccess) {
    fetechReqResult = reqData.results;
  } else {
    return <div>Error Loading...</div>;
  }
  if (rtsLoading) {
    return <HashLoader />;
  } else if (rtsSuccess) {
    fetchRTSResult = rtsData.results;
    console.log(rtsData.results);
  } else {
    return <div>Error Loading...</div>;
  }

  if (delLoading) {
    return <HashLoader />;
  } else if (delsuccess) {
    fetchDelResult = delData.results;
  } else {
    return <div>Error Loading...</div>;
  }

  let PurchaseOrderResult = [];
  if (fetchPurchaseOrder.isLoading) {
    return <HashLoader />;
  } else if (fetchPurchaseOrder.data) {
    PurchaseOrderResult = fetchPurchaseOrder.data.results;
  } else {
    return <div>Error Loading...</div>;
  }
  // data fetching for purchase order slip
  let requiredApprovalResult = [];
  if (fetchRequiredApproval.isLoading) {
    return <HashLoader />;
  } else if (fetchRequiredApproval.data) {
    requiredApprovalResult = fetchRequiredApproval.data.results;
  } else {
    return <div>Error Loading...</div>;
  }
  // variable that handles for displaying the purchase order table and transfer slip table
  const data = [
    {
      label: "Purchase Order",
      value: "purchasae order",
      desc: (
        <PurchaseOrderTable
          PoTableHead={PoTableHead}
          poPageSize={poPageSize}
          handlePoPageSizeChange={handlePoPageSizeChange}
          orderBy={orderBy}
          handleAscending={handleAscending}
          handleDescending={handleDescending}
          PurchaseOrderResult={PurchaseOrderResult}
          currentPage={currentPage}
          POTotalPage={POTotalPage}
          reFetchDelData={reFetchDelData}
          handlePreviousPage={handlePreviousPage}
          handlePoNextPage={handlePoNextPage}
          autoFetch={fetchPurchaseOrder.refetch}
        />
      ),
    },
    {
      label: "PO Adjustment",
      value: "po adjustment",
      desc: (
        <RequiredQtyApprovalTbl
          RequiredAdjustedHead={RequiredAdjustedHead}
          delPageSize={delPageSize}
          handleDeliveryPageSize={handleRASizeChange}
          orderBy={orderBy}
          handleAscending={handleAscending}
          handleDescending={handleDescending}
          fetchRAResult={requiredApprovalResult}
          raCurrentPage={raCurrentPage}
          raTotalPage={RATotalPage}
          handleRaPreviousPage={handleRAPreviousPage}
          handleRaNextPage={handleRaNextPage}
          data_refetch={fetchRequiredApproval.refetch}
        />
      ),
    },
    {
      label: "Delivery",
      value: "delivery slip",
      desc: (
        <DeliveryTransferTable
          DelSlipHead={DelSlipHead}
          delPageSize={delPageSize}
          handleDeliveryPageSize={handleDeliveryPageSize}
          orderBy={orderBy}
          handleAscending={handleAscending}
          handleDescending={handleDescending}
          fetchDelResult={fetchDelResult}
          delCurrentPage={delCurrentPage}
          DelSlipTotalPage={delTotalPage}
          handleDelPreviousPage={handleDelPreviousPage}
          handleDelNextPage={handleDelNextPage}
          data_refetch={reFetchDelData}
        />
      ),
    },
    {
      label: "Manual Request",
      value: "request slip",
      desc: (
        <RequestTransferTable
          DelSlipHead={RequestTableHead}
          delPageSize={reqPageSize}
          handleDeliveryPageSize={handleReqPageSizeChange}
          orderBy={orderBy}
          handleAscending={handleAscending}
          handleDescending={handleDescending}
          fetchDelResult={fetechReqResult}
          delCurrentPage={reqCurrentPage}
          DelSlipTotalPage={reqTotalPage}
          handleDelPreviousPage={handleReqPreviousPage}
          handleDelNextPage={handleReqNextPage}
          data_refetch={refetchReqData}
        />
      ),
    },
    {
      label: "Return To Supplier",
      value: "rts slip",
      desc: (
        <RTSTransferTable
          DelSlipHead={RTSTableHead}
          delPageSize={rtsPageSize}
          handleDeliveryPageSize={handleRtsPageSizeChange}
          orderBy={orderBy}
          handleAscending={handleAscending}
          handleDescending={handleDescending}
          fetchDelResult={fetchRTSResult}
          delCurrentPage={rtsCurrentPage}
          DelSlipTotalPage={rtsTotalPage}
          handleDelPreviousPage={handleRtsPreviousPage}
          handleDelNextPage={handleRtsNextPage}
          data_refetch={refetchRTSData}
        />
      ),
    },
  ];

  return (
    <>
      <div className="container mx-auto lg:px-4 md:mb-28 lg:mb-0">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-5 md:mb-14 lg:mb-5">
          <div className="flex items-center space-x-2">
            <FaCheckToSlot className="h-6 w-6  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Approval
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <Card className="container h-full w-[95%] mx-auto md:mx-0 md:w-full mb-4 lg:mt-24">
          <ApprovalTabComponents data={data} />
        </Card>
      </div>
    </>
  );
}

export default Approval;
