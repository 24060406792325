import React from "react";
import { Input } from "@material-tailwind/react";

const DateInput = ({ label, date, setDate, limiter }) => {
  // Ensure date is formatted as "YYYY-MM-DD"
  const formattedDate = date ? date : ""; // Keep as string
  const formattedLimiter = limiter ? limiter : null; // Keep as string

  const handleChange = (e) => {
    setDate(e.target.value); // Store date as "YYYY-MM-DD" string
  };

  return (
    <div className="flex flex-col md:w-1/2">
      <Input
        label={label}
        type="date"
        id="date-input"
        value={formattedDate}
        onChange={handleChange}
        min={formattedLimiter || undefined} // Ensure limiter is in correct format
        // max={ || undefined}
      />
    </div>
  );
};

export default DateInput;
