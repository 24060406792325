import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import AdjustDeliveryApprovalTbl from "../../table_components/Approval/AdjustDeliveryApprovalTbl";
import { useFetchDeliveryApprovalItemsAdjustQuery } from "../../../store";
import { useUpdateDeliveryItemsApprovalMutation } from "../../../store";
import { AdjustDeliveryQtyConfirmation } from "../../modal_form_components/Error_trap_components/AdjustDeliveryQtyConfirmation";
import { toast, ToastContainer } from "react-toastify";

const AdjustDelApprovalModal = ({
  getOrderNumber,
  getOutlet,
  getOrderId,
  refetchFunction,
  triggerRefetch,
}) => {
  let del_items_result = [];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  // Assume you have the data fetched from your API
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const { data, error, isLoading, isSuccess, refetch } =
    useFetchDeliveryApprovalItemsAdjustQuery({
      page: currentPage,
      page_size: pageSize,
      order_number: getOrderNumber,
    });

  const [adjustedData, setAdjustedData] = useState([]);

  const [adjustDeliveryItems] = useUpdateDeliveryItemsApprovalMutation();
  const handleQtyChange = (newData) => {
    setAdjustedData((prevData) => {
      const updatedData = prevData.filter((existingEntry) => {
        return (
          !newData.some(
            (newEntry) => newEntry.prodId === existingEntry.prodId
          ) && existingEntry.prodQty !== ""
        );
      });
      updatedData.push(...newData);
      return updatedData;
    });
  };

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_items_result = data.results;
  }

  const categoryMappings = {
    1: "Products",
    2: "Beverages",
    3: "Buns",
    4: "Wrappers",
    5: "Sundries",
    6: "Cigarettes",
    7: "Operating Supplies",
    8: "Tank",
    // Add more mappings as needed
  };
  // Organize data by categories
  const categories = {};
  del_items_result.forEach((item) => {
    const categoryId = item.product.category; // Assuming category ID is available in the data
    const categoryName = categoryMappings[categoryId];
    if (!categories[categoryName]) {
      categories[categoryName] = [];
    }
    categories[categoryName].push(item);
  });
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const handleAdjustDelItems = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    let allData = {
      user: account_detailed.user.id,
      adjustedProductQty: adjustedData,
    };
    console.log(allData);
    const sea = await adjustDeliveryItems(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }

    setAdjustedData([]);
    refetchFunction();
    handleOpen();
    refetch();
    triggerRefetch();
  };

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Edit
      </Button>
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Adjust Delivery Receipt
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-{auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Outlet"
                    name="outlet"
                    value={getOutlet}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Order Number"
                    name="ordernumber"
                    value={getOrderId}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="m-5">
              {/* Render tables for each category present in the data */}
              {Object.entries(categories).map(([category, items]) => (
                <div key={category}>
                  <Typography className="text-green-900 mt-2" variant="h3">
                    {category}
                    <AdjustDeliveryApprovalTbl
                      items={items}
                      onQtyChange={handleQtyChange}
                    />
                  </Typography>
                </div>
              ))}
            </div>
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          <AdjustDeliveryQtyConfirmation onConfirm={handleAdjustDelItems} />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleOpen}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AdjustDelApprovalModal;
