import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { useFetchInventoryDashBoardGraphQuery } from "../../../store";
import moment from "moment";
import DateInput from "../../reusable/SelectDate";
import BarChartModal from "./InventryBarChart";

let TABLE_HEAD = [];

const getCurrentMonthRange = () => {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1); // 1st day of the month
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Last day of the month

  return {
    start: firstDay.toISOString().split("T")[0], // Format to YYYY-MM-DD
    end: lastDay.toISOString().split("T")[0], // Format to YYYY-MM-DD
  };
};

function InventoryDashboardTbl() {
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("1");
  let [data, setData] = useState([]);
  const handleChangeLocation = (value) => {
    setCurrentPage(1);
    setLocation(value);
  };

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
    setCurrentPage(1);
  };
  // preview of pagination
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const inventoryData = useFetchInventoryDashBoardGraphQuery({
    page: currentPage,
    page_size: pageSize,
    start_date: startDate,
    end_date: endDate,
    location: location,
    search: searchStr,
  });
  useEffect(() => {
    if (startDate === "" && endDate === "") {
      const { start, end } = getCurrentMonthRange();
      setStartDate(start);
      setEndDate(end);
    }
    if (inventoryData.isSuccess && inventoryData.data.results.length > 0) {
      setData(inventoryData.data.results);
    }
  }, [startDate, endDate, inventoryData]);

  const totalPage = Math.ceil(inventoryData.data?.count / pageSize);
  let content;
  let content_prod;
  //   if (inventoryData.isLoading) {
  //     return <div>Loading....</div>;
  //   } else if (inventoryData.error) {
  //     return <div>Refresh the page....</div>;
  //   } else {

  //     console.log(inventoryData.data.results);
  //   }
  if (["1", "5", "6", "7", "8"].includes(location)) {
    TABLE_HEAD = [
      { head: "Item", customeStyle: "text-left" },
      {
        head: "UM",
        customeStyle: "text-left",
      },
      {
        head: "Ave Consumption",
        customeStyle: "text-left",
      },
      {
        head: "Total Consumption",
        customeStyle: "text-left",
      },
      {
        head: "Action",
        customeStyle: "text-left",
      },
    ];

    content = (
      <>
        {data.map((item, index) => {
          const isLast = index === data.length - 1;
          const classes = isLast ? "!p-4" : "!p-4 border-b border-gray-300";

          const totalProdConsumption =
            item?.prod_consumption?.reduce(
              (sum, record) => sum + (record?.total_cons ?? 0),
              0
            ) ?? 0;

          const totalReqConsumption =
            item?.req_consumption?.reduce(
              (sum, record) => sum + (record?.total_cons ?? 0),
              0
            ) ?? 0;

          const now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1); // 1st day of the month
          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

          const numberOfDays = lastDay.getDate() - firstDay.getDate() + 1;
          const dailyAverage =
            (totalProdConsumption + totalReqConsumption) / numberOfDays;
          return (
            <tr key={index}>
              <td className={classes}>
                <div className="flex items-center gap-4 text-left">
                  <div>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="!font-semibold"
                    >
                      {item.name}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
                >
                  {item.unit_measurement}
                </Typography>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  color="green"
                  className="!font-bold text-left"
                >
                  {dailyAverage.toFixed(2)}
                </Typography>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
                >
                  {(totalProdConsumption + totalReqConsumption).toFixed(2)}
                </Typography>
              </td>
              <td className={classes}>
                <BarChartModal itemData={item} />
              </td>
              {/* <td className={classes}>
				</td> */}
            </tr>
          );
        })}
      </>
    );
  } else {
    TABLE_HEAD = [
      {
        head: "Code",
        customeStyle: "text-left",
      },
      {
        head: "Name",
        customeStyle: "text-left",
      },
      {
        head: "UM",
        customeStyle: "text-left",
      },
      {
        head: "Ave Consumption",
        customeStyle: "text-left",
      },
      {
        head: "Total Del. Cons.",
        customeStyle: "text-left",
      },
      {
        head: "Actions",
        customeStyle: "text-left",
      },
    ];

    content = (
      <>
        {data.map((item, index) => {
          const isLast = index === data.length - 1;
          const classes = isLast ? "!p-4" : "!p-4 border-b border-gray-300";

          const totalDeliveryConsumption =
            item?.prod_consumption?.reduce(
              (sum, record) => sum + (record?.total_cons ?? 0),
              0
            ) ?? 0;

          // const totalReqConsumption = item.req_consumption
          //   ? item.req_consumption.reduce(
          //       (sum, record) => sum + record.total_cons,
          //       0
          //     )
          //   : 0;
          const now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1); // 1st day of the month
          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

          const numberOfDays = lastDay.getDate() - firstDay.getDate() + 1;
          const dailyAverage = totalDeliveryConsumption / numberOfDays;
          return (
            <tr key={index}>
              <td className={classes}>
                <div className="flex items-center gap-4 text-left">
                  <div>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="!font-semibold"
                    >
                      {item.product_code}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
                >
                  {item.nickname}
                </Typography>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
                >
                  {item.unit_measurement}
                </Typography>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  color="green"
                  className="!font-bold text-left"
                >
                  {dailyAverage?.toFixed(2)}
                </Typography>
              </td>
              <td className={classes}>
                <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
                >
                  {totalDeliveryConsumption?.toFixed(2)}
                </Typography>
              </td>
              <td className={classes}>
                <BarChartModal itemData={item} />
              </td>
              {/* <td className={classes}>
				</td> */}
            </tr>
          );
        })}
      </>
    );
  }

  return (
    <section className="m-10">
      <Card className="h-full w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4 mt-10"
        >
          <div>
            <Typography variant="h5" color="blue-gray">
              Inventory Consumption
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full md:w-72">
              <Select
                label="Select Location"
                name="location"
                value={location}
                onChange={handleChangeLocation}
                variant="outlined"
                lockScroll={true}
              >
                <Option value="1">Raw Materials</Option>
                <Option value="2">Foods Duty</Option>
                <Option value="3">Beverages</Option>
                <Option value="4">Consumables</Option>
                <Option value="5">Hardware</Option>
                <Option value="6">Repacking</Option>
                <Option value="7">Plastic</Option>
                <Option value="8">Office Supplies</Option>
              </Select>
            </div>
            <div className="w-full md:w-72">
              <DateInput
                label="Start Date"
                date={startDate}
                setDate={setStartDate}
                limiter={null}
              />
            </div>
            <div className="w-full md:w-72">
              <DateInput
                label="End Date"
                date={endDate}
                setDate={setEndDate}
                limiter={startDate}
              />
            </div>
            <div className="w-full md:w-72">
              <Input size="lg" label="Search" onChange={handleSearch} />
            </div>
            {/* <Button variant="outlined" className="flex items-center gap-2">
              24h
            </Button> */}
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head, customeStyle }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 ${customeStyle}`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            {inventoryData.isSuccess && inventoryData.data.results.lengt > 0 ? (
              <tbody>{content}</tbody>
            ) : (
              <tbody>{content}</tbody>
            )}
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {`${currentPage} of ${Math.ceil(totalPage) || 1}`}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
    </section>
  );
}

export default InventoryDashboardTbl;
