import { Card, Typography } from "@material-tailwind/react";
import { useState } from "react";

const TABLE_HEAD = [
  "Item Code",
  "Product",
  "Order",
  "Adjustment",
  "Unit Measurement",
];

const classes = "p-4 border-b border-blue-gray-50";

const AdjustDeliveryApprovalTbl = ({ items, onQtyChange }) => {
  const [productAdjustedQty, setProductAdjustedQty] = useState([]);

  const handleQtyChange = (index, value) => {
    setProductAdjustedQty((prevQty) => {
      let updatedQty = [...prevQty];

      // If the input is empty, set it as empty (so the user can clear it)
      if (value === "") {
        updatedQty[index] = "";
      } else if (!isNaN(value) && value >= 0) {
        updatedQty[index] = Math.min(parseInt(value), 9999);
      }

      let filteredProductData = updatedQty
        .map((qty, idx) => ({
          prodId: items[idx].id,
          prodQty: qty,
        }))
        .filter((item) => item.prodQty !== undefined && item.prodQty !== "");

      onQtyChange(filteredProductData);

      console.log(filteredProductData);

      return updatedQty;
    });
  };

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={item.id} className={classes}>
              <td className={classes}>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.product.product_code !== null &&
                      item.product.product_code !== undefined
                        ? item.product.product_code
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.product.name !== null &&
                      item.product.name !== undefined
                        ? item.product.name
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.ordered_qty !== null &&
                      item.ordered_qty !== undefined
                        ? item.ordered_qty
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </td>
              <td className={classes}>
                <div className="flex items-center justify-center">
                  <div className="flex flex-col">
                    <input
                      type="number"
                      value={
                        productAdjustedQty[index] !== undefined
                          ? productAdjustedQty[index]
                          : ""
                      }
                      onChange={(e) => handleQtyChange(index, e.target.value)}
                      className="w-20 border border-1 border-blue-gray-300 p-1"
                    />
                  </div>
                </div>
              </td>
              <td className={classes}>
                <div className="flex items-center gap-3">
                  <div className="flex flex-col">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {item.product.unit_measurement !== null &&
                      item.product.unit_measurement !== undefined
                        ? item.product.unit_measurement
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default AdjustDeliveryApprovalTbl;
