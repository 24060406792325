import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";

const CigarettesReportPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  const cigaretteData = [
    {
      name: "Marlboro Red",
      beg_inv: 12000,
      purchases: 5000,
      issuances: 10000,
      ending_inv: 7000,
      actual_ending_inv: 7000,
      short_over: 0,
    },
    {
      name: "Winston Blue",
      beg_inv: 15000,
      purchases: 7000,
      issuances: 13000,
      ending_inv: 9000,
      actual_ending_inv: 9000,
      short_over: 0,
    },
    {
      name: "Lucky Strike",
      beg_inv: 8000,
      purchases: 3000,
      issuances: 6000,
      ending_inv: 5000,
      actual_ending_inv: 5000,
      short_over: 0,
    },
    {
      name: "Camel",
      beg_inv: 5000,
      purchases: 2500,
      issuances: 4500,
      ending_inv: 3000,
      actual_ending_inv: 3000,
      short_over: 0,
    },
  ];

  // Function to add table headers (Cigarette types)
  function addTableHeaders(yOffset) {
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text("VARIABLES", 5, yOffset + 5); // Header for variables
    cigaretteData.forEach((cigarette, index) => {
      pdfDoc.text(cigarette.name, 40 + index * 35, yOffset + 5); // Header for cigarette types
    });
  }

  // Main Header for the page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("CIGARETTE COMPLIANCE REPORT", 5, 15); // Header for the report
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // Start a vertical table layout
  let currentYOffset = 25; // Initial Y-offset for the table

  // Add the table headers
  addTableHeaders(currentYOffset);

  // Variables row data (left side of the table)
  const variables = [
    { label: "BEGIN INV.", key: "beg_inv" },
    { label: "PURCHASES", key: "purchases" },
    { label: "ISSUANCES", key: "issuances" },
    { label: "ENDING INV.", key: "ending_inv" },
    { label: "ACTUAL ENDING INV.", key: "actual_ending_inv" },
    { label: "SHORT/OVER", key: "short_over" },
  ];

  // Loop through the variables and fill the table
  variables.forEach((variable, varIndex) => {
    const yOffsetRow = currentYOffset + 10 + varIndex * 10; // Adjusted Y spacing for each row

    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text(variable.label, 5, yOffsetRow); // Add the variable label on the left

    pdfDoc.setFont("helvetica", "normal");

    // Fill in the data for each cigarette type
    cigaretteData.forEach((cigarette, index) => {
      pdfDoc.text(
        cigarette[variable.key].toString(),
        40 + index * 35,
        yOffsetRow
      );
    });
  });

  // Save the PDF
  pdfDoc.save(
    `Cigarettes_Compliance_Report_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default CigarettesReportPDF;
