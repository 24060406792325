import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const LPGReportPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  const lpgData = [
    {
      type: "11KG",
      beginningInventory: [459, 913, 3, 11],
      purchases: [1250],
      credits: [6],
      returnFromTransport: [12, 1287, 5, 9],
      availableForIssuance: [1721, 2200, 8, 16],
      returnToSupplier: [1280, 6],
      charges: [3],
      issuancesToDutyAreas: [4],
      loadOut: [1299],
      endingInventory: [422, 920, 4, 16],
      actualEndingInventory: [422, 920],
    },
    {
      type: "50KG",
      beginningInventory: [418, 933, 6, 14],
      purchases: [1280],
      credits: [4],
      returnFromTransport: [5, 1223, 5],
      availableForIssuance: [1701, 2160, 6, 19],
      returnToSupplier: [1248, 5],
      charges: [3],
      issuancesToDutyAreas: [5],
      loadOut: [1214],
      endingInventory: [482, 910, 1, 14],
      actualEndingInventory: [482, 910],
    },
    // Add more LPG types as needed
  ];

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("LPG COMPLIANCE REPORT", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // Table Section for each LPG Type
  lpgData.forEach((lpg, index) => {
    pdfDoc.setFontSize(12);
    pdfDoc.text(`LPG Compliance for ${lpg.type}`, 30, 55 + index * 80); // Adjust vertical position for each type

    const tableData = [
      ["Beginning Inventory", `${lpg.beginningInventory.join(", ")} kgs`],
      ["Purchases from Suppliers", `${lpg.purchases.join(", ")} kgs`],
      ["Add: Credits", `${lpg.credits.join(", ")} kgs`],
      ["Return from Transport", `${lpg.returnFromTransport.join(", ")} kgs`],
      ["Available for Issuance", `${lpg.availableForIssuance.join(", ")} kgs`],
      ["Return to Supplier (RTS)", `${lpg.returnToSupplier.join(", ")} kgs`],
      ["Less: Charges (bmhc/hauler)", `${lpg.charges.join(", ")} kgs`],
      [
        "Less: Issuances to Duty Areas",
        `${lpg.issuancesToDutyAreas.join(", ")} kgs`,
      ],
      ["Less: Issuances/Load Out", `${lpg.loadOut.join(", ")} kgs`],
      ["Ending Inventory", `${lpg.endingInventory.join(", ")} kgs`],
      [
        "Actual Ending Inventory",
        `${lpg.actualEndingInventory.join(", ")} kgs`,
      ],
    ];

    pdfDoc.autoTable({
      startY: 65 + index * 80, // Adjust starting Y position for each LPG type
      head: [["Description", "Amount"]],
      body: tableData,
      theme: "grid",
    });
  });

  // Footer Section
  const lastYPosition = pdfDoc.autoTable.previous.finalY + 20;
  pdfDoc.text("LPG Compliance Overview", 30, lastYPosition);
  pdfDoc.save(
    `LPG_Compliance_Report_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default LPGReportPDF;
