import React, {
  useState, useEffect
} from "react";
import {
  Button, Typography,
  Card, CardBody, CardFooter,
  Input
} from "@material-tailwind/react";
import {
  FaTools, FaTruck,
  FaCheckCircle
} from 'react-icons/fa';
import { usePoListStatusQuery } from "../../../store";
import { format } from "date-fns";


export function POStatusesWidget({po_status, po_working, onClickHandleView}) {

  const [workingCount, setWorkingCount] = useState(0);
  const [waitingForDeliveryCount, setWaitingForDeliveryCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);

  const [status, setStatus] = useState('1')
  const [page, setPage] = useState(1)
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")

  const onChangeSetStatus = (event) => {
    setPage(1)
    setStatus(event.target.value)
  }

  const totalPages = () => {
    return (poListStats.data?.count / 10 ) > Math.floor(poListStats.data?.count / 10) ? (1 + Math.floor(poListStats.data?.count / 10)) : Math.floor(poListStats.data?.count / 10)
  }

  const poListStats = usePoListStatusQuery({
    poStatus : status,
    page : page,
    page_size: 10,
    from : start == "" ? '1981-01-01' : start,
    to : end == "" ? new Date().toISOString().split("T")[0] : end
  })

  const onChangeDate = (value, type) => {
    if (type == 'start'){
      setStart(value)
    }else{
      setEnd(value)
    }
    setPage(1)
  }

  console.log(poListStats.isLoading)
  console.log(poListStats.data)

  console.log(po_status)
  useEffect(() => {
    setWorkingCount(po_working.count_working); // Set working count
    setWaitingForDeliveryCount(po_status.count_queue); // Set waiting for delivery count
    setDeliveredCount(po_status.count_submitted); // Set delivered count
  }, []);

  return <>
      <main className="w-full">
        <div className="w-full sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="w-full flex gap-x-2 mb-6">
              <div className="flex flex-1 gap-x-2">
                  <div className=" rounded-md shadow-sm min-w-52 max-w-52">
                    <Input
                      size="lg"
                      label="Search"
                    />
                  </div>

                  <div className=" rounded-md shadow-sm max-w-40 flex-1 mr-10">
                    <Input 
                      size="lg"
                      label="Start Date"
                      type="date"
                      value={start}
                      onChange={(e) => onChangeDate(e.target.value, 'start')}
                    />
                  </div>

                  <div className=" rounded-md shadow-sm min-w-40 max-w-40 flex-1">
                    <Input 
                      size="lg"
                      label="End Date"
                      type="date"
                      value={end}
                      onChange={(e) => onChangeDate(e.target.value, 'end')}
                    />
                  </div>

                </div>

                <div className="">
                  <select value={status} onChange={(event) => onChangeSetStatus(event)} className="block w-full h-full pl-3 pr-10 py-2 text-base border border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option value="1">Pending</option>
                    <option value="6">Raw Material</option>
                    <option value="4">Completed</option>
                  </select>
                </div>
            </div>
            
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            PO Number
                          </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {status == '1' ? "Date" : 'Submited Date'}
                            </th>                            
                          {status == 4 || status == 6 ? 
                          // if status is completed display delivery date
                           <>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {status == 4 ? 'Delivery Date' : 'Actual Delivery Date'}
                            </th>
                           </>
                            :
                            // if the status not is not completed
                            <>
                            </>
                          }
                          
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            {status == 1 || status == 6 ? 'Deparment' : 'Vendor'}
                          </th>

                          {status == 4 ? 
                          // if status is completed display delivery date
                           <>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Vendor Address
                            </th>
                           </>
                            :
                            // if the status not is not completed
                            <>
                            </>
                          }

                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {poListStats.isLoading ?
                          <>
                            <tr className="flex w-full justify-center py-2">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <h1 className="text-gray-700 text-center">Loading...</h1>
                              </td>
                            </tr>
                          </>
                          :
                          <>
                            {poListStats.data?.results.map((poStats) => {
                              return (
                                <tr key={poStats.id}>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm font-medium text-gray-900">{poStats.po_number}</div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">{poStats.status == 1 || poStats.status == 6 ? format(new Date(poStats.date), "MM/dd/yyyy hh:mm a") : format(new Date(poStats.submitted_date), "MM/dd/yyyy hh:mm a")}</div>
                                    {/* <div className="text-sm text-gray-500">Due: Apr 4, 2025</div> */}
                                  </td>
                                  {status == 4 || status == 6?
                                    // if status is completed display delivery date
                                    <>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <h1 className="text-sm text-gray-900">{status == 4 ? poStats?.delivery_date : format(new Date(poStats?.actual_delivery_date), "MM/dd/yyyy hh:mm a") }</h1>
                                      </td>
                                    </>
                                      :
                                      // if the status not is not completed
                                      <>
                                      </>
                                  }
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      {poStats.status == 1 ? 'Pending' : `${poStats.status == 6 ? "Raw Material" : "Completed"}`}
                                    </span>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">{status == '1' || status == 6 ? poStats.department?.name : poStats.vendor?.name}</div>
                                  </td>
                                  {status == 4 ? 
                                    // if status is completed display delivery date
                                    <>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <h1 className="text-sm text-gray-900">{poStats.vendor?.address}</h1>
                                      </td>
                                    </>
                                      :
                                      // if the status not is not completed
                                      <>
                                      </>
                                  }
                                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <div className="flex justify-end space-x-2">
                                      {status == 4 ?
                                        <>
                                          <button onClick={() => onClickHandleView(poStats.id, status, poStats?.vendor)} className="text-indigo-600 hover:text-indigo-900">View</button>
                                        </>
                                        :
                                        <>
                                          <button onClick={() => onClickHandleView(poStats.id, status)} className="text-indigo-600 hover:text-indigo-900">View</button>
                                        </>
                                      }
                                    </div>
                                  </td>
                                </tr>
                            )
                            })}
                          </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 mt-4">
              <div className="flex-1 flex justify-between sm:hidden">
                <a href="#" className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Previous
                </a>
                <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                  Next
                </a>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between ">
                <div>
                  <p className="text-sm text-gray-700">
                    {page >= 1 ? `Page ${page} of ${totalPages()}` : `Page 1 of ${totalPages()}`}
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px gap-x-2" aria-label="Pagination">
                    <button onClick={() => {page >= 1 ? setPage(page - 1) : setPage(1)}}  className={`relative inline-flex items-center px-2 py-2 rounded-md border bg-white text-sm font-medium hover:bg-gray-50 ${page == 1 ? 'border-gray-500 text-gray-600' : 'border-gray-700 text-gray-800'}`}>
                      <h1>PREVIOUS</h1>
                    </button>
                    <button onClick={() => {page < totalPages() ? setPage(page + 1) : setPage(totalPages())}} className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium rounded-md text-gray-500 hover:bg-gray-50 ${page == totalPages() ? 'border-gray-500 text-gray-600' : 'border-gray-700 text-gray-800'}`}>
                      <h1>NEXT</h1>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
  </>
}

export default POStatusesWidget;
