import React from "react";
import RequestApprovalModal from "../../modal_form_components/Approval/RequestApprovalModal";
import RTSApprovalModal from "../../modal_form_components/Approval/RTSApprovalModal";
import {
  Typography,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";

function RTSTransferTable({
  DelSlipHead,
  delPageSize,
  handleDeliveryPageSize,
  fetchDelResult,
  delCurrentPage,
  DelSlipTotalPage,
  handleDelPreviousPage,
  handleDelNextPage,
  data_refetch,
}) {
  return (
    <>
      <div className="container mx-auto lg:px-4">
        <div className="md:px-5">
          <div className="flex justify-end mx-2">
            <div className="flex justify-end items-center mt-5 md:mt-0 mr-10 space-x-4">
              <Typography>Page size:</Typography>
              <select
                defaultValue={delPageSize}
                onChange={handleDeliveryPageSize}
                className="border-2 border-blue-gray-500 cursor-pointer"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <CardBody className="px-0 overflow-y-auto max-h-[60vh]">
            <table className=" w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {DelSlipHead.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fetchDelResult.map((v, index) => {
                  const isLast = index === fetchDelResult.length - 1;
                  const classes = isLast
                    ? "pt-3 px-5"
                    : "py-3 px-5 border-b border-blue-gray-50";
                  const formattedDate = v.requested_at
                    ? new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(v.requested_at))
                    : "";

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.transfer_number || "N/A"}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {(() => {
                              switch (v.item_section) {
                                case 1:
                                  return "Raw Materials";
                                case 2:
                                  return "Foods Duty";
                                case 3:
                                  return "Beverages";
                                case 4:
                                  return "Consumables";
                                case 5:
                                  return "Hardware";
                                case 6:
                                  return "Repacking";
                                case 7:
                                  return "Plastic";
                                case 8:
                                  return "Office Supplies";
                                case 0:
                                  return "None";
                                default:
                                  return "Unknown";
                              }
                            })()}
                          </Typography>
                        </div>
                      </td>
                      {/* <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >

                            {v.transfer &&
                            v.transfer.transfer_number !== null &&
                            v.transfer.transfer_number !== undefined
                              ? v.transfer.transfer_number
                              : "TBD"}
                          </Typography>
                        </div>
                      </td> */}
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.rts_vendor &&
                            v.rts_vendor !== null &&
                            v.rts_vendor !== undefined
                              ? v.rts_vendor.name
                              : "None"}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.requested_by.first_name +
                              " " +
                              v.requested_by.last_name || "N/A"}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate || "N/A"}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            <RTSApprovalModal
                              getTransNum={v.transfer_number}
                              transData={v}
                              data_refetch={data_refetch}
                            />
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page {delCurrentPage} of {DelSlipTotalPage}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={handleDelPreviousPage}
                disabled={delCurrentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={handleDelNextPage}
                disabled={delCurrentPage === DelSlipTotalPage}
              >
                Next
              </Button>
            </div>
          </CardFooter>
        </div>
      </div>
    </>
  );
}

export default RTSTransferTable;
