import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { useFetchDeliveryOutletQuery } from "../../../store";
import { useFetchUserListPageQuery } from "../../../store";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import {
  ProductTbl,
  BeveragesTbl,
  BunsTbl,
  WrappersTbl,
  CigaretteTbl,
  OSTbl,
  SundriesTbl,
  TankTbl,
} from "../../table_components/Delivery/WithOrderTbl";
import { useAddWithdrawalRequestMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import DelSchedulePicker from "../../form_components/calendar/DeliverySelectDelDate";
import moment from "moment";

import { toast } from "react-toastify";

const WithdrawModal2 = ({ refetchDeliveryList }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const trapMessage = "Are you sure to return these orders?";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const {
    data: outletData,
    error,
    isLoading,
    isSuccess,
  } = useFetchDeliveryOutletQuery();
  const [assignedRoute, setAssignedRoute] = useState("Route 1");

  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const handleOutletChange = (value) => {
    const outletId = parseInt(value, 10);
    const outlet = outlet_list_result.find((o) => o.id === outletId);
    setSelectedOutlet(outlet);
  };
  const handleDriverChange = (value) => {
    const driverId = parseInt(value, 10);
    const selectedDriver = user_list_result.find(
      (driver) => driver.id === driverId
    );
    SetDriver(selectedDriver); // Assuming SetDriver is a function to set the driver object in the state
    console.log(selectedDriver);
  };
  const {
    data: userData,
    isError: userErr,
    isLoading: userLoading,
    isSuccess: userSuccess,
  } = useFetchUserListPageQuery({
    page: 1,
    page_size: 50,
    user_type: 16,
  });

  useEffect(() => {
    if (selectedOutlet) {
      setAssignedRoute(selectedOutlet.route.name);
    }
  }, [selectedOutlet]);

  const generateFC = () => {
    return selectedOutlet ? selectedOutlet.fc.name : "";
  };
  const generateRoute = () => {
    return assignedRoute;
  };
  const generateAddress = () => {
    return selectedOutlet ? selectedOutlet.address : "";
  };
  let [productData, setProductData] = useState([]);
  let [beveragesData, setBeveragesData] = useState([]);
  let [bunsData, setBunsData] = useState([]);
  let [wrappersData, setWrappersData] = useState([]);
  let [sundriesData, setSundriesData] = useState([]);
  let [cigarettesData, setCigarettesData] = useState([]);
  let [osData, setOsData] = useState([]);
  let [tankData, setTankData] = useState([]);
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  let [deliveryDate, setdeliveryDate] = useState(null);
  const [crewName, setCrewName] = useState("");
  const [driver, SetDriver] = useState(null);

  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
  };

  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };

  const handleCrewNameChange = (event) => {
    setCrewName(event.target.value);
  };

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    const anyArrayHasData =
      (productData.length > 0 ||
        beveragesData.length > 0 ||
        bunsData.length > 0 ||
        wrappersData.length > 0 ||
        sundriesData.length > 0 ||
        cigarettesData.length > 0 ||
        osData.length > 0 ||
        tankData.length > 0) &&
      selectedOutlet !== null &&
      crewName !== "" &&
      driver !== null &&
      signature !== "";

    // Update isReadyToSave based on the condition
    setIsReadyToSave(anyArrayHasData);
  }, [
    productData,
    beveragesData,
    bunsData,
    wrappersData,
    sundriesData,
    cigarettesData,
    osData,
    tankData,
    selectedOutlet,
    crewName,
    driver,
    signature,
  ]);

  // const logo = JSON.parse(localStorage.getItem("account_detail"));
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");

  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position == 15
    ) {
      if (account_detailed.job_position === 15 && isSuccess) {
        const outlet = outletData.data.find(
          (outlet) => outlet.id === account_detailed.outlet
        );
        if (outlet) {
          setSelectedOutlet(outlet);
        }
      }
    } else {
      return;
    }
  }, [token, outletData, isSuccess]);
  const delDate = (day) => {
    // Update the selected date or perform any other actions
    setdeliveryDate(day);
  };

  let handleProductOrderChange = (data) => {
    setProductData(data);
  };
  let handleBeveragestOrderChange = (bev_data) => {
    setBeveragesData(bev_data);
  };
  let handleBunsOrderChange = (buns_data) => {
    setBunsData(buns_data);
  };

  let handleWrappersOrderChange = (wrappers_data) => {
    setWrappersData(wrappers_data);
  };

  let handleSundriesOrderChange = (sundries_data) => {
    setSundriesData(sundries_data);
  };

  let handleCigarettesOrderChange = (cigarettes_data) => {
    setCigarettesData(cigarettes_data);
  };

  let handleOsOrderChange = (os_data) => {
    setOsData(os_data);
  };

  let handleTankOrderChange = (tank_data) => {
    setTankData(tank_data);
  };
  const [addWithrdrawalRequest] = useAddWithdrawalRequestMutation();
  const handleSave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components

    // Combine all data as needed
    let allData = {
      crew: crewName,
      outlet: selectedOutlet.id,
      signature: signature,
      user: account_detailed.user.id,
      driver: driver.user.id,
      withdrawalItems: [],
    };

    // Merge data from different categories into withdrawalItems array
    allData.withdrawalItems = allData.withdrawalItems.concat(productData);
    allData.withdrawalItems = allData.withdrawalItems.concat(beveragesData);
    allData.withdrawalItems = allData.withdrawalItems.concat(bunsData);
    allData.withdrawalItems = allData.withdrawalItems.concat(wrappersData);
    allData.withdrawalItems = allData.withdrawalItems.concat(sundriesData);
    allData.withdrawalItems = allData.withdrawalItems.concat(cigarettesData);
    allData.withdrawalItems = allData.withdrawalItems.concat(osData);
    allData.withdrawalItems = allData.withdrawalItems.concat(tankData);

    console.log(allData);
    try {
      const sea = await addWithrdrawalRequest(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      console.error("Error:", error);
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }

    setProductData([]);
    setBeveragesData([]);
    setBunsData([]);
    setWrappersData([]);
    setSundriesData([]);
    setCigarettesData([]);
    setOsData([]);
    setTankData([]);
    setSelectedOutlet();
    handleClose();
  };
  console.log(driver);
  let outlet_list_result = [];
  let user_list_result = [];
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (outletData.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess && userSuccess) {
    outlet_list_result = outletData.data;
    user_list_result = userData.results;
    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
          Withdrawal Form
        </Button>
        <Dialog
          open={open}
          size="xl"
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img
              src={burgerMachineLogo}
              alt="bmlogo"
              className="aspect-auto w-72"
            /> */}
            <Typography className="text-green-900" variant="h3">
              Withdrawal Form
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <Select
                    label="Outlet"
                    onChange={handleOutletChange}
                    value={selectedOutlet ? `${selectedOutlet.id}` : null}
                    disabled={account_detailed.job_position === 15}
                  >
                    {outlet_list_result.map((outlet) => (
                      <Option
                        key={outlet.id}
                        value={`${outlet.id}`}
                        fc={outlet.fc}
                        address={outlet.address}
                      >
                        {outlet.outlet}
                      </Option>
                    ))}
                  </Select>
                  <div className={inputClasses}>
                    <Input label="FC" name="fc" value={generateFC()} readOnly />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Crew On Duty"
                      name="crew_on_duty"
                      value={crewName}
                      onChange={handleCrewNameChange}
                    />
                  </div>
                  {crewName === "" && (
                    <span className="text-red-500 text-xs">
                      Must input a crew.
                    </span>
                  )}
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Route"
                      name="route"
                      value={generateRoute()}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Address"
                      name="address"
                      value={generateAddress()}
                      readOnly
                    />
                  </div>
                  <Select
                    label="Driver"
                    onChange={handleDriverChange}
                    value={driver ? `${driver.id}` : null} // Use driver.id instead of driver
                  >
                    {user_list_result.map((driver) => (
                      <Option
                        key={driver.id}
                        value={`${driver.id}`} // Use driver.id instead of driver
                      >
                        {driver.user.first_name} {driver.user.last_name}
                      </Option>
                    ))}
                  </Select>

                  {driver === null && (
                    <span className="text-red-500 text-xs">
                      Select a driver.
                    </span>
                  )}
                </div>
              </div>

              <div className="m-5">
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Products
                  <ProductTbl onOrderChange={handleProductOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Beverages
                  <BeveragesTbl onOrderChange={handleBeveragestOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Buns/Bread
                  <BunsTbl onOrderChange={handleBunsOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Wrappers
                  <WrappersTbl onOrderChange={handleWrappersOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Sundries
                  <SundriesTbl onOrderChange={handleSundriesOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Cigarettes
                  <CigaretteTbl onOrderChange={handleCigarettesOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Operating Supplies
                  <OSTbl onOrderChange={handleOsOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Tank
                  <TankTbl onOrderChange={handleTankOrderChange} />
                </Typography>
              </div>
            </form>

            {driver !== null && (
              <>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      onEnd={handleSignatureChange}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={30}
                        className="cursor-pointer"
                        onClick={handleSignatureReset}
                        color="black"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <Typography className="text-justify text-black" variant="h6">
                    {driver.user.first_name
                      ? driver.user.last_name
                        ? `${driver.user.first_name} ${driver.user.last_name}`
                        : "Driver"
                      : "Driver"}
                  </Typography>
                </div>
                {signature == "" && (
                  <Typography variant="lead" color="red">
                    Please provide a signature.
                  </Typography>
                )}
              </>
            )}
          </DialogBody>

          <DialogFooter>
            {selectedOutlet == null && (
              <Typography variant="lead" color="red">
                Please provide a outlet.
              </Typography>
            )}
            <Confirmation
              onConfirm={handleSave}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
};

export default WithdrawModal2;
