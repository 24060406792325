import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Select,
  Option,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import { useFetchWithdrawalRtsListPageQuery } from "../../store";
import ReturnToSellerModal from "../modal_form_components/Warehouse/WarehouseTransfer/ReturnToSellerModal";
import RTSTransferModal from "../modal_form_components/Warehouse/WarehouseTransfer/RTSTransferModal";
export function CompleteTransferRTSTableView({
  ViewTitle,
  viewDescription,
  TABLE_HEAD_DEL,
  buttonForModal,
  extractPOButton,
  onTopButtonClick,
  onTopExtractPOButtonClick,
}) {
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [result, setResult] = useState([]);

  const columnMapping = {
    "Tracking Number": "id",
    "Issuance From": "department_from__name",
    "Requested By": "requested_by__first_name",
    "Request Date": "requested_at",
    "Actual Transfer Date": "receive_at",
    Action: "id",
    Remarks: "types",
  };

  const { data, error, isLoading, isSuccess, refetch } =
    useFetchWithdrawalRtsListPageQuery({
      page: currentPage,
      page_size: pageSize,
      search: searchTerm,
      order_by: sortConfig.key,
      order_dir: sortConfig.direction,
      types: 4,
    });
  useEffect(() => {
    refetch();
    if (isSuccess) {
      setResult(data.results);
    }
  }, [isSuccess, data]);
  const handleSort = (column) => {
    let direction = "asc";
    if (
      sortConfig.key === columnMapping[column] &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnMapping[column], direction });
  };
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (selectedSize) => {
    console.log("Selected Page Size:", selectedSize);
    let page = parseInt(selectedSize);
    setPageSize(page);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  console.log(result);
  return (
    <Card className="h-full w-full ">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex justify-end md:mr-10 space-x-4">
          <ReturnToSellerModal />
        </div>
        <div className="md:flex justify-between items-center">
          <div className="space-y-5 md:ml-10 mt-5 md:mt-5">
            <Input
              label="Search"
              icon={<FiSearch className="h-5 w-5" />}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className=" flex justify-end items-center space-x-3 mt-5 md:mr-10">
            <Typography>Page size:</Typography>
            <select
              label="Page Size"
              className=" border border-black"
              value={`${pageSize}`}
              onChange={(e) => handlePageSizeChange(e)}
            >
              <option value={`${10}`}>10</option>
              <option value={`${25}`}>25</option>
              <option value={`${50}`}>50</option>
            </select>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD_DEL.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  onClick={() => handleSort(head)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {result.length > 0 ? (
              result.map((t, index) => {
                const isLast = index === result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                const formattedDate = t.requested_at
                  ? new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(t.requested_at))
                  : "";
                return (
                  <tr key={index}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {t.transfer_number || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {t.rts_vendor !== null
                              ? t.rts_vendor.name || "N/A"
                              : ""}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {(() => {
                              switch (t.item_section) {
                                case 1:
                                  return "Raw Materials";
                                case 2:
                                  return "Foods Duty";
                                case 3:
                                  return "Beverages";
                                case 4:
                                  return "Consumables";
                                case 5:
                                  return "Hardware";
                                case 6:
                                  return "Repacking";
                                case 7:
                                  return "Plastic";
                                case 8:
                                  return "Office Supplies";
                                case 0:
                                  return "None";
                                default:
                                  return "Unknown";
                              }
                            })()}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {t.requested_by?.first_name || "N/A"}{" "}
                            {t.requested_by?.last_name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <RTSTransferModal
                            requestId={t.id}
                            section={t.section}
                            refetchMainTable={refetch}
                            types={t.types}
                            transData={t}
                          />
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col items-center">
                        <div
                          className={`relative grid select-none items-center whitespace-nowrap rounded-lg ${
                            t.types === 1
                              ? "bg-yellow-500"
                              : t.types > 1
                              ? "bg-yellow-500"
                              : ""
                          } py-1.5 px-3 font-sans text-xs font-bold uppercase ${
                            t.types === 1 ? "text-black" : "text-black"
                          }`}
                        >
                          <span>
                            {t.types === 1
                              ? "Issue"
                              : t.types > 1
                              ? "Issue"
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={7}
                  className="p-4 border-b border-blue-gray-50 items-center justify-center"
                >
                  <Typography color="blue-gray" className="font-normal">
                    No Data Available
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            size="sm"
            onClick={handlePreviousPage}
            disabled={!data?.previous}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={handleNextPage}
            disabled={!data?.next}
          >
            {data?.next ? "Next" : "No More Pages"}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
