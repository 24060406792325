import React, { useState } from "react";
import { Select, Option, Input } from "@material-tailwind/react";

// Helper function to format date as YYYY-MM-DD in local time
const formatLocalDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const getUnusualPeriods = (year, month) => {
  // Determine previous month/year
  const prevMonth = month === 0 ? 11 : month - 1;
  const prevYear = month === 0 ? year - 1 : year;

  // last day of the previous month
  const lastDayPrevMonth = new Date(year, month, 0).getDate();

  return [
    {
      label: `${new Date(prevYear, prevMonth).toLocaleString("default", {
        month: "short",
      })} 24 - ${new Date(year, month).toLocaleString("default", {
        month: "short",
      })} 8`,
      startDate: formatLocalDate(new Date(prevYear, prevMonth, 24)),
      endDate: formatLocalDate(new Date(year, month, 8)),
    },
    {
      label: `${new Date(year, month).toLocaleString("default", {
        month: "short",
      })} 9 - ${new Date(year, month).toLocaleString("default", {
        month: "short",
      })} 23`,
      startDate: formatLocalDate(new Date(year, month, 9)),
      endDate: formatLocalDate(new Date(year, month, 23)),
    },
  ];
};

const UnusualPeriodSelector = ({ onPeriodChange }) => {
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth()); // Zero-based month
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const periods = getUnusualPeriods(year, month);

  const handlePeriodChange = (value) => {
    const period = periods.find((p) => p.label === value);
    setSelectedPeriod(period);
    if (period) {
      onPeriodChange(period.startDate, period.endDate);
    }
  };

  const handleChangeMonth = (value) => {
    const newMonth = parseInt(value, 10);
    setMonth(newMonth);
    const newPeriods = getUnusualPeriods(year, newMonth);
    setSelectedPeriod(newPeriods[0]);
    onPeriodChange(newPeriods[0].startDate, newPeriods[0].endDate);
  };

  return (
    <>
      {/* Year Input (Disabled) */}
      <div className="flex flex-col gap-2">
        <Input type="number" value={year} disabled />
      </div>

      {/* Month Selector */}
      <div className="flex flex-col gap-2">
        <Select
          value={month.toString()}
          onChange={(val) => handleChangeMonth(val)}
          label="Select Month"
          variant="outlined"
          lockScroll
        >
          {Array.from({ length: 12 }).map((_, index) => (
            <Option key={index} value={index.toString()}>
              {new Date(0, index).toLocaleString("default", { month: "long" })}
            </Option>
          ))}
        </Select>
      </div>

      {/* Period Selector */}
      <div className="flex flex-col gap-2">
        <Select
          label="Select Period"
          onChange={(val) => handlePeriodChange(val)}
          value={selectedPeriod?.label || periods[0].label}
          variant="outlined"
          lockScroll
        >
          {periods.map((period) => (
            <Option key={period.label} value={period.label}>
              {period.label}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default UnusualPeriodSelector;
