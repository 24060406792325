import React, { useState, useEffect } from "react";
import { Typography, Card, CardBody } from "@material-tailwind/react";
import { IoMdCodeWorking } from "react-icons/io";
import { MdOutlinePendingActions } from "react-icons/md";
import { formatDateMDY, formatToNumber } from "../../reusable/reuse_function";

export function DeliveryTablesSalesView({
  sales,
  noOfDel,
  startDate,
  endDate,
}) {
  const [totalSales, setTotalSales] = useState(0);
  const [totalDeliveries, setTotalDeliveries] = useState(0);

  useEffect(() => {
    setTotalSales(sales);
    setTotalDeliveries(noOfDel);
  }, [sales, noOfDel]);

  return (
    <Card className="w-full bg-gradient-to-br from-green-500 to-green-700 text-white shadow-lg rounded-lg p-4">
      <CardBody className="flex flex-col items-center">
        {/* Date Range */}
        <Typography variant="h6" className="font-bold text-lg">
          {formatDateMDY(startDate)} ~ {formatDateMDY(endDate)}
        </Typography>

        {/* Data Section */}
        <div className="grid grid-cols-2 gap-6 mt-5 w-full">
          {/* Total Sales */}
          <div className="flex flex-col items-center bg-white/10 rounded-lg p-5 shadow-md">
            <MdOutlinePendingActions className="text-4xl text-white mb-2" />
            <Typography variant="h6" className="text-white">
              TOTAL SALES
            </Typography>
            <Typography className="text-2xl font-bold">
              ₱ {formatToNumber(totalSales)}
            </Typography>
          </div>

          {/* Total Deliveries */}
          <div className="flex flex-col items-center bg-white/10 rounded-lg p-5 shadow-md">
            <IoMdCodeWorking className="text-4xl text-white mb-2" />
            <Typography variant="h6" className="text-white">
              NO. OF DELIVERY
            </Typography>
            <Typography className="text-2xl font-bold">
              {totalDeliveries}
            </Typography>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default DeliveryTablesSalesView;
