import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function ConfirmationV2({
  onConfirm,
  isReadyToSave,
  trapMessage,
  buttonName,
}) {
  const [open, setOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const confirmButtonRef = useRef(null);

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    if (isButtonDisabled) return; // Prevent multiple clicks

    setIsButtonDisabled(true); // Disable button
    onConfirm(event);
    handleOpen();
    console.log(isReadyToSave);

    // Re-enable button after 5 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="bg-blue-800 ml-10"
        disabled={!isReadyToSave}
      >
        {buttonName || "Confirmation"}
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confirmation</DialogHeader>
        <DialogBody>{trapMessage}</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleConfirm}
            disabled={isButtonDisabled} // Disable confirm button when needed
            ref={confirmButtonRef}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
