import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const PattyProdctionPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("PATTY PRODUCTION REPORT", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // USAGE Section
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("USAGE", 5, 30);

  // Patty Production Based on PTCM
  pdfDoc.setFont("helvetica", "normal");
  pdfDoc.text("PATTY PRODUCTION", 5, 35);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("Based on PTCM:", 5, 40);

  // PTCM Data
  pdfDoc.setFont("helvetica", "normal");
  pdfDoc.text("Meat used for patty production (per kilo)", 5, 45);
  pdfDoc.text("1,310.00", xFirstInput, 45);
  pdfDoc.text("3,272.00", xSecondInput, 45);
  pdfDoc.text("3,589.00", xThirdInput, 45);
  pdfDoc.text("1,629.00", xFourthInput, 45);

  pdfDoc.text("Patty output (in pieces)", 5, 50);
  pdfDoc.text("100,000", xFirstInput, 50);
  pdfDoc.text("220,000", xSecondInput, 50);
  pdfDoc.text("250,000", xThirdInput, 50);
  pdfDoc.text("570,000", xFourthInput, 50);

  // Patty Production Based on TSD
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("Based on TSD:", 5, 60);

  // TSD Data
  pdfDoc.setFont("helvetica", "normal");
  pdfDoc.text("Meat used for patty production (per kilo)", 5, 65);
  pdfDoc.text("1,320.00", xFirstInput, 65);
  pdfDoc.text("3,280.00", xSecondInput, 65);
  pdfDoc.text("3,600.00", xThirdInput, 65);
  pdfDoc.text("1,640.00", xFourthInput, 65);

  pdfDoc.text("Patty output (in pieces)", 5, 70);
  pdfDoc.text("105,000", xFirstInput, 70);
  pdfDoc.text("225,000", xSecondInput, 70);
  pdfDoc.text("255,000", xThirdInput, 70);
  pdfDoc.text("585,000", xFourthInput, 70);

  // Patty Production Based on Operations
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("Based on OPERATIONS:", 5, 80);

  // Operations Data
  pdfDoc.setFont("helvetica", "normal");
  pdfDoc.text("Meat used for patty production (per kilo)", 5, 85);
  pdfDoc.text("1,296.50", xFirstInput, 85);
  pdfDoc.text("3,293.00", xSecondInput, 85);
  pdfDoc.text("3,496.00", xThirdInput, 85);
  pdfDoc.text("1,635.00", xFourthInput, 85);

  pdfDoc.text("Patty output (in pieces)", 5, 90);
  pdfDoc.text("100,000", xFirstInput, 90);
  pdfDoc.text("220,000", xSecondInput, 90);
  pdfDoc.text("250,000", xThirdInput, 90);
  pdfDoc.text("570,000", xFourthInput, 90);
  pdfDoc.save(
    `Patty_Production_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default PattyProdctionPDF;
