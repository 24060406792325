import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";

import { useFetchRouteListQuery } from "../../../store";
import { useUpdateDelDailyScheduleMutation } from "../../../store";
import { useFetchDelScheduleQuery } from "../../../store";

import moment from "moment";
import { toast } from "react-toastify";
import { Confirmation } from "../../modal_form_components/Error_trap_components/ErrorTrapComponent";

function EditRouteModal({
  refetchSched,
  dayIndex,
  isSameSelectedDate,
  selectedDate,
  day,
  isDisabled,
  isToday,
  monthData,
}) {
  let route_data = [];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["", "Route"];
  const [currentDate, setCurrentDate] = useState(moment());
  const monthValue = currentDate.month() + 2;

  const [updateDeliverySchedule] = useUpdateDelDailyScheduleMutation();
  const { data, error, isLoading, isSuccess, refetch } = useFetchRouteListQuery(
    {
      page: 1,
      page_size: 50,
    }
  );
  const [isNewRouteDialogOpen, setIsSetNewRouteDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOldMonRoute, setSelectedOldMonRoute] = useState([]);
  const [selectedNewRoutes, setSelectedNewRoutes] = useState([]);
  const [areRoutesReadyToSave, setAreRoutesReadyToSave] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(monthValue.toString());
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  useEffect(() => {
    // Check if any of the route states have data
    const anyRoutesSelected = selectedNewRoutes.length > 0;

    // Update the state of areRoutesReadyToSave
    setAreRoutesReadyToSave(anyRoutesSelected);
  }, [selectedNewRoutes]);

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };
  const trapMessage = `Are you sure to update this schedule for the month ?`;

  const handleChangeNewRoutes = (route) => {
    setSelectedNewRoutes((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
  };

  const handleOpenNewRoute = () => {
    setIsSetNewRouteDialogOpen(true);
  };

  const handleClearRoute = () => {
    setSelectedNewRoutes([]);
  };

  const handleDialogClose = () => {
    setIsSetNewRouteDialogOpen(false);
  };

  const handleMainClose = () => {
    setOpen(false);
    setSelectedNewRoutes([]);
  };

  const handleUpdateSchedule = async (event) => {
    event.preventDefault();
    let allData = {
      routes: selectedNewRoutes,
      date: day.format("YYYY-MM-DD"),
      user: account_detailed.user.id,
    };
    console.log(allData);
    const sea = await updateDeliverySchedule(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }
    refetchSched();
    handleMainClose();
  };
  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    route_data = data.results;
  }

  const routesString =
    monthData
      .filter((item) => moment(item.date).isSame(day, "day"))
      .flatMap((item) => item.routes)
      .map((route) => route.name)
      .join(", ") || "No Sched";

  return (
    <>
      <div
        key={dayIndex}
        className={`text-center p-2 border-b border-gray-200 ${
          isDisabled ? "bg-gray-300 cursor-not-allowed" : ""
        } ${isSameSelectedDate ? "bg-green-500 text-white" : ""} ${
          isToday ? "bg-yellow-300" : ""
        }`}
        onClick={isDisabled ? null : () => handleOpen(day)}
        style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
      >
        <div className={`text-sm ${isSameSelectedDate ? "text-white" : ""}`}>
          {isSameSelectedDate ? selectedDate.format("D") : day.format("D")}
        </div>
        <div className="text-xs text-white-500">{routesString}</div>
      </div>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full flex flex-col items-center rounded-sm space-y-2">
          <Typography className="text-green-900" variant="h3">
            Route Schedule
          </Typography>
          <Typography className="text-green-900" variant="h6">
            {day.format("MMMM D, YYYY")}
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto mt-10">
            <div className="col-span-2 space-y-5">
              <Typography className="text-green-900" variant="h6">
                Current Route
              </Typography>

              <div className={inputClasses}>
                <Input label="Routes" value={routesString} readOnly />
              </div>
            </div>
            <div className="col-span-2 space-y-5">
              <Typography className="text-green-900" variant="h6">
                Set New Route
              </Typography>
              <div className={inputClasses}>
                <Input
                  label="New Route"
                  value={
                    selectedNewRoutes.map((route) => route.name).join(", ") ||
                    ""
                  }
                  onClick={handleOpenNewRoute}
                  readOnly
                />
              </div>
              <Dialog
                size="sm"
                open={isNewRouteDialogOpen}
                handler={handleDialogClose}
                className="relative max-h-min"
              >
                <DialogHeader>
                  <Typography className="text-green-900" variant="h3">
                    Select New Routes
                  </Typography>
                </DialogHeader>
                <DialogBody className="overflow-y-auto max-h-[50vh]">
                  <table className="w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        {tableHead.map((head, index) => (
                          <th
                            key={index}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {route_data.map((route) => {
                        const classes = "p-4 border-b border-blue-gray-50";
                        return (
                          <tr key={route.id}>
                            <td className={classes}>
                              <Checkbox
                                type="checkbox"
                                color="teal"
                                checked={selectedNewRoutes.some(
                                  (selectedRoute) =>
                                    selectedRoute.id === route.id
                                )}
                                onChange={() => handleChangeNewRoutes(route)}
                              />
                            </td>
                            <td className={classes}>{route.name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </DialogBody>
              </Dialog>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Confirmation
            onConfirm={handleUpdateSchedule}
            isReadyToSave={areRoutesReadyToSave}
            trapMessage={trapMessage}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default EditRouteModal;
