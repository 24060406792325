import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const approvalApi = createApi({
  reducerPath: "approval",

  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  tagTypes: ["deliveryApproval"],
  endpoints(builder) {
    return {
      fetchApproval: builder.query({
        query: () => {
          return {
            url: "/api/warehouse/request/request_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchApprovalDetailed: builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/request/${id}/request_item_detailed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchApprovalData: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id", account_jp }) => {
          return {
            url: `/api/warehouse/request/approval_list_page/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&jp=${account_jp}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferApprovalDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/request/${id}/transfer_approval_details/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferItemApproval: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id", transfer_id }) => {
          return {
            url: `/api/warehouse/request/transfer_items_approval_list/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&transfer_id=${transfer_id}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateApproveTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/approve_transfer_items/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchDeliveryApproval: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id", search = "" }) => {
          return {
            url: `/api/delivery/delivery/delivery_approval_list_page/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateDeliveryItemsApproval: builder.mutation({
        query: (form) => ({
          url: `/api/delivery/delivery/adjust_delivery_order_item/`,
          headers: { Authorization: "token " + token },
          method: "PUT",
          body: form,
        }),
        invalidatesTags: ["deliveryApproval"],
      }),

      fetchDeliveryApprovalItemsDetails: builder.query({
        query: ({ page = 1, page_size = 10, del_tracking_num, is_fresh }) => ({
          url: `/api/delivery/delivery/get_for_delivery_item_details/?page=${page}&page_size=${page_size}&del_tracking_num=${del_tracking_num}&is_fresh=${is_fresh}`,
          headers: { Authorization: "token " + token },
          method: "GET",
        }),
        providesTags: ["deliveryApproval"],
      }),

      fetchDeliveryApprovalItemsAdjust: builder.query({
        query: ({ page = 1, page_size = 10, order_number }) => ({
          url: `/api/delivery/delivery/delivery_order_item_details/?page=${page}&page_size=${page_size}&order_number=${order_number}`,
          headers: { Authorization: "token " + token },
          method: "GET",
        }),
        providesTags: ["deliveryApproval"],
      }),

      updateApproveDelivery: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_approve_delivery/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateApprovalDelItemZero: builder.mutation({
        query: (form) => ({
          url: `/api/delivery/delivery/adjust_product_soh_zero/`,
          headers: { Authorization: "token " + token },
          method: "PUT",
          body: form,
        }),
        invalidatesTags: ["deliveryApproval"],
      }),
      fetchApprovalRtsListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          types,
        }) => {
          return {
            url: `/api/warehouse/transfer/rts_approval_view_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&types=${types}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchRTSItemApprovalView: builder.query({
        query: ({ page, page_size, transfer_num }) => {
          return {
            url: `/api/warehouse/transfer/rts_approval_item_summary/?page=${page}&page_size=${page_size}&transfer_num=${transfer_num}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchAdditionalDeliveryApproval: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id", search = "" }) => {
          return {
            url: `/api/delivery/delivery/additional_delivery_approval_gm_list_page/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateApproveAddDelApproval: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_approve_additional_delivery/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
    };
  },
});
export const {
  useFetchApprovalQuery,
  useFetchApprovalDetailedQuery,
  useFetchApprovalDataQuery,
  useFetchTransferApprovalDetailsQuery,
  useFetchTransferItemApprovalQuery,
  useUpdateApproveTransferMutation,
  useFetchDeliveryApprovalQuery,
  useUpdateDeliveryItemsApprovalMutation,
  useUpdateApproveDeliveryMutation,
  useUpdateApprovalDelItemZeroMutation,
  useFetchApprovalRtsListPageQuery,
  useFetchRTSItemApprovalViewQuery,
  useFetchAdditionalDeliveryApprovalQuery,
  useUpdateApproveAddDelApprovalMutation,
  useFetchDeliveryApprovalItemsAdjustQuery,
  useFetchDeliveryApprovalItemsDetailsQuery,
} = approvalApi;
export { approvalApi };
