import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Select,
  Option,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useFetchDeliveryItemsDetailsQuery } from "../../../store";
import { useFetchDeliveryOrdersQuery } from "../../../store";
import { useFetchDelIssuedItemsQuery } from "../../../store";
import { ResetQuantityConfirmation } from "../../modal_form_components/Error_trap_components/ResetQtyDelItemConfirmation";
import AdjDelRcptModal from "../../modal_form_components/Delivery/AdjustDelReceipt";
import AdjustDelApprovalModal from "../../modal_form_components/Approval/AdjustDelApprovalModal";
import { ToastContainer } from "react-toastify";
import { useTransferRequestItemApprovalViewQuery } from "../../../store";
import GasSerialNumberModal from "../../modal_form_components/Delivery/ProductSerialNumberList";
import { useUpdateApprovalDelItemZeroMutation } from "../../../store";
import { useFetchRTSItemApprovalViewQuery } from "../../../store";

const TABLE_HEAD = [
  "Code",
  "Description",
  "Order Quantity",
  "SOH",
  "UM",
  "Remarks",
  "Action",
  "",
];

const ADD_DEL_TABLE_HEAD = [
  "Code",
  "Description",
  "Order Quantity",
  "SOH",
  "UM",
  "Remarks",
];

const TABLE_HEAD_REQ = ["Name", "UM", "SOH", "Requested Qty", "Remarks"];

const TABLE_HEAD_DEL_ITEM = [
  "Product Code",
  "Description",
  "Weight/UM",
  "Approved Quantity",
  "Issued Quantity",
  "Remarks",
];
const TABLE_HEAD_DRNUM = ["Order Number", "Outlet", "Route", "FC", "Actions"];
const TABLE_HEAD_DRNUM_NEW = [
  "Order Number",
  "Outlet",
  "Route",
  "FC",
  "Actions",
  "",
];
const classes = "p-4 border-b border-blue-gray-50";
let del_items_result = [];
let add_del_items_result = [];
let del_order_num_result = [];
let del_item_issued_result = [];

function DelIssuedItemTbl({ getDeliverId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchGroupItems,
  } = useFetchDelIssuedItemsQuery({
    page: currentPage,
    page_size: pageSize,
    delivery_num: getDeliverId,
  });
  const [allSufficient, setAllSufficient] = useState(false); // Default to true
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }
  // useEffect(() => {
  //   if (!error && isSuccess && data && data.results && data.results.length > 0) {
  //     const allSufficient = data.results.every(del => del.ordered_qty <= del.stock_on_hand);
  //     if (typeof onSufficientData === 'function') {
  //       onSufficientData(allSufficient);
  //     }
  //   }
  // }, [data, error, isSuccess, onSufficientData]);

  // useEffect(() => {

  //   const fetchData = () => {
  //     refetchGroupItems();
  //   };

  //   const intervalId = setInterval(fetchData, 5000);

  //   return () => clearInterval(intervalId);
  // }, [refetchGroupItems]);

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_item_issued_result = data.results;
    const totalOrderedQty = del_item_issued_result.reduce(
      (sum, del) => sum + (del.required_qty || 0),
      0
    );
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD_DEL_ITEM.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {del_item_issued_result.map((del, index) => {
                const isLast = index === del_item_issued_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product.product_code || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product.weight_box || 0}
                            {"/"}
                            {del.product.unit_measurement || "NA"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.required_qty || "No data"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.issued_qty || "No data"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={
                              del.required_qty > del.issued_qty
                                ? "red"
                                : "green"
                            }
                            className="font-normal"
                          >
                            {del.required_qty > del.issued_qty
                              ? "Insufficient"
                              : "Sufficient"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
        <Typography className="text-center">
          - - - - - Total Number of Items - - - - -{" "}
        </Typography>
        <div className="col-span-1 flex items-center justify-center space-y-5">
          <label>
            <span>TOTAL : {totalOrderedQty}</span>
          </label>
        </div>
      </>
    );
  }
}

function GenDelRecTbl({
  getDeliverId,
  onSufficientData,
  productIdsQtyList,
  refetchTrigger,
  resetRefetch,
  freshGoodsData,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchGroupItems,
  } = useFetchDeliveryItemsDetailsQuery({
    page: currentPage,
    page_size: pageSize,
    del_tracking_num: getDeliverId,
    is_fresh: 1,
  });

  const [allSufficient, setAllSufficient] = useState(false); // Default to true
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    let allSufficient;
    if (!error && isSuccess && data && data.results) {
      if (data?.results?.length === 0 && freshGoodsData?.results?.length > 0) {
        allSufficient = true;
      } else {
        allSufficient =
          data?.results?.every((del) => del.ordered_qty <= del.stock_on_hand) ??
          false;
      }

      if (typeof onSufficientData === "function") {
        onSufficientData(allSufficient);
        productIdsQtyList(data.results);
      }
    }
  }, [data, error, isSuccess, onSufficientData]);

  // useEffect(() => {

  //   const fetchData = () => {
  //     refetchGroupItems();
  //   };

  //   const intervalId = setInterval(fetchData, 5000);

  //   return () => clearInterval(intervalId);
  // }, [refetchGroupItems]);

  useEffect(() => {
    if (refetchTrigger) {
      refetchGroupItems();
      resetRefetch();
    }
  }, [refetchTrigger, refetchGroupItems, resetRefetch]);

  const [updateQtyReset] = useUpdateApprovalDelItemZeroMutation();
  const handleResetQuantity = async (event, prodId, actiontypes) => {
    event.preventDefault();
    // Gather data from each table component

    // Repeat for other table components

    // Combine all data as needed

    let allData = {
      id: getDeliverId,
      prod_id: prodId,
      types: actiontypes,
    };

    const sea = await updateQtyReset(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }

    refetchGroupItems();
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_items_result = data.results;
    const totalOrderedQty = del_items_result.reduce(
      (sum, del) => sum + (del.ordered_qty || 0),
      0
    );
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {del_items_result.map((del, index) => {
                const isLast = index === del_items_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.product_id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product__product_code || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product__name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.ordered_qty || 0}/
                            {del.product__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.stock_on_hand || 0}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product__weight_box || "N/A"}/
                            {del.product__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={
                              del.ordered_qty > del.stock_on_hand
                                ? "red"
                                : "green"
                            }
                            className="font-normal"
                          >
                            {del.ordered_qty > del.stock_on_hand
                              ? "Insufficient"
                              : "Sufficient"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <ResetQuantityConfirmation
                            itemName={del.product__name}
                            // prodId={del.prod_id}
                            // delId={getDeliverId}
                            onConfirm={(event) =>
                              handleResetQuantity(event, del.product_id, 1)
                            }
                            types={1}
                          />
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <ResetQuantityConfirmation
                            itemName={del.product__name}
                            // prodId={del.prod_id}
                            // delId={getDeliverId}
                            onConfirm={(event) =>
                              handleResetQuantity(event, del.product_id, 2)
                            }
                            types={2}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <ToastContainer />
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
        <Typography className="text-center">
          - - - - - Total Number of Items - - - - -{" "}
        </Typography>
        <div className="col-span-1 flex items-center justify-center space-y-5">
          <label>
            <span>TOTAL : {totalOrderedQty}</span>
          </label>
        </div>
      </>
    );
  }
}

function DRNumTbl({ getDeliverId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, error, isLoading, isSuccess, refetch } =
    useFetchDeliveryOrdersQuery({
      page: currentPage,
      page_size: pageSize,
      del_tracking_num: getDeliverId,
    });

  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_order_num_result = data.results;
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD_DRNUM_NEW.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {del_order_num_result.map((del, index) => {
                const isLast = index === del_order_num_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.order_number || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.outlet.outlet || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.outlet.route.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.outlet.fc.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <AdjDelRcptModal
                            getOrderNumber={del.id}
                            outletData={del.outlet}
                            orderNumber={del.order_number}
                          />
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <GasSerialNumberModal
                            getOrderNumber={del.id}
                            outletData={del.outlet}
                            orderNumber={del.order_number}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </>
    );
  }
}

function AdjustProductQtyTable({ getDeliverId, triggerRefetch }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, error, isLoading, isSuccess, refetch } =
    useFetchDeliveryOrdersQuery({
      page: currentPage,
      page_size: pageSize,
      del_tracking_num: getDeliverId,
    });
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_order_num_result = data.results;
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD_DRNUM.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {del_order_num_result.map((del, index) => {
                const isLast = index === del_order_num_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.order_number || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.outlet.outlet || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.outlet.route.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.outlet.fc.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <AdjustDelApprovalModal
                            getOrderNumber={del.id}
                            getOrderId={del.order_number}
                            getOutlet={del.outlet.outlet}
                            refetchFunction={refetch}
                            triggerRefetch={triggerRefetch}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </>
    );
  }
}

function ManualRequestTbl({
  getTransNum,
  onSufficientData,
  productIdsQtyList,
  refetchTrigger,
  resetRefetch,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchGroupItems,
  } = useTransferRequestItemApprovalViewQuery({
    page: currentPage,
    page_size: pageSize,
    transfer_num: getTransNum,
  });

  const [allSufficient, setAllSufficient] = useState(false); // Default to true
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    if (
      !error &&
      isSuccess &&
      data &&
      data.results &&
      data.results.length > 0
    ) {
      const allSufficient = data.results.every(
        (del) => del.ordered_qty <= del.stock_on_hand
      );
      if (typeof onSufficientData === "function") {
        onSufficientData(allSufficient);
        productIdsQtyList(data.results);
      }
    }
  }, [data, error, isSuccess, onSufficientData]);

  // useEffect(() => {

  //   const fetchData = () => {
  //     refetchGroupItems();
  //   };

  //   const intervalId = setInterval(fetchData, 5000);

  //   return () => clearInterval(intervalId);
  // }, [refetchGroupItems]);

  useEffect(() => {
    if (refetchTrigger) {
      refetchGroupItems();
      resetRefetch();
    }
  }, [refetchTrigger, refetchGroupItems, resetRefetch]);

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_items_result = data.results;
    const totalOrderedQty = del_items_result.reduce(
      (sum, del) => sum + (del.ordered_qty || 0),
      0
    );
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    console.log(del_items_result);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD_REQ.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {del_items_result.map((del, index) => {
                const isLast = index === del_items_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.item_id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.item__name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.item__weight_box || "N/A"}/
                            {del.item__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.stock_on_hand || 0}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.ordered_qty || 0}/
                            {del.item__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={
                              del.ordered_qty > del.stock_on_hand
                                ? "red"
                                : "green"
                            }
                            className="font-normal"
                          >
                            {del.ordered_qty > del.stock_on_hand
                              ? "Insufficient"
                              : "Sufficient"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
        <Typography className="text-center">
          - - - - - Total Number of Items - - - - -{" "}
        </Typography>
        <div className="col-span-1 flex items-center justify-center space-y-5">
          <label>
            <span>TOTAL : {totalOrderedQty}</span>
          </label>
        </div>
      </>
    );
  }
}

function RTSTransferItemTbl({
  getTransNum,
  onSufficientData,
  productIdsQtyList,
  refetchTrigger,
  resetRefetch,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchGroupItems,
  } = useFetchRTSItemApprovalViewQuery({
    page: currentPage,
    page_size: pageSize,
    transfer_num: getTransNum,
  });

  const [allSufficient, setAllSufficient] = useState(false); // Default to true
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    if (
      !error &&
      isSuccess &&
      data &&
      data.results &&
      data.results.length > 0
    ) {
      const allSufficient = data.results.every(
        (del) => del.ordered_qty <= del.stock_on_hand
      );
      if (typeof onSufficientData === "function") {
        onSufficientData(allSufficient);
        productIdsQtyList(data.results);
      }
    }
  }, [data, error, isSuccess, onSufficientData]);

  useEffect(() => {
    if (refetchTrigger) {
      refetchGroupItems();
      resetRefetch();
    }
  }, [refetchTrigger, refetchGroupItems, resetRefetch]);

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_items_result = data.results;
    const totalOrderedQty = del_items_result.reduce(
      (sum, del) => sum + (del.ordered_qty || 0),
      0
    );
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    console.log(del_items_result);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {TABLE_HEAD_REQ.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {del_items_result.map((del, index) => {
                const isLast = index === del_items_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.item_id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.item__name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.item__weight_box || "N/A"}/
                            {del.item__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.stock_on_hand || 0}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.ordered_qty || 0}/
                            {del.item__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={
                              del.ordered_qty > del.stock_on_hand
                                ? "red"
                                : "green"
                            }
                            className="font-normal"
                          >
                            {del.ordered_qty > del.stock_on_hand
                              ? "Insufficient"
                              : "Sufficient"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
        <Typography className="text-center">
          - - - - - Total Number of Items - - - - -{" "}
        </Typography>
        <div className="col-span-1 flex items-center justify-center space-y-5">
          <label>
            <span>TOTAL : {totalOrderedQty}</span>
          </label>
        </div>
      </>
    );
  }
}

function AddDelApprovalItemList({
  getDeliverId,
  onSufficientData,
  productIdsQtyList,
  refetchTrigger,
  resetRefetch,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchGroupItems,
  } = useFetchDeliveryItemsDetailsQuery({
    page: currentPage,
    page_size: pageSize,
    del_tracking_num: getDeliverId,
    is_fresh: 1,
  });

  const [allSufficient, setAllSufficient] = useState(false); // Default to true
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }
  useEffect(() => {
    if (
      !error &&
      isSuccess &&
      data &&
      data.results &&
      data.results.length > 0
    ) {
      const allSufficient = data.results.every(
        (del) => del.ordered_qty <= del.stock_on_hand
      );
      if (typeof onSufficientData === "function") {
        onSufficientData(allSufficient);
        productIdsQtyList(data.results);
      }
    }
  }, [data, error, isSuccess, onSufficientData]);

  useEffect(() => {
    if (refetchTrigger) {
      refetchGroupItems();
      resetRefetch();
    }
  }, [refetchTrigger, refetchGroupItems, resetRefetch]);

  const [updateQtyReset] = useUpdateApprovalDelItemZeroMutation();
  const handleResetQuantity = async (event, prodId, actiontypes) => {
    event.preventDefault();
    // Gather data from each table component

    // Repeat for other table components

    // Combine all data as needed

    let allData = {
      id: getDeliverId,
      prod_id: prodId,
      types: actiontypes,
    };

    const sea = await updateQtyReset(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }

    refetchGroupItems();
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    add_del_items_result = data.results;
    const totalOrderedQty = add_del_items_result.reduce(
      (sum, del) => sum + (del.ordered_qty || 0),
      0
    );
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    return (
      <>
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left ">
            <thead>
              <tr>
                {ADD_DEL_TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {add_del_items_result.map((del, index) => {
                const isLast = index === add_del_items_result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.product_id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product__product_code || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product__name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.ordered_qty || 0}/
                            {del.product__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.stock_on_hand || 0}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {del.product__weight_box || "N/A"}/
                            {del.product__unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={
                              del.ordered_qty > del.stock_on_hand
                                ? "red"
                                : "green"
                            }
                            className="font-normal"
                          >
                            {del.ordered_qty > del.stock_on_hand
                              ? "Insufficient"
                              : "Sufficient"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <ResetQuantityConfirmation
                            itemName={del.product__name}
                            // prodId={del.prod_id}
                            // delId={getDeliverId}
                            onConfirm={(event) =>
                              handleResetQuantity(event, del.product_id, 1)
                            }
                            types={1}
                          />
                        </div>
                      </div>
                    </td> */}
                    {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <ResetQuantityConfirmation
                            itemName={del.product__name}
                            // prodId={del.prod_id}
                            // delId={getDeliverId}
                            onConfirm={(event) =>
                              handleResetQuantity(event, del.product_id, 2)
                            }
                            types={2}
                          />
                        </div>
                      </div>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
        <ToastContainer />
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
        <Typography className="text-center">
          - - - - - Total Number of Items - - - - -{" "}
        </Typography>
        <div className="col-span-1 flex items-center justify-center space-y-5">
          <label>
            <span>TOTAL : {totalOrderedQty}</span>
          </label>
        </div>
      </>
    );
  }
}

export {
  GenDelRecTbl,
  DRNumTbl,
  AdjustProductQtyTable,
  DelIssuedItemTbl,
  ManualRequestTbl,
  RTSTransferItemTbl,
  AddDelApprovalItemList,
};
