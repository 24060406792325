
function SelectVendorModal({handleSetModal, suggestedVendor, display='hidden'}) {
    console.log(suggestedVendor)
    return <>
        <div className={`flex flex-col w-full h-full absolute top-0 left-0 justify-center z-50 ${display}`}>
            <div className="flex justify-center ">
                <div className="max-w-3xl border border-gray-300 bg-white shadow-2xl rounded-xl relative px-4">
                    {/* <a onClick={() => display.onClickHandleDisplay()} className="font-bold absolute top-3 right-3 hover:cursor-pointer">X</a> */}
                    <main className="mt-4 px-4">
                        <div className="flex-1 flex">
                            <h1 className="text-gray-800 text-xl font-bold flex-1">Select Vendor</h1>
                            <button onClick={() => handleSetModal('view')} className="text-gray-800 cursor-pointer">Back</button>
                        </div>
                        <div class="w-full px-4 ">
                        <div class="py-6 sm:px-0">
                            <div class="flex flex-col">
                            <div class="-my-2 sm:-mx-6 lg:-mx-8 max-w-3xl overflow-x-auto overflow-y-auto overflow-hidden">
                                <div class="py-2 align-middle inline-block min-w-full ">
                                <div class="shadow border-b border-gray-200 sm:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-200 ">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Vendor
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Point of Contact
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Contact
                                            </th>
                                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Address
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        {suggestedVendor.map((suggested) => {
                                            return <>
                                                <td class="px-6 py-4 whitespace-nowrap" key={`a${suggested?.id}`}>
                                                    <div class="text-sm text-gray-500">{suggested?.vendor?.name}</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap" key={`a${suggested?.id}`}>
                                                    <div class="text-sm text-gray-500">{suggested?.vendor?.poc}</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap" key={`a${suggested?.id}`}>
                                                    <div class="text-sm text-gray-500">{suggested?.vendor?.contact}</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-nowrap" key={`a${suggested?.id}`}>
                                                    <div class="text-sm text-gray-500">{suggested?.vendor?.address}</div>
                                                </td>
                                            </>
                                        })}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </>
}

export default SelectVendorModal