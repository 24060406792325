export const formatDateMDY = (date) => {
  return new Date(date).toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatToNumber = (value) => {
  if (value == null || value === "") {
    return "0.00";
  }

  const number = parseFloat(value);

  if (isNaN(number)) {
    throw new Error(
      "Invalid input: Not a valid number or string representing a number"
    );
  }

  return number.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
