import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const transferApi = createApi({
  reducerPath: "transfer",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchTransfer: builder.query({
        query: () => {
          return {
            url: "/api/warehouse/transfer/transfer_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/transfer/${id}/transfer_details/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
        }) => {
          return {
            url: `/api/warehouse/transfer/transfer_delivery_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferListPagev2: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
        }) => {
          return {
            url: `api/warehouse/transfer/transfer_list_page_v2/?page=${page}&page_size=${page_size}&order_by${order_by}$order_dir=${order_dir}$search=${search} `,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/transfer_update_status/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateDeliveryReturn: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/receive_update_status/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      transferReceivePodSig: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/transfer_prod_receive_prod_sig/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      transferReceived: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/received_products/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      goodsRejectListPage: builder.query({
        query: ({
          page,
          page_size,
          order_by = "id",
          order_dir = "desc",
          type,
          remark,
        }) => {
          return {
            url: `/api/production/goods_reject_list_page/?page=${page}&page_size=${page_size}&order_by${order_by}&order_dir=${order_dir}&type=${type}&remark=${remark}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      transferGoodReject: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/transfer_prod_good_reject/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      transferItemGoodReject: builder.mutation({
        query: (form) => {
          return {
            url: `/api/production/transfer_item_good_reject/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      saveTranferProdSig: builder.mutation({
        query: (request) => {
          return {
            url: `/api/production/save_transfer_prod_sig/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      transferRequestListPage: builder.query({
        query: ({ page, page_size, order_by = "id", order_dir = "desc" }) => {
          return {
            url: `/api/warehouse/transfer/manual_transfer_view_list_page/?page=${page}&page_size=${page_size}&order_by${order_by}&order_dir=${order_dir}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      transferRequestApproval: builder.query({
        query: ({ page, page_size, order_by = "id", order_dir = "desc" }) => {
          return {
            url: `/api/warehouse/transfer/request_approval_list_page/?page=${page}&page_size=${page_size}&order_by${order_by}&order_dir=${order_dir}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      createManualRequest: builder.mutation({
        query: (request) => {
          return {
            url: `/api/warehouse/transfer/create_manual_request/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: request,
          };
        },
      }),
      transferRequestItemListPage: builder.query({
        query: ({
          page,
          page_size,
          order_by = "id",
          order_dir = "desc",
          location,
          search = "",
        }) => {
          return {
            url: `/api/warehouse/transfer/item_manual_request_list_page/?page=${page}&page_size=${page_size}&order_by${order_by}&order_dir=${order_dir}&location=${location}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      transferRequestItemApprovalView: builder.query({
        query: ({ page, page_size, transfer_num }) => {
          return {
            url: `/api/warehouse/transfer/transfer_request_item_summary/?page=${page}&page_size=${page_size}&transfer_num=${transfer_num}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateTransferRequestStatus: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/update_request_approve/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchTransferRequestDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/transfer/${id}/transfer_request_details/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      detailedTranferSchedule: builder.query({
        query: ({ id, client_id }) => {
          return {
            url: `/api/production/${id}/detailed_transfer_schedule/?client_id=${client_id}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchUserManualRequestList: builder.query({
        query: ({ user_type = [] }) => {
          return {
            url: `/api/warehouse/transfer/manual_request_user_list/?user_type=${user_type}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateManualTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/manual_transfer_update_status/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      transferMaterialGoodReject: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/inventory/transfer_material_good_reject/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      transferCompletedRequestListPage: builder.query({
        query: ({ page, page_size, order_by = "id", order_dir = "desc" }) => {
          return {
            url: `/api/warehouse/transfer/manual_transfer_completed_view_list_page/?page=${page}&page_size=${page_size}&order_by${order_by}&order_dir=${order_dir}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      createTransferRequestReturn: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/create_manual_return/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      fetchWithdrawalRtsListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          types,
        }) => {
          return {
            url: `/api/warehouse/transfer/withdrawal_rts_view_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&types=${types}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateReceivedReturnManualTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/manual_request_return_update_status/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateReceivedWithdrawalTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/withdrawal_receive_update_status/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchVendorRtsListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
        }) => {
          return {
            url: `/api/warehouse/transfer/get_rts_vendor_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchVendorItemRTSListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          vendor,
        }) => {
          return {
            url: `/api/warehouse/transfer/get_rts_vendor_item_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&vendor=${vendor}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      createRTSTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/create_rts_transfer/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      updateRTSTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/update_rts_transfer/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateRTSApproval: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/transfer/update_rts_approval/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
    };
  },
});

export const {
  useFetchTransferQuery,
  useFetchTransferDetailsQuery,
  useFetchTransferListPageQuery,
  useFetchTransferListPagev2Query,
  useUpdateTransferMutation,
  useUpdateDeliveryReturnMutation,
  useTransferReceivePodSigMutation,
  useTransferReceivedMutation,
  useGoodsRejectListPageQuery,
  useTransferGoodRejectMutation,
  useSaveTranferProdSigMutation,
  useCreateManualRequestMutation,
  useTransferRequestApprovalQuery,
  useTransferRequestListPageQuery,
  useTransferRequestItemListPageQuery,
  useTransferRequestItemApprovalViewQuery,
  useUpdateTransferRequestStatusMutation,
  useFetchTransferRequestDetailsQuery,
  useDetailedTranferScheduleQuery,
  useTransferItemGoodRejectMutation,
  useFetchUserManualRequestListQuery,
  useUpdateManualTransferMutation,
  useTransferMaterialGoodRejectMutation,
  useTransferCompletedRequestListPageQuery,
  useCreateTransferRequestReturnMutation,
  useFetchWithdrawalRtsListPageQuery,
  useUpdateReceivedReturnManualTransferMutation,
  useUpdateReceivedWithdrawalTransferMutation,
  useFetchVendorItemRTSListPageQuery,
  useFetchVendorRtsListPageQuery,
  useCreateRTSTransferMutation,
  useUpdateRTSTransferMutation,
  useUpdateRTSApprovalMutation,
} = transferApi;
export { transferApi };
