import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
  Checkbox,
  CardFooter,
  Card,
  Radio,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";
import { useFetchDeliveryReturnFormListPageQuery } from "../../../store";
import { useFetchDeliveryItemsReturnFormListPageQuery } from "../../../store";
import { GrPowerReset } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";
import ReactSignatureCanvas from "react-signature-canvas";
import { useCreateManualRequestMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { useCreateTransferRequestReturnMutation } from "../../../store";
import { toast } from "react-toastify";
import { formatDateMDY } from "../../reusable/reuse_function";
import { useCreateReturnDeliveryRequestMutation } from "../../../store";
const ManualReturnDeliveryModal = ({ refetechMaintable }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const trapMessage = "Are you sure to request these items?";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const classes = "p-4 border-b border-blue-gray-50";
  const tableHeadItems = ["", "TRACKING #", "ROUTE", "FC", "DELIVERY DATE"];
  const TABLE_HEAD = [
    "Code",
    "Name",
    "UM",
    "Transferred Qty",
    "Actual Return Qty",
  ];

  let [productActualQty, setProductActualQty] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState("");
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isItemDalogOPen, setIsItemDalogOPen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState([]);
  const [deliveryItems, setDeliveryItems] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [itemCurrentPage, setItemCurrentPage] = useState(1);
  const [itemPageSize, setItemPageSize] = useState(100);
  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
  };

  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };
  const { data, isError, isLoading, isSuccess, refetch } =
    useFetchDeliveryReturnFormListPageQuery({
      page: itemCurrentPage,
      page_size: itemPageSize,
      search: searchTerm,
    });

  const delItems = useFetchDeliveryItemsReturnFormListPageQuery({
    page: 1,
    page_size: 200,
    del_id: selectedDelivery ? selectedDelivery.id : "",
  });
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setItemCurrentPage(1);
  };

  const handleChangeDelivery = (item) => {
    setSelectedDelivery(item);
    handleDialogClose();
    setProductActualQty([]);
    delItems.refetch();
  };

  let handleQtyChange = (index, value) => {
    const MaxQty = 99999;
    value = Math.max(0, Math.min(value, MaxQty));

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      itemId: deliveryItems[index].id,
      itemQty: qty,
    }));
    product_data = product_data.filter(
      (item) =>
        item.itemQty !== undefined && item.itemQty !== 0 && item.itemQty !== ""
    );
    console.log(product_data);
  };

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    const anyArrayHasData =
      deliveryItems.length > 0 && signature !== "" && remarks !== "";
    setIsReadyToSave(anyArrayHasData);

    if (isSuccess) {
      setItemData(data.results);
    }

    if (
      delItems.isSuccess &&
      delItems.data.results.length > 0 &&
      selectedDelivery
    ) {
      setDeliveryItems(delItems.data.results);
    }
  }, [
    selectedItem,
    remarks,
    signature,
    selectedTypes,
    isSuccess,
    data,
    delItems,
    selectedDelivery,
  ]);
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");

  function handleChangeRemarks(event) {
    const newValue = event.target.value;
    setRemarks(newValue);
  }

  const handleIopentemsDialogue = () => {
    // Your logic to handle opening the dialog
    refetch();
    setIsItemDalogOPen(true);
  };
  const handleClearSelectedItem = () => {
    setSelectedItem([]);
    setSelectedItemName([]);
  };

  const handleDialogClose = () => {
    // Your logic to handle closing the dialog
    setIsItemDalogOPen(false);
  };
  const [addDeliveryReturn] = useCreateReturnDeliveryRequestMutation();
  const handleSave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components
    let item_data = productActualQty
      .map((qty, index) => ({
        itemId: deliveryItems[index].id,
        itemQty: qty,
      }))
      .filter((item) => item.itemQty > 0);
    // Combine all data as needed
    let allData = {
      account: account_detailed,
      deliveryId: selectedDelivery.id,
      signature: signature,
      requester: account_detailed.user.id,
      remarks: remarks,
      returnProds: [],
    };
    allData.returnProds = allData.returnProds.concat(item_data);

    let del = await addDeliveryReturn(allData).unwrap();

    console.log(allData);
    try {
      if (del.success === true) {
        toast.success(del.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      } else {
        toast.error(del.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetechMaintable();
    setProductActualQty([]);
    handleClose();
    setSelectedItem([]);
    setSelectedItemName([]);
  };

  console.log(selectedItem);

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Return Delivery
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Return Delivery Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Select Delivery"
                    value={
                      selectedDelivery !== null
                        ? selectedDelivery.delivery_number +
                          " ~ " +
                          selectedDelivery.route.name +
                          " ~ " +
                          selectedDelivery.fc.name
                        : ""
                    }
                    onClick={handleIopentemsDialogue}
                    readOnly
                  />
                </div>
                <div>
                  <textarea
                    id="remarks"
                    name="Remarks"
                    value={remarks}
                    onChange={handleChangeRemarks}
                    rows="4"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter your return remarks"
                  />
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Requester"
                    name="requester"
                    value={`${account_detailed.user.first_name} ${account_detailed.user.last_name}`}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <>
              {/* <Typography className="text-green-900 mt-5" variant="h3">
                Select Delivery:
              </Typography> */}

              <div className={inputClasses}>
                {/* {selectedItem.length > 0 && (
                  <Button
                    onClick={handleClearSelectedItem}
                    style={{
                      marginLeft: "8px",
                      backgroundColor: "green",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <FaSyncAlt style={{ color: "white" }} />
                  </Button>
                )} */}
                <Dialog
                  size="xl"
                  open={isItemDalogOPen}
                  handler={handleDialogClose}
                  className="relative max-h-min"
                >
                  <DialogHeader>
                    <Typography className="text-green-900" variant="h3">
                      Select Delivery
                    </Typography>
                  </DialogHeader>
                  <DialogBody className="overflow-y-auto max-h-[80vh]">
                    <Input
                      label="Search"
                      icon={<FiSearch className="h-5 w-5" />}
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <table className="w-full min-w-max table-auto text-left">
                      <thead>
                        <tr>
                          {tableHeadItems.map((head, index) => (
                            <th
                              key={index}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {itemData.length > 0 ? (
                          <>
                            {itemData.map((item) => {
                              const classes =
                                "p-4 border-b border-blue-gray-50";
                              return (
                                <tr
                                  key={item.id}
                                  className="cursor-pointer"
                                  onClick={() => handleChangeDelivery(item)}
                                >
                                  <td className={classes}>
                                    <input
                                      type="radio"
                                      name="deliverySelection"
                                      className="text-teal-500"
                                      checked={selectedItem?.id === item.id}
                                      onChange={() =>
                                        handleChangeDelivery(item)
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  </td>
                                  <td className={classes}>
                                    {item.delivery_number}
                                  </td>
                                  <td className={classes}>{item.route.name}</td>
                                  <td className={classes}>{item.fc.name}</td>
                                  <td className={classes}>
                                    {formatDateMDY(item.delivery_date)}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan={7}
                              className="p-4 border-b border-blue-gray-50 text-center"
                            >
                              <Typography
                                color="blue-gray"
                                className="font-normal"
                              >
                                No Data Available
                              </Typography>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </DialogBody>
                  <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      Page{" "}
                      {`${currentPage} of ${
                        Math.ceil(data?.count / pageSize) || 1
                      }`}
                    </Typography>
                    <div className="flex gap-2">
                      <Button
                        variant="outlined"
                        size="sm"
                        onClick={handlePreviousPage}
                        disabled={!data?.previous}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        onClick={handleNextPage}
                        disabled={!data?.next}
                      >
                        {data?.next ? "Next" : "No More Pages"}
                      </Button>
                    </div>
                  </CardFooter>
                  {/* <DialogFooter>
                           <Button
                             variant="text"
                             color="red"
                             onClick={handleDialogClose}
                             className="mr-1"
                           >
                             <span>Cancel</span>
                           </Button>
                           <Button
                             variant="gradient"
                             color="green"
                             onClick={handleDialogClose}
                           >
                             <span>Confirm</span>
                           </Button>
                         </DialogFooter> */}
                </Dialog>
              </div>
            </>

            <div className="m-5">
              <Typography className="text-green-900 mt-2" variant="h3">
                {selectedDelivery !== null && deliveryItems.length > 0 && (
                  <Card className="h-full w-full overflow-scroll">
                    <table className="w-full min-w-max table-auto text-left ">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {deliveryItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product
                                        ? item.product.product_code
                                        : ""}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product ? item.product.name : ""}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.product
                                        ? item.product.weight_box
                                        : ""}
                                      /
                                      {item.product
                                        ? item.product.unit_measurement
                                        : ""}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {item.issued_qty ? item.issued_qty : ""}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item.issued_qty > 0 && (
                                  <div className="flex items-center">
                                    <div className="flex flex-col flex-grow">
                                      <input
                                        type="number"
                                        max={item.issued_qty} // Set max value to issued_qty
                                        value={productActualQty[index] || ""}
                                        onChange={(e) => {
                                          const value = Math.min(
                                            Number(e.target.value),
                                            item.issued_qty
                                          ); // Prevent exceeding issued_qty
                                          handleQtyChange(index, value);
                                        }}
                                        className="w-20 border border-1 border-blue-gray-300 p-1"
                                      />
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                )}
              </Typography>
            </div>
          </form>
          {deliveryItems.length > 0 && (
            <>
              <div className="col-span-1 flex items-center justify-center space-y-5">
                <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                      width: 200,
                      height: 100,
                      className: "border-black border-2 rounded-s-xl",
                    }}
                    onEnd={handleSignatureChange}
                  />
                  <div className={boxResetClasses}>
                    <GrPowerReset
                      size={30}
                      className="cursor-pointer"
                      onClick={handleSignatureReset}
                      color="black"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 flex items-center justify-center space-y-5">
                <Typography className="text-justify text-black" variant="h6">
                  {account_detailed.user.first_name
                    ? account_detailed.user.last_name
                      ? `${account_detailed.user.first_name} ${account_detailed.user.last_name}`
                      : "User"
                    : ""}
                </Typography>
              </div>
            </>
          )}

          {signature == "" && (
            <Typography variant="lead" color="red">
              Please provide a signature.
            </Typography>
          )}
          {/* {selectedTypes == "" && (
            <Typography variant="lead" color="red">
              Please provide a transaction.
            </Typography>
          )} */}
        </DialogBody>

        <DialogFooter>
          <Confirmation
            onConfirm={handleSave}
            trapMessage={trapMessage}
            isReadyToSave={isReadyToSave}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ManualReturnDeliveryModal;
