import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useFetchDeliveryDetailsQuery } from "../../../store";
import { Button } from "@material-tailwind/react";
import { FaFilePdf } from "react-icons/fa";

const DelSummaryPdf = ({ getDelId }) => {
  const { data, isError, isLoading, isSuccess } =
    useFetchDeliveryDetailsQuery(getDelId);
  const [isPDFLoading, setIsPDFLoading] = useState(false);

  const generatePDF = () => {
    setIsPDFLoading(true);
    console.log(data);
    if (isSuccess && data) {
      const result = data.data;
      const currentDate = new Date();
      const printDate = currentDate.toLocaleDateString("en-US", {
        timeZone: "Asia/Tokyo",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      //  let signatureCageCustodian = result.delivery.cage_custodian_signature;
      // let signatureWarehouse = result.delivery.custodian_signature;
      // let signatureDriver = result.delivery.driver_signature;
      // let signatureLpg = result.delivery.lpg_issuer_signature;
      // let signaturePlantManager = result.delivery.transfer.signature_plant_manager;

      const pdfWidth = 215.9; // Width in millimeters (A4 size)
      const pdfHeight = 279.4; // Height in millimeters (custom length)

      // Create a new jsPDF instance with custom page size
      const pdfDoc = new jsPDF({
        orientation: "portrait", // Landscape orientation
        unit: "mm", // units of measurement
        format: [pdfWidth, pdfHeight], // specify the page size
      });
      const total_items =
        result?.delivery_items?.reduce(
          (sum, item) => sum + item.ordered_qty,
          0
        ) || 0;
      const deliveryDate = new Date(result.delivery.delivery_date);
      const dateNow = deliveryDate.toLocaleDateString("en-US", {
        timeZone: "Asia/Tokyo",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      // Add logo and title
      pdfDoc.setFontSize(20);
      pdfDoc.setTextColor(0, 128, 0);
      pdfDoc.setFont("helvetica", "bold");
      pdfDoc.text("PREMIUM BURGER & FOOD PRODUCT INC.", 30, 25);
      pdfDoc.setFontSize(10);
      pdfDoc.setTextColor(0);
      pdfDoc.setFont("helvetica", "normal");
      pdfDoc.text(
        `1185 TOPAZ ST. RAMAR VILLAGE SAN AGUSTIN CITY OF SAN FERNANDO PAMPANGA`,
        29,
        30
      );
      pdfDoc.setFont("helvetica", "bold");
      pdfDoc.text(`DELIVERY SUMMARY RECEIPT`, 75, 35);
      pdfDoc.setFont("helvetica", "normal");

      // Add delivery information
      pdfDoc.text(`Delivery Date: ${dateNow}`, 20, 45);
      pdfDoc.text(
        `Delivery Number: ${result.delivery.delivery_number}`,
        20,
        50
      );
      pdfDoc.text(
        `Transfer Number: ${result.delivery.transfer.transfer_number}`,
        20,
        55
      );
      pdfDoc.text(`Cage Number: ${result.delivery.cage_number}`, 120, 45);
      pdfDoc.text(`Route: ${result.delivery.route.name}`, 120, 50);
      pdfDoc.text(`Total Items: ${total_items}`, 120, 55);

      // Split delivery items into chunks of 30 rows each
      const numRowsPerTable = 30;
      const chunks = [];
      const filteredItems = result.delivery_items.filter(
        (item) => item.ordered_qty !== 0
      );

      for (let i = 0; i < filteredItems.length; i += numRowsPerTable) {
        chunks.push(filteredItems.slice(i, i + numRowsPerTable));
      }

      // Draw two tables side by side
      drawTables(pdfDoc, chunks);

      // Save the PDF
      pdfDoc.save(
        `BM_${result.delivery.delivery_number}_Cage_Summary_Receipt__${printDate}.pdf`
      );
      setIsPDFLoading(false);
    }
  };

  const drawTables = (
    pdfDoc,
    chunks,
    signaturePlantManager,
    signatureWarehouse
  ) => {
    const startX1 = 10; // X-coordinate for the first table
    const startX2 = 113; // X-coordinate for the second table
    const startY = 77; // Y-coordinate for both tables
    const tableWidth = 70; // Width of each table
    const cellPadding = 1; // Padding for each cell
    const lineHeight = 5; // Height of each line of text
    const fontSize = 8; // Font size for text cells
    const headerSize = 6; // Header size

    chunks.forEach((chunk, index) => {
      const startX = index % 2 === 0 ? startX1 : startX2;
      const startYAdjusted =
        startY + Math.floor(index / 2) * (lineHeight * 30 + cellPadding * 2);

      pdfDoc.setFontSize(fontSize);
      pdfDoc.setFont("helvetica", "bold");
      pdfDoc.text("Code", startX + cellPadding, startYAdjusted);
      pdfDoc.text(
        "Name",
        startX + tableWidth / 3 + cellPadding,
        startYAdjusted
      );
      pdfDoc.text(
        "Total Issued Qty",
        startX + (2 * tableWidth) / 3 + cellPadding,
        startYAdjusted
      );
      pdfDoc.text(
        "Remarks",
        startX + (3 * tableWidth) / 3 + cellPadding,
        startYAdjusted
      );

      // Draw cell rectangles
      chunk.forEach((item, rowIndex) => {
        const yPos = startYAdjusted + (rowIndex + 1) * lineHeight;

        // Calculate text height
        const textHeight = pdfDoc.getTextDimensions(item.product__nickname).h;

        // Draw rectangles around each cell
        pdfDoc.rect(
          startX,
          yPos - textHeight,
          tableWidth / 3,
          textHeight + cellPadding * 2,
          "S"
        ); // Code cell
        pdfDoc.rect(
          startX + tableWidth / 3,
          yPos - textHeight,
          tableWidth / 3,
          textHeight + cellPadding * 2,
          "S"
        ); // Name cell
        pdfDoc.rect(
          startX + (2 * tableWidth) / 3,
          yPos - textHeight,
          tableWidth / 3,
          textHeight + cellPadding * 2,
          "S"
        ); // Total Issued Qty cell
        pdfDoc.rect(
          startX + (3 * tableWidth) / 3,
          yPos - textHeight,
          tableWidth / 3,
          textHeight + cellPadding * 2,
          "S"
        ); // Remarks cell

        // Fill data into cells
        pdfDoc.setFontSize(fontSize);
        pdfDoc.setFont("helvetica", "normal");
        pdfDoc.text(item.product__product_code, startX + cellPadding, yPos);
        pdfDoc.text(
          item.product__nickname,
          startX + tableWidth / 3 + cellPadding,
          yPos
        );
        pdfDoc.text(
          `${item.ordered_qty} ${item.product__unit_measurement}`,
          startX + (2 * tableWidth) / 3 + cellPadding,
          yPos
        );
        pdfDoc.text("", startX + (3 * tableWidth) / 3 + cellPadding, yPos);
      });
    });

    // Calculate the total height of all chunks
    const totalHeight = chunks.length * (lineHeight * 30 + cellPadding * 2);

    // Add signature lines and images below the tables
    const signatureY = startY + totalHeight + 10; // Adjust Y-coordinate for signatures
    const signatureSpacing = 40; // Spacing between signatures

    // pdfDoc.setFont("helvetica", "bold");
    // pdfDoc.text("Plant Manager", startX1 + tableWidth, signatureY);
    // pdfDoc.text("Warehouse Custodian", startX2 + tableWidth, signatureY);

    // pdfDoc.setFont("helvetica", "normal");
    // pdfDoc.text("__________________", startX1 + tableWidth, signatureY + 5);
    // pdfDoc.text("__________________", startX2 + tableWidth, signatureY + 5);

    // pdfDoc.addImage(signaturePlantManager, 'PNG', startX1 + tableWidth, signatureY - 15, 30, 15);
    // pdfDoc.addImage(signatureWarehouse, 'PNG', startX2 + tableWidth, signatureY - 15, 30, 15);
  };

  return (
    <div>
      <Button
        className="bg-orange-900 mt-1 py-3 px-3 flex items-center space-x-2"
        onClick={generatePDF}
        disabled={isLoading || isError || isPDFLoading}
      >
        {/* Icon */}
        {isLoading || isError || isPDFLoading ? (
          "Loading..."
        ) : (
          <>
            <FaFilePdf className="mr-2" />
          </>
        )}
      </Button>
    </div>
  );
};

export default DelSummaryPdf;
