import { usePoDetailQuery } from "../../../store";
import { format } from "date-fns";
import SelectVendorModal from "./SelectVendorModal";
import { useEffect, useState } from "react";
import PrintRR from "../../modal_form_components/Warehouse/WarehouseDeliveryRawMats/PrintRR";



function POModal(display, onClickHandleDisplay, poStatusID, drapdownStatus, vendor){
    const poDetail = usePoDetailQuery({id : display.poStatusID, status : display.drapdownStatus})
    const [modal, setModal] = useState('view')
    const [suggestedVendor, setSuggestedVendor] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)

    const HandleSuggestedVendor = (suggested) => {
        setModal('DisplaySuggestedVendorModal')
        setSuggestedVendor(suggested)
    }

    console.log(poDetail.data)
    console.log(display.vendor)

    useEffect(() => {
        let total = 0

        poDetail.data?.SuggestedVendor.map((item) => {
            total += (item.des_actual_qty * item.quoted_price)
        })
        setTotalPrice(total)
    }, [poDetail.data?.SuggestedVendor]);

    return <>
        {modal == 'view' ? 
            <>
                <div className={`flex flex-col w-full h-screen absolute top-0 left-0 justify-center z-50 ${display.display}`}>
                    <div className="flex justify-center">
                        <div className="max-w-3xl border border-gray-300 bg-white shadow-2xl rounded-xl relative px-4 pt-10 pb-4">

                            <a onClick={() => display.onClickHandleDisplay()} className="font-bold absolute top-3 right-3 hover:cursor-pointer">X</a>
                            <div className="flex">
                                <div className="flex-1 mr-8">
                                    <h1 className="text-gray-800 text-xl font-bold flex-1">Purchase Order #{poDetail.data?.po_number}</h1>
                                    <p className="text-gray-800">{poDetail.data?.date}</p>
                                </div>

                                <div className="my-auto h-full">
                                    <span className="px-3 py-1 font-semibold rounded-full flex flex-col justify-center bg-green-100 text-green-800">
                                        {poDetail.data?.status == 1 ? 'Pending' : `${poDetail.data?.status == 6 && display.drapdownStatus == 6 ? "Raw Material" : "Completed"}`}
                                    </span>
                                </div>
                            </div>

                            {display.drapdownStatus == 4 ?
                                <>
                                    <div className="w-full grid grid-cols-2 mt-6">
                                        <div className="w-full">
                                            <h3 className="text-gray-500 font-semibold">VENDOR</h3>
                                            <h4 className="text-gray-700 mt-0.5 text-sm">{display.vendor?.name}</h4>
                                            <h4 className="text-gray-700 text-sm">0{display.vendor?.contact}</h4>
                                            <h4 className="text-gray-700 text-sm">{display.vendor?.address}</h4>
                                        </div>

                                        <div>
                                            <h3 className="text-gray-500 font-semibold">Delivery Date</h3>
                                            <h4 className="text-gray-700 text-sm">{poDetail.data?.delivery_date}</h4>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                </>
                            }
                            
                            {/* <div className="w-full grid grid-cols-2 mt-6">
                                <div className="w-full">
                                    <h3 className="text-gray-500 font-semibold">VENDOR</h3>
                                    <h4 className="text-gray-800 mt-0.5">Acer Inc</h4>
                                    <h4 className="text-gray-800">1172–1180 Pres</h4>
                                    <h4 className="text-gray-800">Quirino Ave. Ext., Brgy. 827 Zone 89, Paco, Manila</h4>
                                    <h4 className="text-gray-800">acerstore.ph@acer.com</h4>
                                </div>

                                <div className="w-full">
                                    <h3 className="text-gray-500 font-semibold">SEND TO</h3>
                                    <h4 className="text-gray-800 mt-0.5">Burger Machine</h4>
                                    <h4 className="text-gray-800">1172–1180 Pres</h4>
                                    <h4 className="text-gray-800">Quirino Ave. Ext., Brgy. 827 Zone 89, Paco, Manila</h4>
                                    <h4 className="text-gray-800">burgermachine@burgermachine.com</h4>
                                </div>
                            </div> */}

                            <main className="mt-4 max-w-3xl overflow-x-auto overflow-y-auto overflow-hidden">
                                <div className="w-full">
                                <div className="pt-6 pb-3 sm:px-0 max-h-72 h-auto">
                                    <div className="flex flex-col ">
                                    <div className="-my-2  sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow  border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200 ">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        ITEM
                                                    </th>

                                                    {display.drapdownStatus == 4 ?
                                                    <><th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Unit Measure
                                                    </th></>

                                                    :

                                                    <></>
                                                    }

                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        {display.drapdownStatus != 4 ? 'Desired Quantity' : 'Actual Quantity'}
                                                    </th>
                                                    {poDetail.data?.status == 1 || poDetail.data?.status == 6 ?
                                                    // if status is Pending and raw material
                                                        <>
                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                Unit Measurement
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                Weight Box
                                                            </th>      
                                                        </>
                                                    :
                                                        <></>
                                                    }
                                                    {display.drapdownStatus == 6 && poDetail.data?.status == 6 ?
                                                    // if the status is raw material and completed
                                                    <>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Vendor
                                                        </th>
                                                    </> 
                                                    :
                                                    // if not 
                                                    <>
                                                    </>}

                                                    {display.drapdownStatus == 4 ? 
                                                        <>
                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                Price
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                Amount
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                Value Amount if VAT
                                                            </th>
                                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                W/TAX PURC (less 1%)                      
                                                            </th>
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {display.drapdownStatus == 6 || display.drapdownStatus == 1 ? 
                                                // if status is raw mat and pending
                                                poDetail.data?.request_item.map( (detail) => {
                                                    return (
                                                        <tr key={detail.id}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm font-medium text-gray-900">{detail.item?.name}</div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-600">{parseFloat(detail.des_required_qty).toLocaleString()}</div>
                                                            </td>

                                                            {poDetail.data?.status == 1 || poDetail.data?.status == 6  ?
                                                            // if status is Pending
                                                                <>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{detail.item?.unit_measurement}</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{parseFloat(detail.item?.weight_box).toLocaleString()}</div>
                                                                    </td>
                                                                </>
                                                            :
                                                                <></>
                                                            }

                                                            {poDetail.data?.status == 6 && display.drapdownStatus == 6 ?
                                                                // if the status is raw material show vendor
                                                                <>
                                                                    <td scope="col" className="px-6 py-4 whitespace-nowrap">
                                                                        <button onClick={() => HandleSuggestedVendor(detail?.SuggestedVendor)} className="text-sm text-blue-500">Suggested Vendor</button>
                                                                    </td>
                                                                </>
                                                                :
                                                                // if not 
                                                                <>
                                                                </>
                                                            }

                                                        </tr>
                                                    )
                                                }) 
                                                
                                                :

                                                <>
                                                        {poDetail.data?.SuggestedVendor.map((requestItem, index) => {
                                                            const item = requestItem.request_item.item

                                                            return <>
                                                                <tr key={requestItem.id}>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{item.name}</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{item.unit_measurement}</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{parseFloat(requestItem.des_actual_qty).toLocaleString()}</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{requestItem.quoted_price.toLocaleString()} Php</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{(parseFloat(requestItem.des_actual_qty) * parseFloat(requestItem.quoted_price)).toLocaleString()} Php</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{parseFloat(requestItem.vat_amount).toLocaleString()}</div>
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                                        <div className="text-sm text-gray-600">{parseFloat(requestItem.w_tax_purc).toLocaleString()}</div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        })}
                                                </>
                                                }
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </main>
                            {display.drapdownStatus == 4 ?
                                <div className="w-full flex mt-4">
                                    <div className="text-gray-700 flex-1 h-full my-auto">Total: {(totalPrice).toLocaleString()} Php</div>
                                    <div className="">
                                        <PrintRR vrr_id={poDetail.data?.id}></PrintRR>
                                    </div>
                                </div>
                            :
                                <></>
                            }
                        </div>
                    </div>
                </div>            
            </>

            :

            <>
                <SelectVendorModal display={display} handleSetModal={setModal} suggestedVendor={suggestedVendor}></SelectVendorModal>
            </>
        }
    </>
}

export default POModal;