import React, { useState, useEffect } from "react";
import DashboardStatsGrid from "../dashboard_components/DashboardStatsGrid";
import DelGrapLine from "../dashboard_components/Delivery/DeliveryLineGraph";
import { useNavigate } from "react-router-dom";
import InventoryBarChart from "../dashboard_components/Inventory/InventryBarChart";
import POPageDash from "../dashboard_components/Purchase/POPageDash";
import ProdPageDash from "../dashboard_components/Production/ProdPageDash";
import InventoryDashboardTbl from "../dashboard_components/Inventory/InventoryTable";

export default function DashboardV2() {
  const [currentModule, setCurrentModule] = useState("PurchaseOrder");

  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position == 15 ||
      account_detailed.job_position == 16
    ) {
      navigate("/base/delivery");
    } else if (
      account_detailed.job_position == 5 ||
      account_detailed.job_position == 6 ||
      account_detailed.job_position == 7 ||
      account_detailed.job_position == 8
    ) {
      navigate("/base/warehouse_summary");
    } else if (
      account_detailed.job_position == 10 ||
      account_detailed.job_position == 11 ||
      account_detailed.job_position == 12 ||
      account_detailed.job_position == 9
    ) {
      navigate("/base/production");
    }
  }, [token]);

  const handleChangeModule = (data) => {
    setCurrentModule(data);
  };

  return (
    // <div className="flex flex-col md:flex-col gap-4 h-full mb-2">
    <div className="flex md:flex-col flex-grow gap-4 px-2">
      <DashboardStatsGrid moduleView={handleChangeModule} />
      {currentModule === "PurchaseOrder" && (
        <>
          <POPageDash />
        </>
      )}
      {currentModule === "Inventory" && (
        <>
          <div className="mb-20 lg:mb-5 mt-3 md:mt-0">
            <div className="flex flex-row gap-4 w-full">
              <InventoryDashboardTbl />
            </div>
          </div>
        </>
      )}
      {currentModule === "Production" && (
        <>
          <ProdPageDash />
        </>
      )}
      {currentModule === "Delivery" && (
        <>
          <div className="mb-20 lg:mb-5 mt-3 md:mt-0">
            <div className="flex flex-col md:flex-row gap-4 w-full">
              <DelGrapLine />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
