import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "@material-tailwind/react";
import { formatDateMDY } from "../../reusable/reuse_function";

const AuditSummaryPdf = ({ startDate, endDate, name, auditData }) => {
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  console.log(startDate + "   " + endDate);
  // Generate date range between startDate and endDate
  const getDateRange = (start, end) => {
    const dates = [];
    let currentDate = new Date(start);
    const lastDate = new Date(end);

    while (currentDate <= lastDate) {
      const dayNumber = currentDate.getDate(); // 17, 18, etc.
      const dayName = currentDate.toLocaleDateString("en-US", {
        weekday: "short",
      }); // TUE, WED, etc.
      dates.push({
        dayNumber,
        dayName,
        fullDate: new Date(currentDate).toISOString().split("T")[0],
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const generatePDF = () => {
    setIsPDFLoading(true);
    const pdf = new jsPDF();
    const dateRange = getDateRange(startDate, endDate);

    // Header Section
    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");
    pdf.text("ULTIMATE BURGER & FOOD PRODUCTS INC.", 14, 15);
    pdf.setFontSize(9);
    pdf.text(`OUTLET AUDIT REPORT`, 14, 23);
    pdf.setFont("helvetica", "normal");
    pdf.text(
      `Audit Period: ${formatDateMDY(startDate)} to ${formatDateMDY(endDate)}`,
      14,
      30
    );
    pdf.text(`Auditor: ${name}`, 14, 38);

    const firstHeaderRow = [
      { content: "OUTLETS", styles: { halign: "center", valign: "middle" } },
      ...dateRange.map((date) => ({
        content: date.dayNumber,
        styles: { halign: "center", valign: "middle" },
      })),
    ];
    const secondHeaderRow = [
      "",
      ...dateRange.map((date) => ({
        content: date.dayName,
        styles: { halign: "center", valign: "middle", cellWidth: 10 },
      })),
    ];

    // Table Body
    const tableRows = auditData.map((audit) => {
      const row = [audit.outlet.fc.name + "-" + audit.outlet.outlet || "-"]; // Start with outlet name
      dateRange.forEach((date) => {
        if (audit.created_at && audit.created_at.startsWith(date.fullDate)) {
          const createdTime = new Date(audit.created_at).toLocaleTimeString(
            "en-US",
            {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true, // Ensures 12-hour format with AM/PM
            }
          );

          row.push({
            content: createdTime, // Example: "10:45 PM"
            styles: {
              fillColor: [0, 255, 0],
              halign: "center",
              valign: "middle",
            },
          });
        } else {
          row.push(""); // Empty cell
        }
      });
      return row;
    });

    // Generate AutoTable
    pdf.autoTable({
      startY: 45,
      head: [firstHeaderRow, secondHeaderRow],
      body: tableRows,
      theme: "grid",
      headStyles: { fillColor: false, textColor: [0, 0, 0] },
      styles: { fontSize: 8, cellPadding: 1 },
      columnStyles: { 0: { cellWidth: 20 } },
    });

    // Save PDF
    const currentDate = new Date().toISOString().split("T")[0];
    pdf.save(
      `${name}_Audit_Report_${startDate}_to_${endDate}_${currentDate}.pdf`
    );
    setIsPDFLoading(false);
  };

  return (
    <Button
      className="bg-orange-900 ml-5"
      onClick={generatePDF}
      disabled={isPDFLoading}
    >
      {isPDFLoading ? "Generating..." : "Extract PDF"}
    </Button>
  );
};

export default AuditSummaryPdf;
