import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const InvenFlowFGPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("INVENTORY FLOW OF FINISHED GOODS", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`BREAD TO FOODS DUTY`, 5, 30);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`JB`, xFirstInput, 30);
  pdfDoc.text(`RB`, xSecondInput, 30);
  pdfDoc.text(`MB`, xThirdInput, 30);
  pdfDoc.text(`TOTAL`, xFourthInput, 30);

  pdfDoc.text(`Actual Yield, Net of Rejects (in pieces)`, 5, 35);
  pdfDoc.text(`269,904`, xFirstInput, 35);
  pdfDoc.text(`495,036`, xSecondInput, 35);
  pdfDoc.text(`507,468`, xThirdInput, 35);
  pdfDoc.text(`1,272,408`, xFourthInput, 35);

  pdfDoc.text(`Bread available for issuance (per piece)`, 5, 40);
  pdfDoc.text(`269,904`, xFirstInput, 40);
  pdfDoc.text(`495,036`, xSecondInput, 40);
  pdfDoc.text(`507,468`, xThirdInput, 40);
  pdfDoc.text(`1,272,408`, xFourthInput, 40);

  pdfDoc.text(`Issuances to Foods Duty (per piece)`, 5, 45);
  pdfDoc.text(`495,036`, xFirstInput, 45);
  pdfDoc.text(`269,904`, xSecondInput, 45);
  pdfDoc.text(`507,468`, xThirdInput, 45);
  pdfDoc.text(`1,272,408`, xFourthInput, 45);

  pdfDoc.text(`Ending inventory (per piece)`, 5, 50);
  pdfDoc.text(`0`, xFirstInput, 50);
  pdfDoc.text(`0`, xSecondInput, 50);
  pdfDoc.text(`0`, xThirdInput, 50);
  pdfDoc.text(`0`, xFourthInput, 50);

  pdfDoc.text(`Actual inventory (per piece)`, 5, 55);
  pdfDoc.text(`-`, xFirstInput, 55);
  pdfDoc.text(`-`, xSecondInput, 55);
  pdfDoc.text(`-`, xThirdInput, 55);
  pdfDoc.text(`-`, xFourthInput, 55);

  pdfDoc.text(`TO BE ACCOUNTED (short)/over`, 5, 60);
  pdfDoc.text(`0`, xFirstInput, 60);
  pdfDoc.text(`0`, xSecondInput, 60);
  pdfDoc.text(`0`, xThirdInput, 60);
  pdfDoc.text(`0`, xFourthInput, 60);

  // Foods Duty to Deliveries Section
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`FOODS DUTY TO DELIVERIES`, 5, 65);

  pdfDoc.setTextColor(0);

  // Foods Duty Data Rows
  pdfDoc.text(`Beginning inventory (per piece)`, 5, 70);
  pdfDoc.text(`4,308`, xFirstInput, 70);
  pdfDoc.text(`3,036`, xSecondInput, 70);
  pdfDoc.text(`4,902`, xThirdInput, 70);
  pdfDoc.text(`12,246`, xFourthInput, 70);

  pdfDoc.text(`Add: Transfer from Breadline`, 5, 75);
  pdfDoc.text(`495,036`, xFirstInput, 75);
  pdfDoc.text(`269,904`, xSecondInput, 75);
  pdfDoc.text(`507,468`, xThirdInput, 75);
  pdfDoc.text(`1,272,408`, xFourthInput, 75);

  pdfDoc.text(`Add: Delivery Excess Return`, 5, 80);
  pdfDoc.text(`846`, xFirstInput, 80);
  pdfDoc.text(`396`, xSecondInput, 80);
  pdfDoc.text(`840`, xThirdInput, 80);
  pdfDoc.text(`2,082`, xFourthInput, 80);

  pdfDoc.text(`Bread available for issuance (per piece)`, 5, 85);
  pdfDoc.text(`500,190`, xFirstInput, 85);
  pdfDoc.text(`273,336`, xSecondInput, 85);
  pdfDoc.text(`513,210`, xThirdInput, 85);
  pdfDoc.text(`1,286,736`, xFourthInput, 85);

  pdfDoc.text(`Less: Issuance to Transport (per piece)`, 5, 90);
  pdfDoc.text(`496,320`, xFirstInput, 90);
  pdfDoc.text(`270,606`, xSecondInput, 90);
  pdfDoc.text(`508,752`, xThirdInput, 90);
  pdfDoc.text(`1,275,678`, xFourthInput, 90);

  pdfDoc.text(`Less: Issuance to Rejects (per piece)`, 5, 95);
  pdfDoc.text(`276`, xFirstInput, 95);
  pdfDoc.text(`132`, xSecondInput, 95);
  pdfDoc.text(`204`, xThirdInput, 95);
  pdfDoc.text(`612`, xFourthInput, 95);

  pdfDoc.text(`Less: Issuance of Additional`, 5, 100);
  pdfDoc.text(`360`, xFirstInput, 100);
  pdfDoc.text(`414`, xSecondInput, 100);
  pdfDoc.text(`444`, xThirdInput, 100);
  pdfDoc.text(`1,218`, xFourthInput, 100);

  pdfDoc.text(`TOTAL ISSUANCES:`, 5, 105);
  pdfDoc.text(`497,010`, xFirstInput, 105);
  pdfDoc.text(`271,098`, xSecondInput, 105);
  pdfDoc.text(`509,400`, xThirdInput, 105);
  pdfDoc.text(`1,277,508`, xFourthInput, 105);

  pdfDoc.text(`Less: Debit Memo (PIDF)`, 5, 110);
  pdfDoc.text(`726`, xFirstInput, 110);
  pdfDoc.text(`-`, xSecondInput, 110);
  pdfDoc.text(`-`, xThirdInput, 110);
  pdfDoc.text(`726`, xFourthInput, 110);

  pdfDoc.text(`TOTAL ISSUANCES:`, 5, 115);
  pdfDoc.text(`496,284`, xFirstInput, 115);
  pdfDoc.text(`271,098`, xSecondInput, 115);
  pdfDoc.text(`509,400`, xThirdInput, 115);
  pdfDoc.text(`1,276,782`, xFourthInput, 115);

  pdfDoc.text(`Ending inventory (per piece)`, 5, 120);
  pdfDoc.text(`3,906`, xFirstInput, 120);
  pdfDoc.text(`2,238`, xSecondInput, 120);
  pdfDoc.text(`3,810`, xThirdInput, 120);
  pdfDoc.text(`9,954`, xFourthInput, 120);

  pdfDoc.text(`Actual inventory (per piece)`, 5, 125);
  pdfDoc.text(`3,906`, xFirstInput, 125);
  pdfDoc.text(`2,238`, xSecondInput, 125);
  pdfDoc.text(`3,810`, xThirdInput, 125);
  pdfDoc.text(`9,954`, xFourthInput, 125);

  pdfDoc.save(
    `Inventory_Flow_Of_Finished_Goods_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default InvenFlowFGPDF;
