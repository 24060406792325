import POStatusesWidget from "./POStatusesWidget";
import POTable from "./POTable";
import { useDashBoardPOQuery } from "../../../store";
import PurchaseBarChart from "./PurchaseBarChart";
import POModal from "./POModal";
import React, {
  useState, useEffect
} from "react";

import {
  Input
} from "@material-tailwind/react";

export function POPageDash() {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [poStatusID, setPoStatusID] = useState(1)
  const [display, setDisplay] = useState('hidden')
  const [drapdownStatus, setdrapdownStatus] = useState()
  const [vendor, setVendor] = useState()
  const PoStatusDisplay = false
  

  const onClickHandleDisplay = (id=0, status, vendorr) => {
    if (display === 'block'){
      setDisplay('hidden')
    }else{
      setDisplay('block')
      setPoStatusID(id)
      setdrapdownStatus(status)
      setVendor(vendorr)
    }
  }

  const dash_board_po = useDashBoardPOQuery({
    client: account_detailed.client.id,
  });

  let content;
  if (dash_board_po.isLoading) {
    return <div>Loading....</div>;
  } else if (dash_board_po.error) {
    return <div>Refresh the page....</div>;
  } else {
    content = (
      <>
        <div className="block space-y-3 md:space-y-0 md:flex gap-4 mx-2 relative">
          {
            // <PurchaseBarChart approve_status={
            // 	dash_board_po.data?.data.approve
            // }/>
          }

        {drapdownStatus != 4 ? 
          <>
            <POModal display={display} poStatusID={poStatusID} drapdownStatus={drapdownStatus} onClickHandleDisplay={onClickHandleDisplay}></POModal>
          </>
          :
          <>
            <POModal display={display} vendor={vendor} poStatusID={poStatusID} drapdownStatus={drapdownStatus} onClickHandleDisplay={onClickHandleDisplay}></POModal>
          </>
        }

          <POStatusesWidget
            po_status={dash_board_po.data?.data.vrr}
            po_working={dash_board_po.data?.data.vrr_working}
            onClickHandleView={onClickHandleDisplay}
          />
        </div>

        {PoStatusDisplay ? 
          <>
            <div className="flex flex-col gap-4">
              <POTable />
            </div>
          </>

          :
          
          <>
          </>
        }
      </>
    );
  }

  return (
    <>
      <div className="mb-20 lg:mb-5 mt-3 md:mt-0">{content}</div>
    </>
  );
}

export default POPageDash;
