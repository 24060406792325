import React, { useState, useEffect } from "react";
import { Select, Option, Input } from "@material-tailwind/react";

import { useFetchAverageWeeklySchedQuery } from "../../../store";
import moment from "moment-timezone";

const day = moment().tz("Asia/Manila").isoWeekday();

const SlMultiplierSelect = ({ setSlValue }) => {
  const [average, setAverage] = useState(true);
  const { data, error, isLoading, isSuccess } = useFetchAverageWeeklySchedQuery(
    {
      day: day,
    }
  );
  const handleChange = (value) => {
    setAverage(value);
    setSlValue(value.value);
  };

  useEffect(() => {
    if (isSuccess && data) {
      setAverage(data.data.multiplier);
      setSlValue(data.data.multiplier.value);
    } else {
      return;
    }
  }, [isSuccess, data]);

  console.log("scheduled SL : " + data);
  return (
    <>
      {isSuccess && (
        // <Select
        //   label="Stock Level"
        //   onChange={handleChange}
        //   value={average ? `${average.name}` : null}
        //   disabled={true}
        // >
        //   {data.data.map((sl) => (
        //     <Option key={sl.id} value={`${sl.multiplier}`}>
        //       {sl.multiplier.name}
        //     </Option>
        //   ))}
        // </Select>
        <Input
          label="Stock Level"
          name="sl"
          value={average ? average.name + " - " + average.value : ""}
          readOnly={true}
        />
      )}
    </>
  );
};

export default SlMultiplierSelect;
