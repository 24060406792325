import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  CardFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchOutletListPageQuery } from "../../store";
import { useUpdateStockLevelMutation } from "../../store";
import { Confirmation } from ".././modal_form_components/Error_trap_components/ErrorTrapComponent";
import { useFetchCurrentStockLvlQuery } from "../../store";
import ProductTbl from "../modal_form_components/Outlet/SalesReport/SLTbl";
import { ToastContainer, toast } from "react-toastify";

export function CompleteTableSLView() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["", ""];
  const tableHeadSelectOrder = ["", "ORDER #", "OUTLET", "ROUTE", "FC"];
  const tableHheadSelectOutlet = ["", "OUTLET", "FC", "ROUTE", "ADDRESS"];
  const trapMessage =
    "Are you certain you want to keep these stock level records?";
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  let outlet_list_page_result = [];
  let stock_lvl_result = [];

  const [selectedCage, setSelectedCage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOutlets, setSelectedOutlets] = useState(null);
  const [currentOutletPage, setCurrentOutletPage] = useState(1);
  const [pageOutletSize, setPageOutletSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isOutletDialogOpen, setIsOutletDialogOpen] = useState(false);
  const [currentOutletID, setCurrentOutletID] = useState(1);

  let [productData, setProductData] = useState([]);
  let [beveragesData, setBeveragesData] = useState([]);
  let [bunsData, setBunsData] = useState([]);
  let [wrappersData, setWrappersData] = useState([]);
  let [sundriesData, setSundriesData] = useState([]);
  let [cigarettesData, setCigarettesData] = useState([]);
  let [osData, setOsData] = useState([]);
  let [tankData, setTankData] = useState([]);
  let [isReadyToSave, setIsReadyToSave] = useState(false);

  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  const {
    data: outletListData,
    error: outletListErr,
    isLoading: outletListLoding,
    isSuccess: outletListSuccess,
  } = useFetchOutletListPageQuery({
    page: currentOutletPage,
    page_size: 500,
    search: searchTerm,
    fc: "",
    route: "",
  });

  useEffect(() => {
    if (
      outletListSuccess &&
      outletListData &&
      outletListData.results &&
      outletListData.results.length > 0
    ) {
      setSelectedOutlets(outletListData.results[0]);
      setCurrentOutletID(outletListData.results[0].id);
    }
  }, [outletListSuccess, outletListData]);

  const {
    data: stockData,
    error: stockIsErr,
    isLoading: stockIsLoading,
    isSuccess: stockIsSuccess,
    refetch,
  } = useFetchCurrentStockLvlQuery(currentOutletID);

  //fetching logo/img from localstorage
  const logo = JSON.parse(localStorage.getItem("account_detail"));
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";

  const generateFC = () => {
    return selectedOutlets ? selectedOutlets.fc.name : "";
  };
  const generateRoute = () => {
    return selectedOutlets ? selectedOutlets.route.name : "";
  };
  const generateAddress = () => {
    return selectedOutlets ? selectedOutlets.address : "";
  };

  let handleProductOrderChange = (data) => {
    setProductData(data);
  };
  let handleBeveragestOrderChange = (bev_data) => {
    setBeveragesData(bev_data);
  };
  let handleBunsOrderChange = (buns_data) => {
    setBunsData(buns_data);
  };

  let handleWrappersOrderChange = (wrappers_data) => {
    setWrappersData(wrappers_data);
  };

  let handleSundriesOrderChange = (sundries_data) => {
    setSundriesData(sundries_data);
  };

  let handleCigarettesOrderChange = (cigarettes_data) => {
    setCigarettesData(cigarettes_data);
  };

  let handleOsOrderChange = (os_data) => {
    setOsData(os_data);
  };

  let handleTankOrderChange = (tank_data) => {
    setTankData(tank_data);
  };

  const [updateStockLevel] = useUpdateStockLevelMutation();

  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
  };
  // Handle change event
  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentOutletPage(1);
  };
  const handlePreviousOutletPage = () => {
    setCurrentOutletPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextOutletPage = () => {
    setCurrentOutletPage((prevPage) => prevPage + 1);
  };

  const handleClearOutlet = () => {
    setSelectedOutlets(null);
  };

  useEffect(() => {
    // Check if all conditions are met
    const isReady = signature !== "";
    // Update the state based on the condition
    setIsReadyToSave(isReady);
  }, [signature]);

  const handleOpenOutletDialog = () => {
    // Your logic to handle opening the dialog
    setIsOutletDialogOpen(true);
    console.log(selectedOutlets);
  };

  const handleDialogClose = () => {
    // Your logic to handle closing the dialog
    setIsOutletDialogOpen(false);
  };

  const handleOutletChange = (selectedOutlet) => {
    // Set the selected outlet
    setSelectedOutlets(selectedOutlet);
    setCurrentPage(1);
    handleDialogClose();

    setCurrentOutletID(selectedOutlet.id);
    console.log(currentOutletID);
  };

  const handleMainClose = () => {
    setOpen(false);
    setSignature("");
  };

  const handleUpdateSL = async (event) => {
    event.preventDefault();
    let allData = {
      user: account_detailed.user.id,
      outlet: selectedOutlets,
      productData,
      beveragesData,
      bunsData,
      wrappersData,
      sundriesData,
      cigarettesData,
      osData,
      tankData,
    };
    console.log(allData);
    try {
      const sea = await updateStockLevel(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setProductData([]);
        setBeveragesData([]);
        setBunsData([]);
        setWrappersData([]);
        setSundriesData([]);
        setCigarettesData([]);
        setOsData([]);
        setTankData([]);
        refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  if (outletListLoding) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (outletListErr) {
    return <div>Error loading data</div>;
  } else if (outletListData.length === 0) {
    return <div>No data found.</div>;
  } else if (outletListSuccess && stockIsSuccess) {
    outlet_list_page_result = outletListData.results;
    // if(stockIsLoading && selectedOutlets !== null){
    //   return <div>Loading...</div>;
    // } else if (stockIsErr && selectedOutlets !== null) {
    //   return <div>Error loading data</div>;
    // } else if (stockIsSuccess && selectedOutlets !== null) {
    //   stock_lvl_result = stockData.data;
    // }
  }
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D] ml-5" size="md">
        Outlet's Stock Level
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          {/* <img
            src={burgerMachineLogo}
            alt="logo"
            className="aspect-auto w-72"
          /> */}
          <Typography className="text-green-900" variant="h3">
            Stock Level
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Outlet"
                    value={selectedOutlets ? selectedOutlets.outlet : ""}
                    onClick={handleOpenOutletDialog}
                    readOnly
                  />
                  <Dialog
                    size="xl"
                    open={isOutletDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Outlets
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <Input
                        label="Search"
                        icon={<FiSearch className="h-5 w-5" />}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />

                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHheadSelectOutlet.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {outlet_list_page_result.map((outlet) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr
                                key={outlet.id}
                                onClick={() => handleOutletChange(outlet)}
                              >
                                <td className={classes}>
                                  <input
                                    type="radio"
                                    color="teal"
                                    name="selectedOutlet"
                                    checked={
                                      selectedOutlets &&
                                      selectedOutlets.id === outlet.id
                                    }
                                    onChange={() => handleOutletChange(outlet)}
                                  />
                                </td>
                                <td className={classes}>{outlet.outlet}</td>
                                <td className={classes}>{outlet.fc.name}</td>
                                <td className={classes}>{outlet.route.name}</td>
                                <td className={classes}>{outlet.address}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        Page{" "}
                        {`${currentOutletPage} of ${
                          Math.ceil(outletListData?.count / pageOutletSize) || 1
                        }`}
                      </Typography>
                      <div className="flex gap-2">
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handlePreviousOutletPage}
                          disabled={!outletListData?.previous}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handleNextOutletPage}
                          disabled={!outletListData?.next}
                        >
                          {outletListData?.next ? "Next" : "No More Pages"}
                        </Button>
                      </div>
                    </CardFooter>
                  </Dialog>
                  {selectedOutlets !== null && (
                    <Button
                      onClick={handleClearOutlet}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                </div>

                <div className={inputClasses}>
                  <Input
                    label="Address"
                    name="address"
                    value={generateAddress()}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input label="FC" name="fc" value={generateFC()} readOnly />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Route"
                    name="route"
                    value={generateRoute()}
                    readOnly
                  />
                </div>
              </div>
            </div>

            {selectedOutlets !== null && stockIsSuccess && (
              <div className="m-5">
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Meat & Dessert
                  <ProductTbl
                    onOrderChange={handleProductOrderChange}
                    slData={stockData.data}
                    category={1}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Toppings
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleSundriesOrderChange}
                    category={2}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Beverages
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleBeveragestOrderChange}
                    category={3}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Breads & Buns
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleBunsOrderChange}
                    category={4}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Sauce & Dressings
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleWrappersOrderChange}
                    category={5}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Wrappers
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleOsOrderChange}
                    category={6}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Sundries
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleCigarettesOrderChange}
                    category={7}
                  />
                </Typography>
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Supplies
                  <ProductTbl
                    slData={stockData.data}
                    onOrderChange={handleTankOrderChange}
                    category={8}
                  />
                </Typography>
              </div>
            )}

            <div className="col-span-1 flex items-center justify-center space-y-5">
              <div className={signatureBoxclasses}>
                <ReactSignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 100,
                    className: "border-black border-2 rounded-s-xl",
                  }}
                  onEnd={handleSignatureChange}
                />
                <div className={boxResetClasses}>
                  <GrPowerReset
                    size={30}
                    className="cursor-pointer"
                    onClick={handleSignatureReset}
                    color="black"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center space-y-5">
              <Typography className="text-justify text-black" variant="h6">
                {account_detailed.user.first_name
                  ? account_detailed.user.last_name
                    ? `${account_detailed.user.first_name} ${account_detailed.user.last_name}`
                    : "Encoder"
                  : "Encoder"}
              </Typography>
            </div>
            {signature == "" && (
              <Typography variant="lead" color="red">
                Please provide a signature.
              </Typography>
            )}
            <div className="col-span-1 flex items-center justify-center space-y-5 mt-10">
              <Confirmation
                onConfirm={handleUpdateSL}
                isReadyToSave={isReadyToSave}
                trapMessage={trapMessage}
              />
            </div>
          </form>
          <ToastContainer />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
