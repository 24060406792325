import React, { useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import { AddDelApprovalItemList } from "../../table_components/Delivery/GenDelReciTbl";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useUpdateApproveAddDelApprovalMutation } from "../../../store";
import { toast } from "react-toastify";
import { ApproveDeliveryConfirmation } from "../Error_trap_components/ApproveDeliveryConfirmation";
import { useFetchDeliveryItemsDetailsQuery } from "../../../store";
import FreshGoodsModal from "./DeliveryApprovalFreshGoodsModal";
import TruckLoader from "../../reusable/truckLoader";

function AddDeliveryDetailsApprovalModal({
  getDeliverId,
  delData,
  data_refetch,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const formattedDate = delData.delivery_date
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(delData.delivery_date))
    : "";

  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  const [isSufficient, setIsSufficient] = useState(false);
  const [productIdQtyList, setProductIdQtyList] = useState([]);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch: refetchGroupItems,
  } = useFetchDeliveryItemsDetailsQuery({
    page: 1,
    page_size: 100,
    del_tracking_num: getDeliverId,
    is_fresh: 2,
  });
  // let freshGoodIds = [];
  // if (isSuccess && data && Array.isArray(data)) {
  //   // Iterate over the data array and extract the id from each item
  //   freshGoodIds = data.map(item => item.id);
  // }

  const [updateApproveDelivery] = useUpdateApproveAddDelApprovalMutation();
  const handleSufficientData = (isSufficient) => {
    setIsSufficient(isSufficient);
  };
  console.log("suddifcient", isSufficient);
  const handleProductIds = (productIdQtyList) => {
    setProductIdQtyList(productIdQtyList);
  };

  const [refetchTrigger, setRefetchTrigger] = useState(false);

  const triggerRefetch = () => {
    setRefetchTrigger(true);
  };

  // Callback to reset the refetch trigger
  const resetRefetch = () => {
    setRefetchTrigger(false);
  };

  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
    setIsSignatureEmpty(true);
  };
  // Handle change event
  const handleSignatureChange = () => {
    const isEmpty = signatureRef.current.isEmpty();
    setIsSignatureEmpty(isEmpty);
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };
  const handleClose = () => {
    setOpen(false);
    setIsSignatureEmpty(true);
  };

  const handleApproveDelivery = async (event) => {
    event.preventDefault();
    let base64Data = signature.slice("data:image/png;base64,".length);
    let allData = {
      signature: base64Data,
      deliveryId: getDeliverId,
      account: account_detailed,
    };
    const sea = await updateApproveDelivery(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }
    data_refetch();
    handleOpen();
    handleClose();
  };

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Addl Slip
      </Button>
      <Dialog
        size="xl"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          {/* <img
            src="https://oodc.com.ph/wp-content/uploads/2023/05/viber_image_2023-05-02_14-20-42-700.png"
            className="aspect-auto w-48"
          /> */}
          <Typography className="text-green-900" variant="h3">
            Additional Delivery Approval Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <div className="col-span-1 flex items-center justify-center space-y-5 mb-7">
            <Typography variant="h5" color="blue-gray">
              Order Summary
            </Typography>
          </div>
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Delivery Date"
                    name="delivery_date"
                    value={formattedDate}
                    readOnly
                  />
                </div>
                {/* <div className={inputClasses}>
                  <Input label="Route" name="route" value={delData.route.name || "NA"} readOnly/>
                </div> */}
                <div className={inputClasses}>
                  <Input
                    label="Delivery Slip Number (DTN)"
                    name="dtn"
                    value={delData.delivery_number || "NA"}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Requested By"
                    name="tsn"
                    value={
                      delData.custodian_name.first_name +
                        " " +
                        delData.custodian_name.last_name || "N/A"
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Outlet"
                    name="outlet"
                    value={
                      delData.orders && delData.orders.length > 0
                        ? delData.orders
                            .map((order) => order.outlet?.outlet || "No Outlet")
                            .join(", ")
                        : "TBD"
                    }
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="FC"
                    name="fc"
                    value={delData.fc ? delData.fc.name || "NA" : "NA"}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Route"
                    name="route"
                    value={delData.route ? delData.route.name || "NA" : "NA"}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="m-5">
              <AddDelApprovalItemList
                getDeliverId={getDeliverId}
                onSufficientData={handleSufficientData}
                productIdsQtyList={handleProductIds}
                refetchTrigger={refetchTrigger}
                resetRefetch={resetRefetch}
              />
              {isSuccess && <FreshGoodsModal itemData={data} />}
            </div>

            {/* <AdjustProductQtyTable
              getDeliverId={getDeliverId}
              triggerRefetch={triggerRefetch}
            /> */}

            <div className="col-span-1 flex items-center justify-center space-y-5">
              <div className={signatureBoxclasses}>
                <ReactSignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 100,
                    className: "border-black border-2 rounded-s-xl",
                  }}
                  onEnd={handleSignatureChange}
                />
                <div className={boxResetClasses}>
                  <GrPowerReset
                    size={30}
                    className="cursor-pointer"
                    onClick={handleSignatureReset}
                    color="black"
                  />
                </div>
              </div>
            </div>
            <Typography className="text-center text-black" variant="h6">
              {account_detailed.user.first_name
                ? account_detailed.user.last_name
                  ? `${account_detailed.user.first_name} ${account_detailed.user.last_name}`
                  : "General Manager"
                : "General Manager"}
            </Typography>
            {isSignatureEmpty && (
              <Typography variant="lead" color="red">
                Please provide a signature.
              </Typography>
            )}
          </form>
        </DialogBody>
        <DialogFooter>
          <ApproveDeliveryConfirmation
            onConfirm={handleApproveDelivery}
            isSufficient={true}
            isSignatureEmpty={signature}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default AddDeliveryDetailsApprovalModal;
