import { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useFetchDSRFormatProductListQuery } from "../../../../store";

const TABLE_HEAD = [
  "Code",
  "Name",
  "Daily AVG. Cons.",
  "Low",
  "High",
  "New AVG. Cons",
  "Unit Measure",
];

const classes = "p-4 border-b border-blue-gray-50";

// const [bunsActualQty, setBunsActualQty] = useState([]);
// const [beveragesActualQty, setBeveragesActualQty] = useState([]);

function ProductTbl({ onOrderChange, slData, category }) {
  const { data, error, isLoading, isSuccess } =
    useFetchDSRFormatProductListQuery({
      category: category,
    });
  let [productActualQty, setProductActualQty] = useState([]);
  let product_result = [];
  // useEffect(() => {
  //   if (isSuccess && data) {
  //     setProductActualQty(new Array(data.data.length).fill(0));
  //   }
  // }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: product_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_result);

    onOrderChange(product_data);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess && data.data.length > 0) {
    product_result = data.data;

    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {product_result.map((product, index) => {
              const item = slData.find(
                (item) => item.product.id === product.id
              );
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;

              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {product.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {product.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {category == 8
                            ? stockLevelNum
                            : (stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {category == 8
                            ? stockLevelNum
                            : (stockLevelNum * 5).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onChange={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {product.weight_box}/ {product.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}

export default ProductTbl;
