import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { GrPowerReset } from "react-icons/gr";
import {
  useAccountListSelectQuery,
  useFetchScheduleProductQuery,
  useSelectedEmployeeSchedQuery,
} from "../../../store";
import SignatureCanvas from "react-signature-canvas";
import GenerateCompletedProductionSlip from "../../form_components/pdf/GenerateCompletedProductionSlip";

function ProdCompletedSlip({ prod_id }) {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const { data, isLoading, error, refetch } =
    useFetchScheduleProductQuery(prod_id);
  const selectedEmpSched = useSelectedEmployeeSchedQuery(prod_id);
  const accouint_list_select = useAccountListSelectQuery(1);
  const product_line = data?.data.product_line;

  let als = [];
  if (accouint_list_select.isLoading) {
    //pass
  } else if (accouint_list_select.error) {
    //pass
  } else {
    als = accouint_list_select.data.data;
  }

  // refetch the data every 3sec
  let interval = 3000;
  useEffect(() => {
    refetch();
  }, [interval]);

  const inputClasses = "flex flex-row items-center";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const TableHead = [
    "Production Items",
    "Scheduled Batch",
    "Actual Batch",
    "Theoretical Output",
    "Actual Weight",
    "Theoretical Weight",
    "Weight Box",
    "Actual Yield",
    "Good",
    "Reject",
  ];
  if (product_line === 1) {
    TableHead.push("Reject Kilos")
  }
  const prodLine = [
    {
      id: 1,
      name: "Bakery",
    },
    {
      id: 2,
      name: "Patty",
    },
    {
      id: 3,
      name: "Sandwich line",
    },
    {
      id: 4,
      name: "Catsup",
    },
    {
      id: 5,
      name: "Cake and Pastries",
    },
    {
      id: 6,
      name: "Premixes",
    },
  ];
  const assignatories = [
    {
      id: "1",
      label: "bakery_team_leader",
      title: "Bakery Team Leader",
      ref: React.createRef(),
    },
    {
      id: "2",
      label: "production_supervisor",
      title: "Production Supervisor",
      ref: React.createRef(),
    },
  ];

  const clearSignature = (canvasRef) => {
    canvasRef.current.isEmpty() ? console.log("") : canvasRef.current.clear();
  };

  let selected_emp_arr = [];
  if (selectedEmpSched.isLoading) {
    return <div>Loading....</div>;
  } else if (selectedEmpSched.error) {
    return <div>Refresh the page...</div>;
  } else {
    selected_emp_arr = selectedEmpSched.data?.data;
  }

  let content;
  if (isLoading) {
    return <div>Loading....</div>;
  } else if (error) {
    return <div>Refresh the page...</div>;
  } else {

    content = <>
      {data?.data.sched_product.map((pr, i) => {
        const isLast = pr.length - 1;
        const classes = isLast
          ? "p-4 text-[#252525] text-sm"
          : "p-4 border-b border-blue-gray-50 text-[#252525] text-sm";
        let ab = 0;
        if (pr.is_decimal_acc_batch == true){
          ab = pr.ast_decimal
        }else{
          ab = pr.actual_batch
        }
        return (
          <>
            <tr key={i}>
              <td className={classes}>{pr.name}</td>
              <td className={classes}>{pr.schedule_batch}</td>
              {/* Actual Batch */}

              <td className={classes}>{ab}</td>
              {/* Theoretical Output */}
              <td className={classes}>{pr.theo_yield}</td>
              {/* Actual Weight */}
              <td className={classes}>{pr.actual_weight}</td>
              {/* Theoretical Weight */}
              <td className={classes}>{pr.theo_weight}</td>
              {/* Weight in box */}
              <td className={classes}>{pr.weight_box}</td>
              {/* Actual Yield */}
              <td className={classes}>{pr.actual_yield}</td>
              {/* Good */}
              <td className={classes}>{pr.good}</td>
              {/* Reject */}
              <td className={classes}>{pr.reject}</td>
              {product_line === 1 ? (
                <td className={classes}>{pr.reject_kilos}</td>
                ) : ''}
            </tr>
            {
              pr.is_end === true ? (
                <tr key={i}>
                  <td className={classes}>MD END</td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}></td>
                  <td className={classes}>{pr.end_rej_qty}</td>
                  <td className={classes}>{pr.end_rej_kilos_qty}</td>
                </tr>
              ) : ('')
            }
          </>
        );
      })}
    </>
  }
  console.log(data.data?.transfer_to)
  return (
    <>
      <Button onClick={handleOpen} color="teal" size="sm">
        View
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
        size="xl"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <img
            src={burgerMachineLogo}
            alt="bmLogo"
            className="aspect-auto w-72"
          />
          <Typography className="text-green-900" variant="h3">
            Production Report
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Typography variant="h5">
                    {"Transfer From: " +  data?.data.issuance_from}
                  </Typography>
                </div>
                <div className="relative h-10 w-full">
                  {/* fist select */}
                  <Typography variant="h5">
                    Transfer to:{" "}
                    {data.data?.transfer_to != null
                      ? data.data?.transfer_to
                      : ""}
                  </Typography>
                </div>
                <div className={inputClasses}>
                  <Typography variant="h5">
                    Transfer Request Date: {data?.data.transfer_request_date}
                  </Typography>
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Typography variant="h5">
                    Issued By: {data?.data.requested_by}
                  </Typography>
                </div>
                {/* <div className={inputClasses}>
                  <Typography variant="h5">
                    {data?.data != null
                      ? data?.data.recieve_by != null
                        ? `Receiver: ${data.data?.requested_by}`
                        : ""
                      : ""}
                  </Typography>
                </div> */}
                <div className={inputClasses}>
                  <Typography variant="h5">
                    Transfer Tracking Number: {data?.data.transfer_number}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TableHead.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center space-x-10">
              {data?.data.bakery_team_leader == null ? (
                ""
              ) : (
                <div className="flex flex-col justify-center items-center mt-10 space-y-3">
                  <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                    <img
                      src={apiDomain + data?.data.bakery_team_leader}
                      alt=""
                    />
                  </div>
                  <Typography>{}</Typography>
                  <Typography>Bakery Team Leader</Typography>
                </div>
              )}
              {data?.data.production_supervisor == null ? (
                ""
              ) : (
                <div className="flex flex-col justify-center items-center mt-10 space-y-3">
                  <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                    <img
                      src={apiDomain + data?.data.production_supervisor}
                      alt=""
                    />
                  </div>
                  <Typography>{}</Typography>
                  <Typography>Production Supervisor</Typography>
                </div>
              )}
              {assignatories.map((sig) => {
                if (data?.data.bakery_team_leader === null) {
                  if (sig.label === "bakery_team_leader") {
                    return (
                      <>
                        <div key={sig.id} className="flex flex-col">
                          <div className="flex flex-row mt-8">
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 200,
                                height: 100,
                                className: "border-black border-2 rounded-l-xl",
                              }}
                              ref={sig.ref}
                            />
                            <div className={boxResetClasses}>
                              <GrPowerReset
                                size={"30px"}
                                onClick={() => clearSignature(sig.ref)}
                                className="cursor-pointer"
                                color="white"
                              />
                            </div>
                          </div>
                          <Typography className="text-center mt-3">
                            {sig.title}
                          </Typography>
                        </div>
                      </>
                    );
                  }
                }
                if (data?.data.production_supervisor === null) {
                  if (sig.label === "production_supervisor") {
                    return (
                      <>
                        <div key={sig.id} className="flex flex-col">
                          <div className="flex flex-row mt-8">
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 200,
                                height: 100,
                                className: "border-black border-2 rounded-l-xl",
                              }}
                              ref={sig.ref}
                            />
                            <div className={boxResetClasses}>
                              <GrPowerReset
                                size={"30px"}
                                onClick={() => clearSignature(sig.ref)}
                                className="cursor-pointer"
                                color="white"
                              />
                            </div>
                          </div>
                          <Typography className="text-center mt-3">
                            {sig.title}
                          </Typography>
                        </div>
                      </>
                    );
                  }
                }
              })}
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <GenerateCompletedProductionSlip prod_id={prod_id} />
          <Button
            variant="text"
            color="red"
            className="mr-3 ml-3"
            onClick={handleOpen}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ProdCompletedSlip;
