import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const BreadProductionPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("BREAD PRODUCTION REPORT", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCT INC.", 5, 10);

  // Flour Usage (HARD)
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`FLOUR USAGE (HARD)`, 7, 25);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`JB`, xFirstInput, 25);
  pdfDoc.text(`RB`, xSecondInput, 25);
  pdfDoc.text(`MB`, xThirdInput, 25);
  pdfDoc.text(`TOTAL`, xFourthInput, 25);

  pdfDoc.text(`Kilos used`, 7, 30);
  pdfDoc.text(`6,390`, xFirstInput, 30);
  pdfDoc.text(`8,514`, xSecondInput, 30);
  pdfDoc.text(`4,374`, xThirdInput, 30);
  pdfDoc.text(`19,278`, xFourthInput, 30);

  pdfDoc.text(`Kilos per batch`, 7, 35);
  pdfDoc.text(`18`, xFirstInput, 35);
  pdfDoc.text(`18`, xSecondInput, 35);
  pdfDoc.text(`18`, xThirdInput, 35);

  pdfDoc.text(`Total Batches used`, 7, 40);
  pdfDoc.text(`355`, xFirstInput, 40);
  pdfDoc.text(`473`, xSecondInput, 40);
  pdfDoc.text(`243`, xThirdInput, 40);
  pdfDoc.text(`1,071`, xFourthInput, 40);

  // Flour Usage (SOFT)
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`FLOUR USAGE (SOFT)`, 7, 45);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`Kilos used`, 7, 50);
  pdfDoc.text(`3,550`, xFirstInput, 50);
  pdfDoc.text(`4,730`, xSecondInput, 50);
  pdfDoc.text(`2,430`, xThirdInput, 50);
  pdfDoc.text(`10,710`, xFourthInput, 50);

  pdfDoc.text(`Kilos per batch`, 7, 55);
  pdfDoc.text(`10`, xFirstInput, 55);
  pdfDoc.text(`10`, xSecondInput, 55);
  pdfDoc.text(`10`, xThirdInput, 55);

  pdfDoc.text(`Total Batches used`, 7, 60);
  pdfDoc.text(`355`, xFirstInput, 60);
  pdfDoc.text(`473`, xSecondInput, 60);
  pdfDoc.text(`243`, xThirdInput, 60);
  pdfDoc.text(`1,071`, xFourthInput, 60);

  // Yield Compliance Section
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`BREAD PRODUCTION`, 7, 65);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`Yield Compliance`, 7, 70);
  pdfDoc.text(`JB`, xFirstInput, 70);
  pdfDoc.text(`RB`, xSecondInput, 70);
  pdfDoc.text(`MB`, xThirdInput, 70);
  pdfDoc.text(`TOTAL`, xFourthInput, 70);

  pdfDoc.text(`Theoretical Yield Per Batch`, 7, 75);
  pdfDoc.text(`782`, xFirstInput, 75);
  pdfDoc.text(`1,095`, xSecondInput, 75);
  pdfDoc.text(`2,180`, xThirdInput, 75);
  pdfDoc.text(`4,057`, xFourthInput, 75);

  pdfDoc.text(`# of Batches Used`, 7, 80);
  pdfDoc.text(`355`, xFirstInput, 80);
  pdfDoc.text(`473`, xSecondInput, 80);
  pdfDoc.text(`243`, xThirdInput, 80);
  pdfDoc.text(`1,071`, xFourthInput, 80);

  pdfDoc.text(`Theoretical Yield (in pieces)`, 7, 85);
  pdfDoc.text(`278,780`, xFirstInput, 85);
  pdfDoc.text(`519,480`, xSecondInput, 85);
  pdfDoc.text(`531,329`, xThirdInput, 85);
  pdfDoc.text(`1,329,589`, xFourthInput, 85);

  pdfDoc.text(`Actual Yield (in pieces)`, 7, 90);
  pdfDoc.text(`278,780`, xFirstInput, 90);
  pdfDoc.text(`519,480`, xSecondInput, 90);
  pdfDoc.text(`531,329`, xThirdInput, 90);
  pdfDoc.text(`1,329,589`, xFourthInput, 90);

  pdfDoc.text(`Over \ (Under) Compliance`, 7, 95);
  pdfDoc.text(`1,170`, xFirstInput, 95);
  pdfDoc.text(`1,545`, xSecondInput, 95);
  pdfDoc.text(`1,589`, xThirdInput, 95);
  pdfDoc.text(`4,304`, xFourthInput, 95);

  pdfDoc.text(`% Yield Compliance`, 7, 100);
  pdfDoc.text(`100.42%`, xFirstInput, 100);
  pdfDoc.text(`100.30%`, xSecondInput, 100);
  pdfDoc.text(`100.30%`, xThirdInput, 100);
  pdfDoc.text(`100.32%`, xFourthInput, 100);

  // Weight Compliance Section
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`Weight Compliance Based on PTCM including Rejects`, 5, 105);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`JB`, xFirstInput, 105);
  pdfDoc.text(`RB`, xSecondInput, 105);
  pdfDoc.text(`MB`, xThirdInput, 105);
  pdfDoc.text(`TOTAL`, xFourthInput, 105);

  pdfDoc.text(`Standard Ave. Weight Per Piece`, 7, 110);
  pdfDoc.text(`55.00`, xFirstInput, 110);
  pdfDoc.text(`40.00`, xSecondInput, 110);
  pdfDoc.text(`19.00`, xThirdInput, 110);

  pdfDoc.text(`Actual Ave. Weight Per Piece`, 7, 115);
  pdfDoc.text(`54.99`, xFirstInput, 115);
  pdfDoc.text(`39.11`, xSecondInput, 115);
  pdfDoc.text(`18.76`, xThirdInput, 115);

  pdfDoc.text(`Variance: Over \ (Short)`, 7, 120);
  pdfDoc.text(`(0.01)`, xFirstInput, 120);
  pdfDoc.text(`(0.89)`, xSecondInput, 120);
  pdfDoc.text(`(0.24)`, xThirdInput, 120);

  pdfDoc.text(`% Weight Compliance`, 7, 125);
  pdfDoc.text(`99.98%`, xFirstInput, 125);
  pdfDoc.text(`97.78%`, xSecondInput, 125);
  pdfDoc.text(`98.74%`, xThirdInput, 125);

  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`Weight Compliance Based on PTCM`, 5, 130);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`JB`, xFirstInput, 130);
  pdfDoc.text(`RB`, xSecondInput, 130);
  pdfDoc.text(`MB`, xThirdInput, 130);
  pdfDoc.text(`TOTAL`, xFourthInput, 130);
  pdfDoc.text(`Standard Ave. Weight Per Piece`, 7, 135);
  pdfDoc.text(`Actual Ave. Weight Per Piec`, 7, 140);
  pdfDoc.text(`Variance: Over \ (Short)`, 7, 145);
  pdfDoc.text(`% Weight Compliance`, 7, 150);
  pdfDoc.text(`Input (Theoretical Yield x Std. Ave. Wt.)`, 7, 155);
  pdfDoc.text(`Output (Actual Yield x Actual Wt.) `, 7, 160);
  pdfDoc.text(`Over - All % Compliance) `, 7, 165);

  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`Weight Compliance Based on TCSD Reports`, 5, 170);
  pdfDoc.setTextColor(0);

  pdfDoc.text(`Standard Ave. Weight Per Piece`, 5, 175);
  pdfDoc.text(`40.00`, xFirstInput, 175);
  pdfDoc.text(`55.00`, xSecondInput, 175);
  pdfDoc.text(`19.00`, xThirdInput, 175);

  pdfDoc.text(`Actual Ave. Weight Per Piece`, 5, 180);
  pdfDoc.text(`39.11`, xFirstInput, 180);
  pdfDoc.text(`54.99`, xSecondInput, 180);
  pdfDoc.text(`18.76`, xThirdInput, 180);

  pdfDoc.text(`Variance: Over \ (Short)`, 5, 185);
  pdfDoc.text(`(0.89)`, xFirstInput, 185);
  pdfDoc.text(`(0.01)`, xSecondInput, 185);
  pdfDoc.text(`(0.24)`, xThirdInput, 185);

  pdfDoc.text(`% Weight Compliance`, 5, 190);
  pdfDoc.text(`99.98%`, xFirstInput, 190);
  pdfDoc.text(`97.78%`, xSecondInput, 190);
  pdfDoc.text(`98.74%`, xThirdInput, 190);

  pdfDoc.text(`Input (Theoretical Yield x Std. Ave. Wt.)`, 5, 195);
  pdfDoc.text(`19,739.64`, xFirstInput, 195);
  pdfDoc.text(`14,780.37`, xSecondInput, 195);
  pdfDoc.text(`9,611.70`, xThirdInput, 195);
  pdfDoc.text(`44,131.71`, xFourthInput, 195);

  pdfDoc.text(`Output (Actual Yield x Actual Wt.)`, 5, 200);
  pdfDoc.text(`19,360.86`, xFirstInput, 200);
  pdfDoc.text(`14,842.02`, xSecondInput, 200);
  pdfDoc.text(`9,520.10`, xThirdInput, 200);
  pdfDoc.text(`43,722.98`, xFourthInput, 200);

  pdfDoc.text(`Over - All % Compliance`, 5, 205);
  pdfDoc.text(`100.42%`, xFirstInput, 205);
  pdfDoc.text(`98.08%`, xSecondInput, 205);
  pdfDoc.text(`99.05%`, xThirdInput, 205);
  pdfDoc.text(`99.07%`, xFourthInput, 205);

  // Weight Compliance Based on OPS Reports
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`Weight Compliance Based on OPS Reports: JB RB MB TOTAL`, 5, 210);
  pdfDoc.setTextColor(0);

  pdfDoc.text(`Standard Ave. Weight Per Piece`, 5, 215);
  pdfDoc.text(`40.00`, xFirstInput, 215);
  pdfDoc.text(`55.00`, xSecondInput, 215);
  pdfDoc.text(`19.00`, xThirdInput, 215);

  pdfDoc.text(`Actual Ave. Weight Per Piece`, 5, 220);
  pdfDoc.text(`-`, xFirstInput, 220);
  pdfDoc.text(`-`, xSecondInput, 220);
  pdfDoc.text(`-`, xThirdInput, 220);

  pdfDoc.text(`Variance: Over \ (Short)`, 5, 225);
  pdfDoc.text(`(40.00)`, xFirstInput, 225);
  pdfDoc.text(`(55.00)`, xSecondInput, 225);
  pdfDoc.text(`(19.00)`, xThirdInput, 225);

  pdfDoc.text(`% Weight Compliance`, 5, 230);
  pdfDoc.text(`0.00%`, xFirstInput, 230);
  pdfDoc.text(`0.00%`, xSecondInput, 230);
  pdfDoc.text(`0.00%`, xThirdInput, 230);

  pdfDoc.text(`Input (Theoretical Yield x Std. Ave. Wt.)`, 5, 235);
  pdfDoc.text(`19,739.64`, xFirstInput, 235);
  pdfDoc.text(`14,780.37`, xSecondInput, 235);
  pdfDoc.text(`9,611.70`, xThirdInput, 235);
  pdfDoc.text(`44,131.71`, xFourthInput, 235);

  pdfDoc.text(`Output (Actual Yield x Actual Wt.)`, 5, 240);
  pdfDoc.text(`0.00`, xFirstInput, 240);
  pdfDoc.text(`0.00`, xSecondInput, 240);
  pdfDoc.text(`0.00`, xThirdInput, 240);
  pdfDoc.text(`-`, xFourthInput, 240);

  pdfDoc.text(`Over - All % Compliance`, 5, 245);
  pdfDoc.text(`0.00%`, xFirstInput, 245);
  pdfDoc.text(`0.00%`, xSecondInput, 245);
  pdfDoc.text(`0.00%`, xThirdInput, 245);
  pdfDoc.text(`0.00%`, xFourthInput, 245);

  // Efficiency Compliance, Net of Rejects
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`Efficiency Compliance, Net of Rejects: JB RB MB TOTAL`, 5, 250);
  pdfDoc.setTextColor(0);

  pdfDoc.text(`Actual Yield (in pieces)`, 5, 255);
  pdfDoc.text(`278,780`, xFirstInput, 255);
  pdfDoc.text(`519,480`, xSecondInput, 255);
  pdfDoc.text(`531,329`, xThirdInput, 255);
  pdfDoc.text(`1,329,589`, xFourthInput, 255);

  pdfDoc.text(`Less: Bread Rejects (in pieces)`, 5, 260);
  pdfDoc.text(`24,444`, xFirstInput, 260);
  pdfDoc.text(`8,876`, xSecondInput, 260);
  pdfDoc.text(`23,861`, xThirdInput, 260);
  pdfDoc.text(`57,181`, xFourthInput, 260);

  pdfDoc.text(`Actual Yield, Net of Rejects (in pieces)`, 5, 265);
  pdfDoc.text(`269,904`, xFirstInput, 265);
  pdfDoc.text(`495,036`, xSecondInput, 265);
  pdfDoc.text(`507,468`, xThirdInput, 265);
  pdfDoc.text(`1,272,408`, xFourthInput, 265);

  pdfDoc.text(`% Efficiency Compliance`, 5, 270);
  pdfDoc.text(`96.82%`, xFirstInput, 270);
  pdfDoc.text(`95.29%`, xSecondInput, 270);
  pdfDoc.text(`95.51%`, xThirdInput, 270);
  pdfDoc.text(`95.70%`, xFourthInput, 270);
  pdfDoc.save(
    `Bread_Production_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default BreadProductionPDF;
