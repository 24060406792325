import { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";

import { useFetchDSRFormatProductListQuery } from "../../../store";
const TABLE_HEAD = [
  "Item Code",
  "Products",
  // "SL",
  "SOH in Pieces",
  "Reject in Pieces",
  // "Order",
  "Conversion",
  "Unit Measure",
];
const classes = "p-4 border-b border-blue-gray-50";

function DSRProdTbl({ onOrderChange, category }) {
  const { data, error, isLoading, isSuccess } =
    useFetchDSRFormatProductListQuery({
      category: category,
    });
  let [productActualQty, setProductActualQty] = useState([]);
  let [rejectQty, setRejectQty] = useState([]);
  let fetch_data_result = [];
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999 ? 9999 : value;

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: fetch_data_result[index].id,
      prodQty: qty - (parseInt(rejectQty[index]) || 0),
    }));

    onOrderChange(product_data);
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10) || 0;
    const MaxQty = productActualQty[index];

    // Ensure rejectQty is within bounds
    const newRejectQty = Math.min(inputValue, MaxQty);

    // Update the rejectQty state
    let updatedRejectQty = [...rejectQty];
    updatedRejectQty[index] = newRejectQty;
    setRejectQty(updatedRejectQty);

    // Calculate product_data using the updated rejectQty directly
    let product_data = productActualQty.map((qty, i) => ({
      prodId: fetch_data_result[i].id,
      prodQty: qty - (i === index ? newRejectQty : parseInt(rejectQty[i]) || 0),
    }));

    onOrderChange(product_data);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    fetch_data_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fetch_data_result.map((product, index) => (
              <tr key={index}>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                        component={"span"}
                      >
                        {product.product_code}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                        component={"span"}
                      >
                        {product.name
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    <div className="flex flex-col flex-grow">
                      <input
                        type="number"
                        value={productActualQty[index] || ""}
                        onChange={(e) =>
                          handleOrderChange(index, e.target.value)
                        }
                        className="w-20 border border-1 border-blue-gray-300 p-1"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    <div className="flex flex-col flex-grow">
                      <input
                        type="number"
                        value={rejectQty[index] || ""}
                        onChange={(e) => handleRejectQty(e, index)}
                        style={{
                          width: "80px",
                          border: "1px solid #ccc",
                          padding: "5px",
                        }}
                      />
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                        component={"span"}
                      >
                        {product.weight_box}
                      </Typography>
                    </div>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                        component={"span"}
                      >
                        {product.unit_measurement}
                      </Typography>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    );
  }
}

export default DSRProdTbl;
