import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const BevReportPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("BEVERAGES COMPLIANCE REPORT", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // Beverage types
  const beverages = [
    "COKE",
    "PEPSI",
    "SPRITE",
    "FANTA",
    "ROOT_BEER",
    "MDEW",
    "CAL",
    "MANGO",
    "SEVEN_UP",
    "TOTAL",
  ];

  // Beverage Data for all categories
  const beverageData = {
    "Beginning_Inventory (pcs)": [
      25272, 12684, 35664, 9204, 12792, 5534, 20130, 3612, 3336, 128228,
    ],
    "Purchases_from_Supplier (pcs)": [
      31200, 17760, 14400, 17400, 9600, 9600, 3600, 4320, 4320, 112200,
    ],
    "Return_from_Transport (pcs)": [0, 24, 36, 12, 91, 126, 12, 0, 301, 0],
    "Credits (pcs)": [0, 1, 1, 5, 0, 0, 7, 0, 0, 7],
    "Available_for_Issuance (pcs)": [
      56496, 30481, 50076, 26605, 22392, 15230, 23856, 7944, 7656, 240736,
    ],
    "Return_to_Supplier (pcs)": [1, 1, 0, 0, 0, 1, 0, 0, 0, 3],
    "Issuances_Load_out (pcs)": [
      30960, 15744, 26304, 14040, 13044, 7174, 9166, 3096, 2916, 122444,
    ],
    "Ending_Inventory (pcs)": [
      25536, 14736, 23772, 12564, 9348, 8055, 14690, 4848, 4740, 118289,
    ],
    "Actual_Ending_Inventory (pcs)": [
      25536, 14736, 23772, 12564, 9348, 8055, 14690, 4848, 4740, 118289,
    ],
    "Short_Over (pcs)": Array(beverages.length).fill("0"), // Assume short/over is 0 for now
  };

  // Column headers: Beverages
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("CATEGORY", 5, 30);
  beverages.forEach((beverage, index) => {
    const xOffset = 50 + index * 30; // Increased spacing between columns
    pdfDoc.text(beverage.replace(/_/g, " "), xOffset, 30); // Replace underscores with spaces
  });

  // Rows for each category
  Object.keys(beverageData).forEach((category, rowIndex) => {
    const yOffset = 40 + rowIndex * 10; // Position each row at a new Y-coordinate
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text(category.replace(/_/g, " "), 5, yOffset); // Replace underscores in category names
    pdfDoc.setFont("helvetica", "normal");

    // Loop through each beverage and print the corresponding data in each column
    beverageData[category].forEach((value, colIndex) => {
      const xOffset = 50 + colIndex * 30; // Increased X spacing for each value
      pdfDoc.text(value.toString(), xOffset, yOffset); // Output data for each beverage
    });
  });

  pdfDoc.save(
    `Beverages_Compliance_Report_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default BevReportPDF;
