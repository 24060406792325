import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const InvenFlowPattiesPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("INVENTORY FLOW OF PATTIES", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // Patty Varieties (as table headers)
  const patties = [
    "RP",
    "LP",
    "B1T1",
    "BBC-XL",
    "JSAUSAGE",
    "CHILICON",
    "CHX BBQ",
    "RBM",
  ];

  // Patty Data - Rows corresponding to each category
  const productionData = {
    "Beginning Inventory (pcs)": Array(patties.length).fill("-"), // Beginning inventory is not available
    "Production (pcs)": [
      43524, 329724, 194082, 29724, 22629, 541716, 21078, 3301,
    ],
    "Available for Issuance (pcs)": [
      43524, 329724, 194082, 29724, 22629, 541716, 21078, 3301,
    ],
    "Issuance to Foods (pcs)": [
      43524, 329724, 194082, 29724, 22629, 541716, 21078, 3301,
    ],
    "Issuance to TSD (pcs)": Array(patties.length).fill("-"),
    "Ending Inventory (pcs)": Array(patties.length).fill("-"),
  };

  // Column headers: Varieties of patties
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PRODUCTION OF PATTIES", 5, 30);
  patties.forEach((patty, index) => {
    const xOffset = 50 + index * 20; // Position headers with proper spacing
    pdfDoc.text(patty, xOffset, 30); // Variety names as headers
  });

  // Rows for each category
  Object.keys(productionData).forEach((category, rowIndex) => {
    const yOffset = 40 + rowIndex * 10; // Position each row at a new Y-coordinate
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text(category, 5, yOffset); // Category label (e.g., "Beginning Inventory", "Production")
    pdfDoc.setFont("helvetica", "normal");

    // Loop through each variety and print the corresponding data in each column
    productionData[category].forEach((value, colIndex) => {
      const xOffset = 50 + colIndex * 20; // Position each value in its column
      pdfDoc.text(value.toString(), xOffset, yOffset); // Output data for each variety
    });
  });

  // FOODS DUTY TO DELIVERIES section
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("FOODS DUTY TO DELIVERIES", 5, 100);
  patties.forEach((patty, index) => {
    const xOffset = 50 + index * 20;
    pdfDoc.text(patty, xOffset, 100); // Repeat headers for this section
  });

  // Foods Duty Data
  const foodsDutyData = {
    "Beginning Inventory (pcs)": [
      6840, 51084, 28266, 4464, 3384, 79284, 2670, 570,
    ],
    "Received from Patty Area (pcs)": [
      43524, 329724, 194082, 29724, 22629, 541716, 21078, 3301,
    ],
    "Excess Returns (pcs)": [84, 636, 282, 36, 39, 960, 42, 3],
    "Available for Issuance (pcs)": [
      50448, 381444, 222630, 34224, 26052, 621960, 23790, 3874,
    ],
    "Issuance to Transport (pcs)": [
      46416, 348576, 198990, 31314, 23907, 560268, 21432, 3459,
    ],
    "Ending Inventory (pcs)": [
      3960, 32484, 23130, 2880, 2127, 60888, 2340, 412,
    ],
  };

  // Rows for Foods Duty
  Object.keys(foodsDutyData).forEach((category, rowIndex) => {
    const yOffset = 110 + rowIndex * 10;
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text(category, 5, yOffset); // Print category labels
    pdfDoc.setFont("helvetica", "normal");

    foodsDutyData[category].forEach((value, colIndex) => {
      const xOffset = 50 + colIndex * 20; // Position each value in the proper column
      pdfDoc.text(value.toString(), xOffset, yOffset); // Output data for each category and variety
    });
  });

  // Final Inventory and Totals
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("ACTUAL INVENTORY", 5, 190);
  foodsDutyData["Ending Inventory (pcs)"].forEach((value, colIndex) => {
    const xOffset = 50 + colIndex * 20; // Final values for actual ending inventory
    pdfDoc.text(value.toString(), xOffset, 190);
  });
  pdfDoc.save(
    `Inventory_Flow_Of_Patties_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default InvenFlowPattiesPDF;
