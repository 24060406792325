import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const InvenFlowFlourPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCT INC.", 5, 10);

  // Header
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text(`INVENTORY FLOW OF FLOUR`, 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // RAW MATERIALS Section
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`RAW MATERIALS`, 5, 30);
  pdfDoc.text(`SOFT FLOUR`, xFirstInput, 30);
  pdfDoc.text(`HARD FLOUR`, xSecondInput, 30);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`Beginning inventory (per kilo)`, 7, 35);
  pdfDoc.text(`9,250.00`, xFirstInput, 35);
  pdfDoc.text(`14,375.00`, xSecondInput, 35);

  pdfDoc.text(`Purchases from suppliers (per kilo)`, 7, 40);
  pdfDoc.text(`10,000.00`, xFirstInput, 40);
  pdfDoc.text(`15,000.00`, xSecondInput, 40);

  pdfDoc.text(`Flour available (per kilo)`, 7, 45);
  pdfDoc.text(`19,250.00`, xFirstInput, 45);
  pdfDoc.text(`29,375.00`, xSecondInput, 45);

  pdfDoc.text(`Less: Issuances to Repacking (per kilo)`, 7, 50);
  pdfDoc.text(`12,125.00`, xFirstInput, 50);
  pdfDoc.text(`17,500.00`, xSecondInput, 50);

  pdfDoc.text(`Ending inventory (per kilo)`, 7, 55);
  pdfDoc.text(`7,125.00`, xFirstInput, 55);
  pdfDoc.text(`11,875.00`, xSecondInput, 55);

  pdfDoc.text(`Actual inventory (per kilo)`, 7, 60);
  pdfDoc.text(`7,125.00`, xFirstInput, 60);
  pdfDoc.text(`11,875.00`, xSecondInput, 60);

  pdfDoc.text(`TO BE ACCOUNTED (short)/over`, 5, 65);
  pdfDoc.text(`-`, xFirstInput, 65);
  pdfDoc.text(`-`, xSecondInput, 65);

  // Monitoring Section
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`MONITORING OF DECLARED WT VS. ACTUAL WT`, 5, 70);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`Declared Wt. (25 kilos per bag) (per kilo)`, 7, 75);
  pdfDoc.text(`12,125.00`, xFirstInput, 75);
  pdfDoc.text(`17,500.00`, xSecondInput, 75);

  pdfDoc.text(`Actual Weight per bag (per kilo)`, 7, 80);
  pdfDoc.text(`12,151.20`, xFirstInput, 80);
  pdfDoc.text(`17,445.41`, xSecondInput, 80);

  pdfDoc.text(`Variance (short)/over`, 7, 85);
  pdfDoc.text(`26.20`, xFirstInput, 85);
  pdfDoc.text(`(54.59)`, xSecondInput, 85);

  pdfDoc.text(`% of Actual Wt. to Declared Wt.`, 7, 90);
  pdfDoc.text(`100.22%`, xFirstInput, 90);
  pdfDoc.text(`99.69%`, xSecondInput, 90);

  // SIFTING FLOUR COMPLIANCE
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`SIFTING FLOUR COMPLIANCE`, 5, 100);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`Left Over Last Month (per kilo)`, 7, 105);
  pdfDoc.text(`1.80`, xFirstInput, 105);
  pdfDoc.text(`10.40`, xSecondInput, 105);

  pdfDoc.text(`Actual Weight per bag (per kilo)`, 7, 110);
  pdfDoc.text(`12,151.20`, xFirstInput, 110);
  pdfDoc.text(`17,445.41`, xSecondInput, 110);

  pdfDoc.text(`Total Available Flour For Sifting`, 7, 115);
  pdfDoc.text(`12,153.00`, xFirstInput, 115);
  pdfDoc.text(`17,455.81`, xSecondInput, 115);

  pdfDoc.text(`Actual Output (10/18 kls bag)`, 7, 120);
  pdfDoc.text(`12,154.00`, xFirstInput, 120);
  pdfDoc.text(`17,442.00`, xSecondInput, 120);

  pdfDoc.text(`Total Variance (short)/over`, 7, 125);
  pdfDoc.text(`1.00`, xFirstInput, 125);
  pdfDoc.text(`(13.81)`, xSecondInput, 125);

  pdfDoc.text(`Left Over this Month (per kilo)`, 7, 130);
  pdfDoc.text(`8.80`, xFirstInput, 130);
  pdfDoc.text(`12.90`, xSecondInput, 130);

  pdfDoc.text(`Unaccounted Variance on Sifting (per kilo)`, 7, 135);
  pdfDoc.text(`9.80`, xFirstInput, 135);
  pdfDoc.text(`(0.91)`, xSecondInput, 135);

  pdfDoc.text(`Sifted Flour Compliance (percentage)`, 7, 140);
  pdfDoc.text(`100.02%`, xFirstInput, 140);
  pdfDoc.text(`99.98%`, xSecondInput, 140);

  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`INVENTORY FLOW OF FLOUR`, 5, 145);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`RAW MATERIALS REPACKING`, 5, 150);

  pdfDoc.text(`Beginning inventory (per kilo)`, 7, 155);
  pdfDoc.text(`1,460.00`, xFirstInput, 155);
  pdfDoc.text(`2,628.00`, xSecondInput, 155);

  pdfDoc.text(`Raw mat transfers (per kilo)`, 7, 160);
  pdfDoc.text(`12,154.00`, xFirstInput, 160);
  pdfDoc.text(`17,442.00`, xSecondInput, 160);

  pdfDoc.text(`Flour available for repacking (per kilo)`, 7, 165);
  pdfDoc.text(`13,614.00`, xFirstInput, 165);
  pdfDoc.text(`20,070.00`, xSecondInput, 165);

  pdfDoc.text(`Less: Issuance to Bakery (per kilo)`, 7, 170);
  pdfDoc.text(`10,710.00`, xFirstInput, 170);
  pdfDoc.text(`19,278.00`, xSecondInput, 170);

  pdfDoc.text(`Ending inventory (per kilo)`, 7, 175);
  pdfDoc.text(`440.00`, xFirstInput, 175);
  pdfDoc.text(`792.00`, xSecondInput, 175);

  pdfDoc.text(`Actual inventory (per kilo)`, 7, 180);
  pdfDoc.text(`440.00`, xFirstInput, 180);
  pdfDoc.text(`792.00`, xSecondInput, 180);

  pdfDoc.text(`TO BE ACCOUNTED (short)/over`, 5, 185);
  pdfDoc.text(`-`, xFirstInput, 185);
  pdfDoc.text(`-`, xSecondInput, 185);

  // Flour Usage Section
  pdfDoc.setTextColor(0, 0, 255);
  pdfDoc.text(`FLOUR USAGE`, 5, 190);
  pdfDoc.setTextColor(0);
  pdfDoc.text(`Beginning inventory of flour (per kilo)`, 7, 195);
  pdfDoc.text(`-`, xFirstInput, 195);
  pdfDoc.text(`-`, xSecondInput, 195);

  pdfDoc.text(`Received flour from repacking (per kilo)`, 7, 200);
  pdfDoc.text(`10,710.00`, xFirstInput, 200);
  pdfDoc.text(`19,278.00`, xSecondInput, 200);

  pdfDoc.text(`Flour available (per kilo)`, 7, 205);
  pdfDoc.text(`10,710.00`, xFirstInput, 205);
  pdfDoc.text(`19,278.00`, xSecondInput, 205);

  pdfDoc.text(`Usage (per kilo) BAKERY`, 7, 210);
  pdfDoc.text(`10,710.00`, xFirstInput, 210);
  pdfDoc.text(`19,278.00`, xSecondInput, 210);

  pdfDoc.text(`Ending inventory of flour (per kilo)`, 7, 215);
  pdfDoc.text(`-`, xFirstInput, 215);
  pdfDoc.text(`-`, xSecondInput, 215);

  pdfDoc.text(`TO BE ACCOUNTED (short)/over`, 7, 220);
  pdfDoc.text(`-`, xFirstInput, 220);
  pdfDoc.text(`-`, xSecondInput, 220);

  pdfDoc.save(
    `Inventory_Flow_Of_Flour_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default InvenFlowFlourPDF;
