import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import PageTitle from "../page_components/PageTitle";
import moment from "moment";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import RawMaterialsReceipt from "../modal_form_components/Warehouse/WarehouseDeliveryRawMats/RawMaterialsReceipt";
import PrintRR from "../modal_form_components/Warehouse/WarehouseDeliveryRawMats/PrintRR";
import { useRawMatListPageQuery } from "../../store";
import { HashLoader } from "react-spinners";
import { HiOutlineDocumentReport } from "react-icons/hi";
import ReceivingExtractPO from "../modal_form_components/Warehouse/WarehouseDeliveryRawMats/ReceivingExtractPO";
import ViewFileReceivingReport from "../modal_form_components/Warehouse/WarehouseDeliveryRawMats/ViewFileReceivingReport";

function Raw_Mats_Delivery() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  let content;

  const filterStatus = [
    {
      label: "On queue",
      value: 1,
    },
    {
      label: "Completed",
      value: 2,
    },
  ];


  const [selectedStatus, setSelectedStatus] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [orderDir] = useState("asc");

  // fetching po with a status of raw_mats

  // ON_QUEUE = 1 SUBMITTED = 2
  const TABLE_HEAD = ["PO Number"];
  if (selectedStatus === 1){
    TABLE_HEAD.push("Delivery Date")
  }else{
    TABLE_HEAD.push("Date Delivered")
  }
  TABLE_HEAD.push("Status")
  TABLE_HEAD.push("Action")

  let fetch_raw_mat = useRawMatListPageQuery({
    page: currentPage,
    page_size: pageSize,
    order_by: "id",
    order_dir: orderDir,
    status: selectedStatus,
  });

  useEffect(() => {
    if (
      account_detailed.job_position === 1 ||
      account_detailed.job_position === 5 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 14 ||
      account_detailed.job_position == 5
    ) {
      //pass
    } else {
      navigate("/base/dashboard");
      return;
    }
    fetch_raw_mat.refetch();
  }, [token]);

  // total page for purchase order
  const POTotalPage = Math.ceil(fetch_raw_mat.data?.count / pageSize);

  // function for previous page
  const handlePreviousPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  // function for next page
  const handleNextPage = () => {
    if (currentPage === POTotalPage) return;
    setCurrentPage(currentPage + 1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedStatus(value);
    fetch_raw_mat.refetch();
  };

  if (fetch_raw_mat.isLoading) {
    content = <HashLoader />;
  } else if (fetch_raw_mat.error) {
    content = <div>Error Loading...</div>;
  } else {
    let result = fetch_raw_mat.data.results;

    console.log(result)
    content = (
      <>
        <Card className="h-full w-[95%] md:w-full mx-auto md:mx-0">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="md:flex items-center justify-between gap-8 mt-2">
              <div className="mr-3 lg:mr-0 md:w-72 lg:w-72 w-full">
                <Input label="Search" icon={<FiSearch className="h-5 w-5" />} />
              </div>
              <div className="flex mt-10 md:mt-0">
                <Button
                  className="flex items-center gap-3"
                  variant="outlined"
                  size="sm"
                >
                  <FiDownload strokeWidth={2} className="h-4 w-4" /> Download
                </Button>
              </div>
            </div>
            <div className="flex flex-col mt-4 items-center justify-between gap-4 md:flex-row">
              <Tabs value={selectedStatus} className="w-full md:w-[70%]">
                <TabsHeader>
                  {filterStatus.map(({ label, value }) => (
                    <Tab
                      className="text-sm"
                      key={value}
                      value={value}
                      onClick={() => handleTabChange(value)}
                    >
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            </div>
          </CardHeader>
          <CardBody className="overflow-scroll px-0">
            <table className="mt-2 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.map((rst, i) => {
                  let status;
                  if (rst.status === 1) {
                    status = "On queue";
                  } else if (rst.status === 2) {
                    status = "Completed";
                  }
                  const isLast = i === result.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";
                  return (
                    <tr key={i}>
                      <td className={classes}>{rst.po_number}</td>
                      {status === "On queue" ? (
                        <td className={classes}>{
                          moment(
                            new Date(
                              rst.delivery_date
                            )
                          ).format("YYYY-MM-DD")}</td>
                      ): (
                        <td className={classes}>{
                          moment(
                            new Date(
                              rst.submitted_date
                            )
                          ).format("YYYY-MM-DD")}</td>
                      )}
                      <td className={classes}>{status}</td>
                      <td className={classes}>
                        {status === "On queue" ? (
                          <div className="flex justify-center gap-5 lg:gap-0">
                            <RawMaterialsReceipt
                              vrr_id={rst.id}
                              refetch_receiving={fetch_raw_mat.refetch}
                            />{" "}
                            <ReceivingExtractPO vrr_id={rst.id} />{" "}
                          </div>
                        ) : (
                          <div className="flex justify-center gap-2 lg:gap-0 pr-2 md:pr-0">
                            <PrintRR vrr_id={rst.id} />
                            <ViewFileReceivingReport file={rst.file} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page {currentPage} of {POTotalPage}
            </Typography>
            <div className="flex space-x-5 lg:space-x-2">
              <Button variant="outlined" size="sm" onClick={handlePreviousPage}>
                Previous
              </Button>
              <Button variant="outlined" size="sm" onClick={handleNextPage}>
                Next
              </Button>
            </div>
          </CardFooter>
        </Card>
      </>
    );
  }

  return (
    <>
      <div className="container mx-auto lg:px-4">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
          <div className="flex items-center space-x-2">
            <HiOutlineDocumentReport className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Receiving Report
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div className=" px-0 lg:px-5 mt-5">{content}</div>
      </div>
    </>
  );
}

export default Raw_Mats_Delivery;
