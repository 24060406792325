import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
  CheckCircle,
  Circle,
  Checkbox,
} from "@material-tailwind/react";
import { useFetchFCListQuery } from "../../../store";
import { useFetchRouteListQuery } from "../../../store";
import { useUpdateOutletDetailsMutation } from "../../../store";
import { useUpdateOutletPasswordMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { ConfirmationV2 } from "../Error_trap_components/ErrorTrapComponentV2";
import { ToastContainer, toast } from "react-toastify";
const trapMessage = "Are you sure you want to submit these outlet changes? ";
const buttonName = "Update Password";
const tableHead = ["", ""];
const trapMessageUpdatePassword =
  "Are you sure you want to reset this outlet username and password? ";
function EditOutletModal({ outletData, refetchMainTable }) {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [outletName, setOutletName] = useState(outletData.outlet);
  const [dealerNmae, setDelearName] = useState("");
  const [address, setAdress] = useState(outletData.address);
  const [isActive, setIsActive] = useState(outletData.is_active.toString());
  const [selectedFC, setSelectedFC] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isRouteDialogOpen, setIsRouteDialogOpen] = useState(false);
  const [isFCDialogOpen, setIsFCDialogOpen] = useState(false);

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    console.log(outletData);
    const anyArrayHasData =
      selectedFC !== "" &&
      selectedRoute !== "" &&
      address !== "" &&
      outletName != "";

    // Update isReadyToSave based on the condition
    setIsReadyToSave(anyArrayHasData);
  }, [selectedFC, selectedRoute, address, outletName]);

  useEffect(() => {
    if (selectedFC === "") {
      setSelectedFC(outletData.fc.id);
    }

    if (selectedRoute === "") {
      setSelectedRoute(outletData.route.id);
    }
  }, [selectedFC, selectedRoute, outletData.fc.id]);

  const {
    data: fcData,
    error: fcError,
    isLoading: fcLoading,
    isSuccess: fcSuccess,
  } = useFetchFCListQuery();
  const {
    data: routeData,
    error: routeError,
    isLoading: loadingRoute,
    isSuccess: routeSucess,
  } = useFetchRouteListQuery({
    page: 1,
    page_size: 50,
    schedule: 1,
  });

  const handleFCChange = (value) => {
    const fcId = parseInt(value, 10);
    setSelectedFC(fcId);
    setIsFCDialogOpen(false);
  };

  const handleRouteChange = (value) => {
    const rID = parseInt(value, 10);
    setSelectedRoute(rID);
    setIsRouteDialogOpen(false);
  };
  function handleOutletChange(event) {
    const newValue = event.target.value;
    setOutletName(newValue);
  }
  function handleDealerChange(event) {
    const newValue = event.target.value;
    setDelearName(newValue);
  }
  function handleAddressChange(event) {
    const newValue = event.target.value;
    setAdress(newValue);
  }

  function handleStatusChange(value) {
    setIsActive(value);
  }

  const handleClose = () => {
    setOpen(false);
    setSelectedRoute("");
    setSelectedFC("");
  };

  const handleOpenRouteDialog = () => {
    // Your logic to handle opening the dialog
    setIsRouteDialogOpen(true);
  };

  const handleOpenFCDialog = () => {
    // Your logic to handle opening the dialog
    setIsFCDialogOpen(true);
  };
  const [updateUserName] = useUpdateOutletPasswordMutation();
  const [saveOutlets] = useUpdateOutletDetailsMutation();

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    let allData = {
      outlet: outletData.id,
      user: account_detailed.user.id,
    };
    console.log(allData);
    try {
      const sea = await updateUserName(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        handleClose();
        setIsReadyToSave(false);
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    let allData = {
      outlet: outletData.id,
      fc: selectedFC,
      route: selectedRoute,
      o_name: outletName,
      address: address,
      user: account_detailed.user.id,
      is_active: isActive,
    };
    console.log(allData);
    try {
      const sea = await saveOutlets(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        handleClose();
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  const handleDialogClose = () => {
    setIsRouteDialogOpen(false);
    setIsFCDialogOpen(false);
  };
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Edit
      </Button>

      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Modify Outlet Form
          </Typography>
        </DialogHeader>
        <DialogBody>
          <form>
            <div className="grid grid-cols-2 gap-5 mb-10">
              <div className="space-y-5">
                {routeSucess && (
                  // <Select
                  // label="Route"
                  // onChange={handleRouteChange}
                  // value={selectedRoute ? `${selectedRoute}` : null}
                  // variant="outlined"
                  // >
                  // {routeData.results.map((route) => (
                  //     <Option key={route.id} value={`${route.id}`}>
                  //     {route.name}
                  //     </Option>
                  // ))}
                  // </Select>

                  <>
                    <Input
                      label="Route"
                      value={
                        selectedRoute
                          ? routeData.results.find(
                              (route) => route.id === selectedRoute
                            )?.name || ""
                          : ""
                      }
                      onClick={handleOpenRouteDialog}
                      readOnly
                    />
                    <Dialog
                      size="sm"
                      open={isRouteDialogOpen}
                      handler={handleDialogClose}
                      className="relative max-h-min"
                    >
                      <DialogHeader>
                        <Typography className="text-green-900" variant="h3">
                          Select Route
                        </Typography>
                      </DialogHeader>
                      <DialogBody className="overflow-y-auto max-h-[50vh]">
                        <table className="w-full min-w-max table-auto text-left">
                          <thead>
                            <tr>
                              {tableHead.map((head, index) => (
                                <th
                                  key={index}
                                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {routeData.results.map((route) => {
                              const classes =
                                "p-4 border-b border-blue-gray-50";
                              return (
                                <tr key={route.id}>
                                  <td className={classes}>
                                    <Checkbox
                                      type="checkbox"
                                      color="teal"
                                      checked={selectedRoute === route.id}
                                      onChange={() => {
                                        handleRouteChange(route.id);
                                      }}
                                    />
                                  </td>
                                  <td className={classes}>{route.name}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </DialogBody>
                      {/* <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        onClick={handleDialogClose}
                        className="mr-1"
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={handleDialogClose}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter> */}
                    </Dialog>
                  </>
                )}
                <Select
                  label="Outlet Status"
                  onChange={handleStatusChange}
                  value={isActive}
                  variant="outlined"
                >
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
                {/* <Input
                  label="Dealer Name"
                  name="dealerName"
                  onChange={handleDealerChange}
                  // Add value and onChange handler
                /> */}
              </div>
              <div className="space-y-5">
                <Input
                  label="Address"
                  name="address"
                  onChange={handleAddressChange}
                  value={address}
                />
                {fcSuccess && (
                  <>
                    <Input
                      label="FC"
                      value={
                        selectedFC
                          ? fcData.data.find((fc) => fc.id === selectedFC)
                              ?.name || ""
                          : ""
                      }
                      onClick={handleOpenFCDialog}
                      readOnly
                    />
                    <Dialog
                      size="sm"
                      open={isFCDialogOpen}
                      handler={handleDialogClose}
                      className="relative max-h-min"
                    >
                      <DialogHeader>
                        <Typography className="text-green-900" variant="h3">
                          Select FC
                        </Typography>
                      </DialogHeader>
                      <DialogBody className="overflow-y-auto max-h-[50vh]">
                        <table className="w-full min-w-max table-auto text-left">
                          <thead>
                            <tr>
                              {tableHead.map((head, index) => (
                                <th
                                  key={index}
                                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                                >
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                  >
                                    {head}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {fcData.data.map((fc) => {
                              const classes =
                                "p-4 border-b border-blue-gray-50";
                              return (
                                <tr key={fc.id}>
                                  <td className={classes}>
                                    <Checkbox
                                      type="checkbox"
                                      color="teal"
                                      checked={selectedRoute === fc.id}
                                      onChange={() => {
                                        handleFCChange(fc.id);
                                      }}
                                    />
                                  </td>
                                  <td className={classes}>{fc.name}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </DialogBody>
                    </Dialog>
                  </>
                )}
              </div>
            </div>
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          <ConfirmationV2
            onConfirm={handleUpdatePassword}
            trapMessage={trapMessageUpdatePassword}
            isReadyToSave={true}
            buttonName={buttonName}
          />
          <Confirmation
            onConfirm={handleSave}
            trapMessage={trapMessage}
            isReadyToSave={isReadyToSave}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3 ml-5"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default EditOutletModal;
