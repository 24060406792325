import React from "react";
import Lottie from "lottie-react";
import loaderAnimation from "./animations/truckAnimation.json";

const MiniTruckLoader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="loader-container max-w-[150px] max-h-[150px]">
        <Lottie animationData={loaderAnimation} loop={true} />
      </div>
    </div>
  );
};

export default MiniTruckLoader;
