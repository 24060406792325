import React from "react";
import Lottie from "lottie-react";
import loaderAnimation from "./animations/truckAnimation.json";

const TruckLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="loader-container">
        <Lottie animationData={loaderAnimation} loop={true} />
      </div>
    </div>
  );
};

export default TruckLoader;
