import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Select,
  Option,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { useFetchDeliveryListPageQuery } from "../../store";
import WithdrawModal2 from "../modal_form_components/Delivery/WithdrawalFrm";
import GenDelReceiptModal from "../modal_form_components/Delivery/GenDelReceipt";
import DeliveryReceiptForm from "../modal_form_components/Delivery/DeliveryReceiptForm";
import AddDeliveryModal from "../modal_form_components/Delivery/AddDelivery";
import OrderSummaryPdf from "../form_components/pdf/GenerateOrderPdf";
import OrderSummaryReceiipt from "../modal_form_components/Delivery/OrderCompletedSummary";
import DelCalendar from "../form_components/calendar/DeliveryCalendar";
import DelSummaryPdf from "../form_components/pdf/GenerateTransferDeliveryPdf";
import DelSummaryExcel from "../form_components/excel/GenerateDelSummaryExcel";
import SOHDailyModal from "../modal_form_components/Delivery/SOHDailyReport";
import ManualRequestOrderModal from "../modal_form_components/Delivery/ManualRequestOrder";
import OutletModal from "../modal_form_components/Delivery/Outlet";
import WithdrawModal from "../modal_form_components/Delivery/WithdrawalForm";
import AuditSOHModal from "../modal_form_components/Delivery/AuditSOHDailyReport";
import AddRouteFrm from "../modal_form_components/Delivery/AddRouteForm";
import TruckLoader from "../reusable/truckLoader";
import MiniTruckLoader from "../reusable/miniTruckLoader";
import DelSchedulePicker from "../form_components/calendar/DeliverySelectDelDate";
import ManualReturnDeliveryModal from "../modal_form_components/Delivery/ManualReturnDel";

export function CompleteTableView({ TABLE_HEAD }) {
  let result = [];
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [currentOutlet, setCurrentOutlet] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const columnMapping = {
    "Order Number": "order_number",
    Outlet: "outlet",
    Route: "outlet__route",
    "Order Date": "order_date",
    Status: "status",
  };

  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  const filterStatus = [];

  if (account_detailed.job_position === 15) {
    filterStatus.push(
      {
        label: "In-Transit",
        value: 4,
      },
      {
        label: "Partially Completed",
        value: 7,
      },
      {
        label: "Completed",
        value: 5,
      }
    );
  } else if (account_detailed.job_position === 16) {
    filterStatus.push(
      {
        label: "In-Transit",
        value: 4,
      },
      {
        label: "Partially Completed",
        value: 7,
      },
      {
        label: "Completed",
        value: 5,
      },
      {
        label: "Return",
        value: 6,
      }
    );
  } else {
    filterStatus.push(
      {
        label: "On Queue",
        value: 1,
      },
      {
        label: "For Approval",
        value: 2,
      },
      {
        label: "For Delivery",
        value: 3,
      },
      {
        label: "In-Transit",
        value: 4,
      },
      {
        label: "Partially Completed",
        value: 7,
      },
      {
        label: "Completed",
        value: 5,
      },
      {
        label: "Return",
        value: 6,
      }
    );
  }

  const { data, error, isLoading, isSuccess, refetch } =
    useFetchDeliveryListPageQuery({
      page: currentPage,
      page_size: pageSize,
      search: searchTerm,
      status: selectedStatus,
      order_by: sortConfig.key,
      order_dir: sortConfig.direction,
      outlet: currentOutlet,
    });

  useEffect(() => {
    if (
      account_detailed.job_position == 16 ||
      account_detailed.job_position == 1 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position == 15 ||
      account_detailed.job_position == 5
    ) {
      if (account_detailed.job_position == 15) {
        setCurrentOutlet(account_detailed.outlet);
        if (selectedStatus === 1) {
          setSelectedStatus(4);
        }
      }
      if (account_detailed.job_position == 16) {
      }
      // pass
    } else {
      return;
    }
  }, [token, account_detailed]);

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 5000);

      return () => clearTimeout(timer);
    } else {
      setShowLoader(false);
    }
  }, [isLoading]);

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "On Queue";
      case 2:
        return "For Approval";
      case 3:
        return "For Delivery";
      case 4:
        return "In Transit";
      case 5:
        return "Completed";
      case 6:
        return "Return";
      case 7:
        return "Partially Completed";
      default:
        return "";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1: // On Queue
        return { color: "text-black", bgColor: "bg-yellow-500" };
      case 2: // For Approval
        return { color: "text-black", bgColor: "bg-orange-500" };
      case 3: // For Delivery
        return { color: "text-white", bgColor: "bg-gray-500" };
      case 4: // In Transit
        return { color: "text-white", bgColor: "bg-blue-500" };
      case 5: // Completed
        return { color: "text-white", bgColor: "bg-green-500" };
      case 6: // Return
        return { color: "text-white", bgColor: "bg-red-500" };
      case 7: // Return
        return { color: "text-white", bgColor: "bg-purple-500" };
      default:
        return { color: "", bgColor: "" };
    }
  };

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  const handleSort = (column) => {
    let direction = "asc";
    if (
      sortConfig.key === columnMapping[column] &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnMapping[column], direction });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedStatus(value);
    refetch();
  };

  if (showLoader) {
    return <TruckLoader />;
  }

  if (isSuccess) {
    result = data.results;
  }
  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none mr-5">
        <div></div>
        <div className="mb-8 flex flex-col lg:flex-row lg:items-center items-start justify-end gap-8 mt-5">
          {account_detailed.job_position !== 15 &&
            account_detailed.job_position !== 16 && (
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {account_detailed.job_position === 1 && <DelSchedulePicker />}
                {account_detailed.job_position === 1 && <AddRouteFrm />}
                <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                  <AddDeliveryModal refetchDeliveryList={refetch} />
                  <ManualReturnDeliveryModal refetechMaintable={refetch} />
                  {/* <ManualRequestOrderModal refetchDeliveryList={refetch} /> */}
                </div>
                {/* <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                  <OutletTabModal />
                </div> */}
              </div>
            )}
          {/* if job ===15 hide this div */}

          {/* {account_detailed.job_position !== 1 && account_detailed.job_position !== 13 && ( */}

          {account_detailed.job_position !== 16 &&
            account_detailed.job_position !== 4 &&
            account_detailed.job_position !== 2 &&
            account_detailed.job_position !== 5 && (
              <>
                {" "}
                <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                  <SOHDailyModal refetchDeliveryList={refetch} />
                  <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                    <WithdrawModal2 />
                  </div>
                </div>
              </>
            )}
        </div>

        <div className="flex justify-start mb-5">
          <div className="w-full md:w-72">
            <div className="col-span-2 space-y-5">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <Tabs value={selectedStatus} className="w-[80%]">
            <TabsHeader>
              {filterStatus.map(({ label, value }) => (
                <Tab
                  className="text-sm"
                  key={value}
                  value={value}
                  onClick={() => handleTabChange(value)}
                >
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
          <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
            <Typography className=" font-medium text-sm">Page size:</Typography>
            <select
              className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(e)}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
            </select>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                  onClick={() => handleSort(head)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="small"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  <div className="flex justify-center items-center">
                    <TruckLoader />
                  </div>
                </td>
              </tr>
            ) : result.length > 0 ? (
              result.map((d, index) => {
                const { color, bgColor } = getStatusColor(d.status);
                const statusText = getStatusText(d.status);
                const isLast = index === result.length;
                const classes = isLast
                  ? "p-3"
                  : "p-3 border-b border-blue-gray-50";
                const formattedDate = d.expected_delivery_date
                  ? new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(d.expected_delivery_date))
                  : "";
                return (
                  <tr key={d.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-1">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {d.order_number !== null &&
                            d.order_number !== undefined
                              ? d.order_number
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {d.delivery_tracking_number &&
                            d.delivery_tracking_number.delivery_number !==
                              null &&
                            d.delivery_tracking_number.delivery_number !==
                              undefined
                              ? d.delivery_tracking_number.delivery_number
                              : "TBD"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {d.outlet ? d.outlet.outlet : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {d.outlet ? d.outlet.route.name : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex gap-2">
                        <div
                          className={`relative grid select-none items-center whitespace-nowrap rounded-lg py-1.5 px-3 font-sans text-xs font-bold uppercase ${color} ${bgColor}`}
                        >
                          <span>{statusText}</span>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center justify-center gap-3">
                        <div className="flex flex-col items-center">
                          {d.status === 4 ? (
                            account_detailed.job_position === 15 ? (
                              <div className="flex gap-2">
                                <DeliveryReceiptForm
                                  refetchMainTable={refetch}
                                  getOrderId={d.id}
                                  delData={d}
                                />
                              </div>
                            ) : (
                              <div className="flex gap-2">
                                <DeliveryReceiptForm
                                  refetchMainTable={refetch}
                                  getOrderId={d.id}
                                  delData={d}
                                />
                                <div className="border border-transparent bg-transparent">
                                  <p className="text-sm font-semibold text-gray-700">
                                    Summary
                                  </p>
                                  <div className="flex gap-2">
                                    <DelSummaryExcel
                                      getDelId={d.delivery_tracking_number.id}
                                    />
                                    <DelSummaryPdf
                                      getDelId={d.delivery_tracking_number.id}
                                    />
                                  </div>
                                </div>
                                <OrderSummaryPdf
                                  getDeliverId={d.delivery_tracking_number.id}
                                  isPDFReady={true}
                                  refetchMainTable={refetch}
                                  CloseModal={false}
                                />
                              </div>
                            )
                          ) : d.status === 3 &&
                            account_detailed.job_position !== 15 ? (
                            <GenDelReceiptModal
                              refetchMainTable={refetch}
                              getDeliverId={d.delivery_tracking_number.id}
                              delData={d}
                            />
                          ) : d.status === 1 || d.status === 2 ? (
                            <span>Pending...</span>
                          ) : d.status === 5 ? (
                            <OrderSummaryReceiipt
                              getOrderId={d.id}
                              delData={d}
                            />
                          ) : d.status === 6 ? (
                            <span>In Progress...</span>
                          ) : d.status === 7 ? (
                            <OrderSummaryReceiipt
                              getOrderId={d.id}
                              delData={d}
                            />
                          ) : null}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4">
                  <div className="flex justify-center items-center">
                    <div className="h-16 w-16">
                      {/* Adjust dimensions for the loader */}
                      <MiniTruckLoader />
                    </div>
                  </div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal mt-2"
                  >
                    {searchTerm && searchTerm.trim() !== ""
                      ? `No data found for "${searchTerm}"`
                      : "No available data"}
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            size="sm"
            onClick={handlePreviousPage}
            disabled={!data?.previous}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={handleClickRefresh}
            disabled={isRefreshing}
          >
            Refresh
          </Button>

          <Button
            variant="outlined"
            size="sm"
            onClick={handleNextPage}
            disabled={!data?.next}
          >
            {data?.next ? "Next" : "No More Pages"}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
