import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const BreadCrumbsReportPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("BREAD CRUMBS COMPLIANCE REPORT", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // Beginning Inventory
  pdfDoc.text("BEGINNING INVENTORY", 5, 25);
  pdfDoc.text("203.25", xFourthInput, 25); // Adjust the positioning to match previous columns

  // Add: Bakery Reject
  pdfDoc.text("ADD: BAKERY REJECT", 5, 30);
  pdfDoc.text("RB", xFirstInput, 30);
  pdfDoc.text("JB", xSecondInput, 30);
  pdfDoc.text("MB", xThirdInput, 30);
  pdfDoc.text("MB ENDS", xFourthInput, 30);

  pdfDoc.text("PCS.", 5, 35);
  pdfDoc.text("24,444.00", xFirstInput, 35);
  pdfDoc.text("8,876.00", xSecondInput, 35);
  pdfDoc.text("23,861.00", xThirdInput, 35);
  pdfDoc.text("41,501.00", xFourthInput, 35);

  pdfDoc.text("KLS.", 5, 40);
  pdfDoc.text("879.50", xFirstInput, 40);
  pdfDoc.text("459.00", xSecondInput, 40);
  pdfDoc.text("467.85", xThirdInput, 40);
  pdfDoc.text("724.20", xFourthInput, 40);

  pdfDoc.text("ave. wt./pc.", 5, 45);
  pdfDoc.text("0.0360", xFirstInput, 45);
  pdfDoc.text("0.0517", xSecondInput, 45);
  pdfDoc.text("0.0196", xThirdInput, 45);
  pdfDoc.text("0.017", xFourthInput, 45);

  // Foods Reject
  pdfDoc.text("FOODS REJECT", 5, 50);
  pdfDoc.text("RB", xFirstInput, 50);
  pdfDoc.text("JB", xSecondInput, 50);
  pdfDoc.text("MB", xThirdInput, 50);

  pdfDoc.text("PCS.", 5, 55);
  pdfDoc.text("276.00", xFirstInput, 55);
  pdfDoc.text("132.00", xSecondInput, 55);
  pdfDoc.text("204.00", xThirdInput, 55);

  pdfDoc.text("KLS.", 5, 60);
  pdfDoc.text("10.79", xFirstInput, 60);
  pdfDoc.text("7.26", xSecondInput, 60);
  pdfDoc.text("3.82", xThirdInput, 60);
  pdfDoc.text("21.87", xFourthInput, 60);

  pdfDoc.text("ave. wt./pc.", 5, 65);
  pdfDoc.text("0.0391", xFirstInput, 65);
  pdfDoc.text("0.0550", xSecondInput, 65);
  pdfDoc.text("0.0187", xThirdInput, 65);

  // Withdrawals
  pdfDoc.text("WITHDRAWALS", 5, 70);
  pdfDoc.text("RB", xFirstInput, 70);
  pdfDoc.text("JB", xSecondInput, 70);
  pdfDoc.text("MB", xThirdInput, 70);

  pdfDoc.text("PCS.", 5, 75);
  pdfDoc.text("16,209.00", xFirstInput, 75);
  pdfDoc.text("17,057.00", xSecondInput, 75);
  pdfDoc.text("12,732.00", xThirdInput, 75);

  pdfDoc.text("KLS.", 5, 80);
  pdfDoc.text("634.14", xFirstInput, 80);
  pdfDoc.text("919.34", xSecondInput, 80);
  pdfDoc.text("237.26", xThirdInput, 80);
  pdfDoc.text("1,790.74", xFourthInput, 80);

  pdfDoc.text("ave. wt./pc.", 5, 85);
  pdfDoc.text("0.0391", xFirstInput, 85);
  pdfDoc.text("0.0539", xSecondInput, 85);
  pdfDoc.text("0.0186", xThirdInput, 85);

  // Total Available Bread for Process
  pdfDoc.text("TOTAL AVAILABLE BREAD FOR PROCESS", 5, 90);
  pdfDoc.text("4,546.41", xFourthInput, 90);

  // Bread Disposal and Processed
  pdfDoc.text("LESS: BREAD DISPOSAL", 5, 95);
  pdfDoc.text("925.00", xFourthInput, 95);

  pdfDoc.text("BREAD CRUMBS PROCESS", 5, 100);
  pdfDoc.text("3,410.81", xFourthInput, 100);

  // Ending Inventory
  pdfDoc.text("ENDING INVENTORY", 5, 105);
  pdfDoc.text("210.60", xFourthInput, 105);

  pdfDoc.text("ACTUAL INVENTORY", 5, 110);
  pdfDoc.text("210.60", xFourthInput, 110);

  // Short/Over Compliance
  pdfDoc.text("SHORT/OVER - BREAD CRUMBS COMPLIANCE", 5, 115);
  pdfDoc.text("BREAD PROCESS (KILOS)", 5, 120);
  pdfDoc.text("3,410.81", xFourthInput, 120);

  pdfDoc.text("BREAD CRUMBS OUTPUT (KILOS)", 5, 125);
  pdfDoc.text("2,660.00", xFourthInput, 125);

  pdfDoc.text("% COMPLIANCE", 5, 130);
  pdfDoc.text("77.99%", xFourthInput, 130);
  pdfDoc.save(
    `Bread_Crumbs_Compliance_Report_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default BreadCrumbsReportPDF;
