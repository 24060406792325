import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "@material-tailwind/react";
import { useFetchDeliveryOrdersSummaryQuery } from "../../../store/apis/DeliveryApi";
import { FaFilePdf } from "react-icons/fa";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const OrderSummaryPdf = ({
  getDeliverId,
  isPDFReady,
  handleMainClose,
  refetchMainTable,
  CloseModal,
}) => {
  const [hasTriggeredRefetch, setHasTriggeredRefetch] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const { data, isError, isLoading, isSuccess, refetch } =
    useFetchDeliveryOrdersSummaryQuery({
      del_tracking_num: getDeliverId,
    });

  useEffect(() => {
    if (isPDFReady && !hasTriggeredRefetch) {
      refetch();
      setHasTriggeredRefetch(true);
    }
  }, [isPDFReady, hasTriggeredRefetch, refetch]);

  const generatePDF = (result) => {
    setIsPDFLoading(true);

    const currentDate = new Date();
    const date_now = currentDate.toLocaleDateString("en-US", {
      timeZone: "Asia/Tokyo",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const categoryMap = {
      1: "Product",
      2: "Beverages",
      3: "Buns",
      4: "Wrappers",
      5: "Sundries",
      6: "Cigarettes",
      7: "Office Supp.",
      8: "Tank",
    };

    const pdfWidth = 215.9; // Width in millimeters (A4 size)
    const pdfHeight = 279.4; // Height in millimeters (custom length)

    // Create a new jsPDF instance with custom page size
    const pdfDoc = new jsPDF({
      orientation: "portrait", // Landscape orientation
      unit: "mm", // units of measurement
      format: [pdfWidth, pdfHeight], // specify the page size
    });

    // Add logo and title
    // pdfDoc.addImage("/images/burger_machine_logo.png", "PNG", 20, 10, 50, 0);
    pdfDoc.setFontSize(20);
    pdfDoc.setTextColor(0, 128, 0);
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text("PREMIUM BURGER & FOOD PRODUCT INC.", 30, 25);
    pdfDoc.setFontSize(10);
    pdfDoc.setTextColor(0);
    pdfDoc.setFont("helvetica", "normal");
    pdfDoc.text(
      `1185 TOPAZ ST. RAMAR VILLAGE SAN AGUSTIN CITY OF SAN FERNANDO PAMPANGA`,
      29,
      30
    );
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text(`OUTLET ORDER RECEIPT`, 75, 35);
    pdfDoc.setFont("helvetica", "normal");
    // Add delivery information
    pdfDoc.text(`Delivery Date: ${date_now}`, 20, 45);
    pdfDoc.text(
      `Delivery Number: ${result.delivery_tracking_number.delivery_number}`,
      20,
      50
    );
    pdfDoc.text(`Order Number: ${result.order_number}`, 20, 55);
    pdfDoc.text(`Address: ${result.outlet.address}`, 20, 60);
    pdfDoc.text(`Route: ${result.outlet.route.name}`, 120, 50);
    pdfDoc.text(`Outlet: ${result.outlet.outlet}`, 120, 55);

    const tableStartY = 65;
    const startY = 65;
    const startX = 30; // X-coordinate for the first table
    const startX2 = 113; // X-coordinate for the second table
    const columnWidth = 60;
    const horizontalSpacing = 40;

    const contentY = startY + 170; // Adjust the Y-coordinate for content below the table

    if (result.serial_number !== null) {
      pdfDoc.text(
        `Tank Serial #: ${result.serial_number}`,
        startX + 80,
        contentY - 8
      );
    }

    pdfDoc.text(`Remarks:`, startX, contentY);
    pdfDoc.text(
      `Paalala: Bawal magsulat ng "Cancelled Items" o magbago ng anumang impormasyong `,
      startX,
      contentY + 10
    );
    pdfDoc.text(
      `nakatala sa papel na ito. Anumang pagbabago ay hindi kikilalanin o tatanggapin.`,
      startX + 5,
      contentY + 15
    );

    // Driver and Outlet Receiver
    const driverReceiverX = startX;
    const driverReceiverY = contentY + 35; // Adjust Y-coordinate for driver and outlet receiver
    const driverReceiverTextWidth = pdfDoc.getTextWidth("Driver");
    const centeredDriverReceiverX =
      driverReceiverX + (columnWidth - driverReceiverTextWidth) / 2;
    pdfDoc.text("Driver", centeredDriverReceiverX, driverReceiverY + 5);
    pdfDoc.text(`__________________`, driverReceiverX + 10, driverReceiverY);

    const receiverTextWidth = pdfDoc.getTextWidth("Outlet Receiver");
    const receiverX = driverReceiverX + columnWidth + horizontalSpacing;
    const centeredReceiverX = receiverX + (columnWidth - receiverTextWidth) / 2;
    pdfDoc.text("Outlet Receiver", centeredReceiverX, driverReceiverY + 5);
    pdfDoc.text(`__________________`, receiverX + 10, driverReceiverY);

    const numRowsPerTable = 30;
    const chunks = [];
    const filteredItems = result.items.filter((item) => item.ordered_qty !== 0);

    for (let i = 0; i < filteredItems.length; i += numRowsPerTable) {
      chunks.push(filteredItems.slice(i, i + numRowsPerTable));
    }

    drawTables(pdfDoc, chunks);

    pdfDoc.save(
      `BM_${result.outlet.outlet}_Receipt_${result.order_number}_${date_now}.pdf`
    );
    setIsPDFLoading(false);
  };

  const drawTables = (pdfDoc, chunks) => {
    const startX1 = 10; // X-coordinate for the first table
    const startX2 = 109; // X-coordinate for the second table
    const startY = 70; // Y-coordinate for both tables
    const tableWidth = 70; // Width of each table
    const cellPadding = 1; // Padding for each cell
    const lineHeight = 5; // Height of each line of text
    const fontSize = 8; // Font size for text cells
    const headerSize = 6; // Header size

    chunks.forEach((chunk, index) => {
      const startX = index % 2 === 0 ? startX1 : startX2;
      const startYAdjusted =
        startY + Math.floor(index / 2) * (lineHeight * 30 + cellPadding * 2);

      pdfDoc.setFontSize(fontSize);
      pdfDoc.setFont("helvetica", "bold");

      // Adjusted cell widths and positions
      const cellWidth = tableWidth / 4;
      const codeX = startX + cellPadding;
      const nameX = startX + cellWidth + cellPadding;
      const orderedQtyX = startX + 2 * cellWidth + cellPadding + 6;
      const receivedQtyX = startX + 3 * cellWidth + cellPadding + 6;
      const remarksX = startX + 4 * cellWidth + cellPadding + 6;

      // Draw headers
      pdfDoc.text("Code", codeX, startYAdjusted);
      pdfDoc.text("Name", nameX, startYAdjusted);
      pdfDoc.text("Order Qty", orderedQtyX, startYAdjusted);
      pdfDoc.text("Receive Qty", receivedQtyX, startYAdjusted);
      pdfDoc.text("Remarks", remarksX, startYAdjusted);

      // Draw cell rectangles and fill data into cells
      chunk.forEach((item, rowIndex) => {
        const yPos = startYAdjusted + (rowIndex + 1) * lineHeight;
        const textHeight = pdfDoc.getTextDimensions(item.prod_nickname).h;

        pdfDoc.rect(
          codeX,
          yPos - textHeight,
          cellWidth,
          textHeight + cellPadding * 2,
          "S"
        ); // Code cell
        pdfDoc.rect(
          nameX,
          yPos - textHeight,
          cellWidth + 6,
          textHeight + cellPadding * 2,
          "S"
        ); // Name cell
        pdfDoc.rect(
          orderedQtyX,
          yPos - textHeight,
          cellWidth,
          textHeight + cellPadding * 2,
          "S"
        ); // Order Qty cell
        pdfDoc.rect(
          receivedQtyX,
          yPos - textHeight,
          cellWidth,
          textHeight + cellPadding * 2,
          "S"
        ); // Received Qty cell
        pdfDoc.rect(
          remarksX,
          yPos - textHeight,
          cellWidth,
          textHeight + cellPadding * 2,
          "S"
        ); // Remarks cell

        // Fill data into cells
        pdfDoc.setFontSize(fontSize);
        pdfDoc.setFont("helvetica", "normal");
        pdfDoc.text(item.prod_code, codeX + cellPadding, yPos);
        pdfDoc.text(item.prod_nickname, nameX + cellPadding, yPos);
        pdfDoc.text(
          `${item.ordered_qty} ${item.product_unit_measurement}`,
          orderedQtyX + cellPadding,
          yPos
        );
        pdfDoc.text("", receivedQtyX + cellPadding, yPos); // Received Qty data (empty for now)
        pdfDoc.text("", remarksX + cellPadding, yPos); // Remarks data (empty for now)

        const totalHeight = chunks.length * (lineHeight * 30 + cellPadding * 2);

        // Content below the table
      });

      // Calculate the total height of all chunks
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  }

  const downloadPDFs = async (results) => {
    for (const result of results) {
      try {
        generatePDF(result);
        await new Promise((resolve) => setTimeout(resolve, 500));
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  };

  return (
    <div>
      <Button
        className="bg-orange-900 mt-1 py-3 px-3 flex items-center space-x-2"
        disabled={!isPDFReady || !isSuccess}
        // onClick={() => {
        //   if (isSuccess && data && data.results) {
        //     data.results.forEach((result, index) => {
        //       console.log(`Processing result #${index + 1}`, result);
        //       generatePDF(result);
        //     });
        //   }
        //   // refetchMainTable();
        //   // if (CloseModal) {
        //   //   handleMainClose();
        //   // }
        // }}
        onClick={() => {
          if (isSuccess && data && data.results) {
            downloadPDFs(data.results);
          }
        }}
      >
        {isPDFLoading ? (
          " Loading..."
        ) : (
          <>
            <FaFilePdf className="mr-2" /> {/* Icon with margin-right */}
            DR
          </>
        )}
      </Button>
    </div>
  );
};

export default OrderSummaryPdf;
