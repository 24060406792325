import jsPDF from "jspdf";
import "jspdf-autotable";
import { formatDateMDY } from "../../../reusable/reuse_function";
const InvenFlowMeatPDF = (startDate, endDate) => {
  const formattedStartDate = formatDateMDY(startDate);
  const formattedEndDate = formatDateMDY(endDate);

  const xFirstInput = 100;
  const xSecondInput = 130;
  const xThirdInput = 160;
  const xFourthInput = 190;

  // A4 size dimensions
  const pdfWidth = 215.9; // Width in millimeters (A4 size)
  const pdfHeight = 279.4; // Height in millimeters (custom length)

  // Create a new jsPDF instance
  const pdfDoc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [pdfWidth, pdfHeight],
  });

  // Header for the new page
  pdfDoc.setFontSize(12);
  pdfDoc.setTextColor(0, 128, 0);
  pdfDoc.setFont("helvetica", "bold");
  pdfDoc.text("PREMIUM BURGER & FOOD PRODUCTS INC.", 5, 10);
  pdfDoc.setFontSize(10);
  pdfDoc.setTextColor(0);
  pdfDoc.text("INVENTORY FLOW OF MEAT", 5, 15);
  pdfDoc.text(`${formattedStartDate} to ${formattedEndDate}`, 5, 20);

  // Raw Materials Section
  pdfDoc.text("RAW MATERIALS", 5, 30);
  pdfDoc.text("IBK", xFirstInput, 30);
  pdfDoc.text("MDM", xSecondInput, 30);
  pdfDoc.text("CHX MEAT", xThirdInput, 30);
  pdfDoc.text("P DIA", xFourthInput, 30);
  pdfDoc.text("1,181.50", xFourthInput, 35);
  pdfDoc.text("Beginning inventory (per kilo)", 5, 35);
  pdfDoc.text("1,231.98", xFirstInput, 35);
  pdfDoc.text("2,751.72", xSecondInput, 35);
  pdfDoc.text("3,151.40", xThirdInput, 35);
  pdfDoc.text("1,181.50", xFourthInput, 35);

  pdfDoc.text("Purchases from suppliers (per kilo)", 5, 40);
  pdfDoc.text("1,201.50", xFirstInput, 40);
  pdfDoc.text("2,520.00", xSecondInput, 40);
  pdfDoc.text("2,775.00", xThirdInput, 40);
  pdfDoc.text("1,295.45", xFourthInput, 40);

  pdfDoc.text("Available for issuances (per kilo)", 5, 45);
  pdfDoc.text("2,433.48", xFirstInput, 45);
  pdfDoc.text("5,271.72", xSecondInput, 45);
  pdfDoc.text("5,926.40", xThirdInput, 45);
  pdfDoc.text("2,476.95", xFourthInput, 45);

  pdfDoc.text("Less: Issuances to MAW (per kilo)", 5, 50);
  pdfDoc.text("1,307.63", xFirstInput, 50);
  pdfDoc.text("3,271.90", xSecondInput, 50);
  pdfDoc.text("3,587.40", xThirdInput, 50);
  pdfDoc.text("1,627.30", xFourthInput, 50);

  pdfDoc.text("Ending inventory (per kilo)", 5, 55);
  pdfDoc.text("1,125.85", xFirstInput, 55);
  pdfDoc.text("1,999.82", xSecondInput, 55);
  pdfDoc.text("2,339.00", xThirdInput, 55);
  pdfDoc.text("849.65", xFourthInput, 55);

  // Meat Weight Monitoring Section
  pdfDoc.text("MEAT WEIGHT MONITORING", 5, 65);
  pdfDoc.text("Declared Wt. of Supplier (in kilos)", 5, 70);
  pdfDoc.text("1,307.63", xFirstInput, 70);
  pdfDoc.text("3,271.90", xSecondInput, 70);
  pdfDoc.text("3,587.40", xThirdInput, 70);
  pdfDoc.text("1,627.30", xFourthInput, 70);

  pdfDoc.text("Actual Weight before flaking (in kilos)", 5, 75);
  pdfDoc.text("1,307.87", xFirstInput, 75);
  pdfDoc.text("3,271.87", xSecondInput, 75);
  pdfDoc.text("3,587.85", xThirdInput, 75);
  pdfDoc.text("1,627.67", xFourthInput, 75);

  pdfDoc.text("Variance: Over(Short)", 5, 80);
  pdfDoc.text("0.24", xFirstInput, 80);
  pdfDoc.text("(0.03)", xSecondInput, 80);
  pdfDoc.text("0.45", xThirdInput, 80);
  pdfDoc.text("0.37", xFourthInput, 80);

  // Flaked Meat Compliance Section
  pdfDoc.text("FLAKED MEAT COMPLIANCE", 5, 90);
  pdfDoc.text("Left Over Last Month (per kilo)", 5, 95);
  pdfDoc.text("1.35", xFirstInput, 95);
  pdfDoc.text("0.30", xSecondInput, 95);
  pdfDoc.text("1.15", xThirdInput, 95);
  pdfDoc.text("2.60", xFourthInput, 95);

  pdfDoc.text("Actual Weight per bag (per kilo)", 5, 100);
  pdfDoc.text("1,307.87", xFirstInput, 100);
  pdfDoc.text("3,271.87", xSecondInput, 100);
  pdfDoc.text("3,587.85", xThirdInput, 100);
  pdfDoc.text("1,627.67", xFourthInput, 100);

  pdfDoc.text("Actual Output", 5, 105);
  pdfDoc.text("1,310.00", xFirstInput, 105);
  pdfDoc.text("3,272.00", xSecondInput, 105);
  pdfDoc.text("3,589.00", xThirdInput, 105);
  pdfDoc.text("1,629.00", xFourthInput, 105);

  // Repacking Section
  pdfDoc.text("REPACKING", 5, 115);
  pdfDoc.text("Beginning inventory (per kilo)", 5, 120);
  pdfDoc.text("2.50", xFirstInput, 120);
  pdfDoc.text("58.00", xSecondInput, 120);
  pdfDoc.text("52.00", xThirdInput, 120);
  pdfDoc.text("21.00", xFourthInput, 120);

  pdfDoc.text("Actual Received from Flaking", 5, 125);
  pdfDoc.text("1,310.00", xFirstInput, 125);
  pdfDoc.text("3,272.00", xSecondInput, 125);
  pdfDoc.text("3,589.00", xThirdInput, 125);
  pdfDoc.text("1,629.00", xFourthInput, 125);

  pdfDoc.text("Less: Issuance to Patty Area (per kilo)", 5, 130);
  pdfDoc.text("1,296.50", xFirstInput, 130);
  pdfDoc.text("3,293.00", xSecondInput, 130);
  pdfDoc.text("3,496.00", xThirdInput, 130);
  pdfDoc.text("1,635.00", xFourthInput, 130);

  pdfDoc.text("Ending inventory (per kilo)", 5, 135);
  pdfDoc.text("16.00", xFirstInput, 135);
  pdfDoc.text("37.00", xSecondInput, 135);
  pdfDoc.text("46.00", xThirdInput, 135);
  pdfDoc.text("15.00", xFourthInput, 135);

  pdfDoc.text("USAGE", 5, 145);
  pdfDoc.text("Meat available for usage (per kilo)", 5, 150);
  pdfDoc.text("1,310.00", xFirstInput, 150);
  pdfDoc.text("3,272.00", xSecondInput, 150);
  pdfDoc.text("3,589.00", xThirdInput, 150);
  pdfDoc.text("1,629.00", xFourthInput, 150);

  pdfDoc.text("Less: Issuance to Patty Area", 5, 155);
  pdfDoc.text("1,296.50", xFirstInput, 155);
  pdfDoc.text("3,293.00", xSecondInput, 155);
  pdfDoc.text("3,496.00", xThirdInput, 155);
  pdfDoc.text("1,635.00", xFourthInput, 155);

  pdfDoc.text("Ending inventory after usage (per kilo)", 5, 160);
  pdfDoc.text("13.50", xFirstInput, 160);
  pdfDoc.text("19.00", xSecondInput, 160);
  pdfDoc.text("93.00", xThirdInput, 160);
  pdfDoc.text("(-6.00)", xFourthInput, 160);

  pdfDoc.text("Actual inventory (per kilo)", 5, 165);
  pdfDoc.text("13.50", xFirstInput, 165);
  pdfDoc.text("19.00", xSecondInput, 165);
  pdfDoc.text("93.00", xThirdInput, 165);
  pdfDoc.text("(-6.00)", xFourthInput, 165);

  pdfDoc.save(
    `Inventory_Flow_Of_Meat_${formattedStartDate}_to_${formattedEndDate}.pdf`
  );
};

export default InvenFlowMeatPDF;
