import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  CardFooter,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useFetchDeliveryOrdersQuery } from "../../../../store";
import { formatToNumber } from "../../../reusable/reuse_function";
import DeliveryOrderItemDetailModal from "./DeliveryOrderItem";
import OrderSummaryReceiiptDB from "./DeliveryOrderItem";

// import AdjustDelApprovalModal from "../../modal_form_components/Approval/AdjustDelApprovalModal";

const TABLE_HEAD_DRNUM = [
  "Order Number",
  "Status",
  "Outlet",
  "Route",
  "FC",
  "Sales",
  "Actions",
];
let del_order_num_result = [];

export default function DeliveryOrderDBModal({ getDeliverId, getDeliveryNum }) {
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, error, isLoading, isSuccess, refetch } =
    useFetchDeliveryOrdersQuery({
      page: currentPage,
      page_size: pageSize,
      del_tracking_num: getDeliverId,
    });
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }

  const handleOpen = () => setOpen(!open);

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_order_num_result = data.results;
    let totalPage = Math.ceil((data.count || 0) / pageSize);
    console.log(data.results);
    return (
      <>
        <Button
          onClick={handleOpen}
          className="bg-green-600 ml-10 font-8"
          size="sm"
        >
          View
        </Button>
        <Dialog open={open} handler={handleOpen} size="lg">
          <DialogHeader>Order Summary</DialogHeader>
          <DialogBody>
            <Card className="h-[500px] w-full overflow-y-auto">
              <table className="w-full min-w-max table-auto text-left ">
                <thead>
                  <tr>
                    {TABLE_HEAD_DRNUM.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {del_order_num_result.map((del, index) => {
                    const isLast = index === del_order_num_result.length - 1;
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";
                    const getStatusClass = (status) => {
                      switch (status) {
                        case 1:
                          return "bg-yellow-200 text-yellow-800"; // ON_QUEUE
                        case 2:
                          return "bg-blue-200 text-blue-800"; // FOR_APPROVAL
                        case 3:
                          return "bg-orange-200 text-orange-800"; // FOR_DELIVERY
                        case 4:
                          return "bg-purple-200 text-purple-800"; // ON_TRANSIT
                        case 5:
                          return "bg-green-200 text-green-800"; // COMPLETED
                        case 6:
                          return "bg-red-200 text-red-800"; // RETURN
                        case 7:
                          return "bg-gray-200 text-gray-800"; // PARTIALLY_COMPLETE
                        default:
                          return "bg-gray-100 text-gray-600"; // Default case
                      }
                    };

                    const getStatusText = (status) => {
                      switch (status) {
                        case 1:
                          return "On Queue";
                        case 2:
                          return "For Approval";
                        case 3:
                          return "For Delivery";
                        case 4:
                          return "In Transit";
                        case 5:
                          return "Completed";
                        case 6:
                          return "Return";
                        case 7:
                          return "Partially Complete";
                        default:
                          return "Unknown";
                      }
                    };
                    return (
                      <tr key={del.id}>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {del.order_number || "N/A"}
                              </Typography>
                            </div>
                          </div>
                        </td>

                        <td
                          className={`${classes} ${getStatusClass(
                            del.status
                          )}  rounded-md`}
                        >
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography variant="small" className="font-bold">
                                {getStatusText(del.status)}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {del.outlet.outlet || "N/A"}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {del.outlet.route.name || "N/A"}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {del.outlet.fc.name || "N/A"}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-1">
                            <div className="flex flex-col">
                              <Typography
                                className={
                                  parseFloat(del.in_transit_sales) > 0
                                    ? "text-green-600"
                                    : ""
                                }
                              >
                                ₱{formatToNumber(del.in_transit_sales)}
                              </Typography>
                            </div>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <div className="flex flex-col">
                              <OrderSummaryReceiiptDB
                                getOrderId={del.id}
                                delData={del}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Card>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                Page {currentPage} of {totalPage}
              </Typography>
              <div className="flex gap-2">
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPage}
                >
                  Next
                </Button>
              </div>
            </CardFooter>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}
