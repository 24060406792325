import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import dayjs from "dayjs";

const data = [
  { date: "2024-02-01", consumption: 50 },
  { date: "2024-02-02", consumption: 75 },
  { date: "2024-02-03", consumption: 60 },
  { date: "2024-02-04", consumption: 90 },
  { date: "2024-02-05", consumption: 120 },
];

const BarChartModal = ({ itemData }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [chartHeight, setChartHeight] = useState(300);
  const [chartWidth, setChartWidth] = useState(500);

  useEffect(() => {
    // Adjust chart dimensions dynamically based on data length
    const dynamicHeight = Math.min(600, 50 * itemData.prod_consumption.length);
    const dynamicWidth = Math.max(500, 30 * itemData.prod_consumption.length);
    setChartHeight(dynamicHeight);
    setChartWidth(dynamicWidth);
  }, [itemData]);

  const chartOptions = {
    chart: {
      type: "bar",
      height: chartHeight,
      width: chartWidth,
    },
    xaxis: {
      categories: itemData?.prod_consumption?.length
        ? itemData.prod_consumption.map((item) => item.record_at__date)
        : itemData?.del_consumption?.map((item) => item.record_at__date) || [],
      labels: {
        style: {
          colors: "#1E293B", // Dark gray text for x-axis
          fontSize: "8px",
          // fontWeight: "bold",
        },
        formatter: (value) => dayjs(value).format("MMM DD"), // Formats as "Feb 10"
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#1E293B", // Dark gray text for y-axis
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    dataLabels: {
      enabled: true, // Enables labels on top of bars
      style: {
        colors: ["#000"], // Black text for data labels
        fontSize: "12px",
        fontWeight: "bold",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "70%", // Makes bars thicker (increase for more thickness)
        borderRadius: 2, // Rounds bar corners
      },
    },
    annotations: {
      xaxis: [
        {
          x: itemData.prod_consumption[0]?.record_at__date, // First date
          label: {
            text: dayjs(itemData.prod_consumption[0]?.record_at__date).format(
              "YYYY"
            ), // Year indicator
            style: {
              color: "#666",
              fontSize: "14px",
              fontWeight: "bold",
            },
            position: "bottom",
          },
        },
      ],
    },
  };

  const chartSeries = [
    {
      name: "Consumption",
      data: itemData.prod_consumption.map((item) => item.total_cons),
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <Button className="bg-orange-900 mr-5 mt-1" onClick={handleOpen}>
        View
      </Button>
      <Dialog open={open} handler={handleOpen} size="xl">
        <DialogBody className="max-w-xl p-6 overflow-x-auto min-w-[1000px]">
          <DialogHeader>
            <Typography className="text-green-900" variant="h3">
              {itemData ? itemData.name : ""} Consumption Over Time
            </Typography>
          </DialogHeader>
          <div className="w-full overflow-x-auto">
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={chartHeight}
              width={chartWidth}
            />
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default BarChartModal;
