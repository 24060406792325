import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  CardFooter,
} from "@material-tailwind/react";
import { useFetchDeliveryItemsAdjustQuery } from "../../../../store";
import OrderCompleteSumPDF from "../../../form_components/pdf/GenOrderSumPdf";

const TABLE_HEAD = [
  "Item Code",
  "Description",
  "Weight/UM",
  "Ordered Quantity",
  "Adjusted Quantity",
  "Delivered Quantity",
  "Rejected Quantity",
  "Missing Quantity",
  "Price",
  "Cost",
];
const trapMessage = "Are you sure to Transfer these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const formattedDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];

function OrderSummaryReceiiptDB({ getOrderId, delData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const { data, isError, isLoading, isSuccess, refetch } =
    useFetchDeliveryItemsAdjustQuery({
      page: currentPage,
      page_size: pageSize,
      order_number: getOrderId,
    });

  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([true, true]);

  const [signatures, setSignatures] = useState(["", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };

  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleRefresh() {
    refetch();
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }

  let handleMainClose = () => {
    setOpen(false);
    setSignatures(["", ""]);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.results;
    // console.log(account_detailed)
    let totalPage = Math.ceil((data.count || 0) / pageSize);

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          Details
        </Button>

        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              Outlet Order Summary Receipt
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Delivery Date"
                      name="date_ordered"
                      value={`${formattedDate}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Delivery Number & Cage Number"
                      name="requested_at"
                      value={`${delData.delivery_tracking_number.delivery_number} - Cage ${delData.delivery_tracking_number.cage_number}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Order Number"
                      name="requested_at"
                      value={delData.order_number}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Outlet"
                      name="outlet"
                      value={`${delData.outlet.outlet}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Address"
                      name="address"
                      value={`${delData.outlet.address}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Route"
                      name="route"
                      value={`${delData.outlet.route.name}`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <table className="w-full table-auto text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              component={"span"}
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.product.product_code || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.product.name || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.product.weight_box || "NA"}
                                    {"/"}
                                    {item.product.unit_measurement || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.stated_qty || "0"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.ordered_qty || "0"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.received_qty || "0"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="red"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.reject_qty || ""}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="red"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.missing_qty || ""}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    ₱{item.current_price || "0"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    ₱{item.product_cost || "0"}
                                  </Typography>
                                </div>
                              </div>
                            </td>

                            {/* <td className={classes} key={index}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <input
                                    type="number"
                                    value={actualQtys[index] || 0}
                                    onChange={(e) => handleActualQtyChange(e, index)}
                                    style={{ width: "80px", border: "1px solid #ccc", padding: "5px" }}
                                  />
                                </div>
                              </div>
                            </td> */}
                            {/* <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  {item.ordered_qty && actualQtys[index] !== undefined && (
                                    <Typography variant="small" color={difference === 0 ? "green" : "red"} className="font-normal" component={'span'}>
                                      {difference === 0 ? "Complete" : `Missing (${difference} )`}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            </td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page {currentPage} of {totalPage}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleRefresh}
                    >
                      Refresh
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPage}
                    >
                      Next
                    </Button>
                  </div>
                </CardFooter>
                <div className="m-5"></div>
                <Typography className="text-center text-green-900" variant="h6">
                  Total Order Cost: ₱
                  {Number(delData.total_cost).toLocaleString()}
                </Typography>
              </div>

              {/* <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto ml-8">
                  <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[0]}
                      onEnd={() => handleSignatureChange(0)}
                    />
                      <div className={boxResetClasses}>
                        <GrPowerReset
                          size={"30px"}
                          onClick={() => handleReset(0)}
                          className="cursor-pointer"
                          color="white"
                        />
                      </div>
                    </div>
                    <Typography className="text-center text-black" variant="h6" component="span">
                      Driver
                      </Typography><>&nbsp;</>
                        {isSignatureEmpty[0] && <Typography variant="lead" color="red">Please provide a signature.</Typography>}
                  </div>
                  <div className="col-span-2 space-y-5">
                    <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[1]}
                      onEnd={() => handleSignatureChange(1)}
                    />
                        <div className={boxResetClasses}>
                        <GrPowerReset
                          size={"30px"}
                          onClick={() => handleReset(1)}
                          className="cursor-pointer"
                          color="white"
                        />
                        </div>
                      </div>
                      <Typography className="text-center text-black m" variant="h6"component={'span'}>
                          Receiver
                     </Typography><>&nbsp;</>
                      {isSignatureEmpty[1] && <Typography variant="lead" color="red">Please provide a signature.</Typography>}
                  </div>
              </div> */}
            </form>
          </DialogBody>
          <DialogFooter>
            {/* <div >
          <ReturnOrderConfirmation
            onConfirm={handleSaveReturn}
            isReadyToSave={isReadyToSave}
          />
          </div>
          <Confirmation
            onConfirm={handleSaveSignatures}
            trapMessage={trapMessage}
            isReadyToSave={isReadyToSave}
          /> */}
            {[5, 7].includes(delData.status) && (
              <OrderCompleteSumPDF getOrderId={getOrderId} />
            )}
            <Button
              variant="text"
              color="red"
              className="mr-3 ml-5"
              onClick={handleMainClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default OrderSummaryReceiiptDB;
