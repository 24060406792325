import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

const dashboardApi = createApi({
  reducerPath: "dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchDeliveryTotalSales: builder.query({
        query: ({
          route,
          fc,
          startDate,
          endDate,
          page = 1,
          page_size = 999,
        }) => {
          return {
            url: `/api/client/get_delivery_cage_sales/?page=${page}&page_size=${page_size}&route=${route}&fc=${fc}&startDate=${startDate}&endDate=${endDate}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchInventoryDashBoardGraph: builder.query({
        query: ({
          location,
          start_date,
          end_date,
          page = 1,
          page_size = 999,
          search = "",
        }) => {
          return {
            url: `/api/client/inventory_dashboard_view_list/?page=${page}&page_size=${page_size}&start_date=${start_date}&end_date=${end_date}&location=${location}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashBoardPO: builder.query({
        query: ({ client }) => {
          return {
            url: `/api/client/${client}/dashboard_po/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashBoardProd: builder.query({
        query: ({ client }) => {
          return {
            url: `/api/client/dashboard_prod/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashPendingPo: builder.query({
        query: ({ page, page_size, search_str }) => {
          return {
            url: `/api/client/dash_pending_po/?page=${page}&page_size=${page_size}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashProdReport: builder.query({
        query: ({ page, page_size, search_str, from, to }) => {
          return {
            url: `/api/client/dash_prod_report_item/?page=${page}&page_size=${page_size}&search_str=${search_str}&from=${from}&to=${to}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      poListStatus : builder.query({
        query : ({page, page_size, poStatus, from, to}) => {
          return {
            url: `/api/client/po_list_status/?page=${page}&page_size=${page_size}&status=${poStatus}&from=${from}&to=${to}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          }
        }
      }),
      poDetail : builder.query({
        query : ({id, status}) => {
          return {
            url: `/api/client/${id}/po_detailed_status/?status=${status}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          }
        }
      })
    };
  },
});
export const {
  useFetchDeliveryTotalSalesQuery,
  useFetchInventoryDashBoardGraphQuery,
  useDashBoardPOQuery,
  useDashPendingPoQuery,
  useDashBoardProdQuery,
  useDashProdReportQuery,
  usePoListStatusQuery,
  usePoDetailQuery,
} = dashboardApi;
export { dashboardApi };
